<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title class="text-subtitle-1">
            Corte diario
            <v-spacer></v-spacer>

            <!-- EXPORTAT EL EXCEL -->
            <v-btn class="mr-2" small dark color="green" @click="dialogIngresosCorte.estatus = true" tile v-if="![18, 19, 17].includes(this.getdatosUsuario.idpuesto)">
              <v-icon small>mdi-auto-download</v-icon>
            </v-btn>

            <!-- EXPORTAT EL EXCEL -->
            <v-btn class="mr-2" small dark color="green" @click="exportar( tablaMovimientos )" tile>
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <!-- EXPORTAT EL EXCEL -->
            <v-btn class="mr-2" small dark color="green" @click="exportar2( tablaMovimientos )" tile>
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <!-- IMPRIMIR CORTE  -->
            <v-btn
              color="orange"
              class="mr-2"
              @click="generarRecibo( )"
              small
              dark
              tile
            >
              <v-icon small left>mdi-cash-register</v-icon>
              CORTE
            </v-btn>

            <!-- DIFERENCIAS  -->
            <v-btn
              color="secondary"
              class="mr-2"
              @click="dialogCifras = true"
              small
              dark
              v-if="![18, 19, 17].includes(this.getdatosUsuario.idpuesto)"
              tile
              ><v-icon left>mdi-currency-usd</v-icon>Diferencias</v-btn
            >

            <!-- ACTUALIZAR LA INFO -->
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="consultar()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
            
          </v-card-title>

          <!-- CONTENIDO -->
          <v-card-text class="">

            <!-- FILTROS  -->
            <v-row>
              <v-col cols="12" sm="4" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  :clearable="[18, 19, 17].includes( getdatosUsuario.idpuesto) ? false : true "
                  v-model="plantel"
                  :items="planteles"
                  label="Selecciona un plantel"
                  persistent-hint
                  hide-details
                  item-text="plantel"
                  item-value="id_plantel"
                  :readonly="[18, 19, 17].includes( getdatosUsuario.idpuesto) ? true : false "
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fecha_corte"
                  type="date"
                  @keyup.enter="consultar"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                lg="3"
                xl="2"
                v-if="plantel && fecha_corte"
              >
                <v-card width="44">
                  <v-avatar
                    size="44"
                    :color="diferencia_global != 0 ? 'red' : 'green'"
                    rounded
                    class="elevation-1"
                  >
                    <v-icon
                      dark
                      color="white"
                      size="30"
                      v-if="diferencia_global == 0"
                    >
                      mdi-check
                    </v-icon>

                    <v-icon dark color="white" size="30" v-else>
                      mdi-close
                    </v-icon>
                  </v-avatar>
                </v-card>
              </v-col>
            </v-row>

            <v-row align="center">

              <!-- DESGLOSE DE LOS PAGOS; SOLO PARA VENDEDORAS -->
              <v-col cols="12" v-if="plantel" class="pa-0">
                <v-card class="transparent elevation-0">
                  <v-card-text class="black--text text-subtitle-1" v-if="![18, 19, 17].includes(this.getdatosUsuario.idpuesto)">
                    <b>Corte plantel: </b> 
                      {{ corte_plantel }}
                    <br/>
                    <b>Diferencia global: </b> 
                      $ {{ diferencia_global }}
                  </v-card-text>

                  <v-card-text class="black--text text-h6" v-else>
                    <b>Corte plantel: </b> 
                      {{ corte_plantel }}
                    <br/>
                    <v-btn @click="expand = !expand" color="primary" small>
                      {{ !expand ? 'Reporte completo' : 'Ocultar Reporte' }}
                    </v-btn>
                  </v-card-text>

                  <v-expand-transition>
                    <div v-if="expand">
                      <v-card max-width="500" class="shadowCard">
                        <v-list class="transparent">
                          <v-list-item>
                            <v-list-item-title>Diferencia</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                              {{ diferencia_total }}
                            </v-list-item-subtitle>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-title>Pagos de tu suc. a otra suc.</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                              {{ pagos_otra_suc }}
                            </v-list-item-subtitle>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-title>Pagos de otra suc. a tu suc.</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                              {{ pagos_de_otra_suc }}
                            </v-list-item-subtitle>
                          </v-list-item>
                        </v-list>

                        <v-list-item>
                            <v-list-item-title>Pagos propios.</v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                              {{ pagos_propios }}
                            </v-list-item-subtitle>
                          </v-list-item>
                        </v-list>
                      </v-card>

                    </div>
                  </v-expand-transition>
                </v-card>
              </v-col>

              <v-col cols="12" v-if="plantel" class="pa-0">
                <MisMovimientos :tablaPagosSucursal="tablaPagosSucursal" :plantel="plantel" :url_servidor="url_servidor"/>
              </v-col>

              <v-col cols="12" v-if="plantel" class="pa-0">
                <MovimientosOtraSucursal  :pagosDesdeOtraSucursal="pagosDesdeOtraSucursal" :plantel="plantel" :url_servidor="url_servidor"/>
              </v-col>

              <v-col cols="12" class="pa-0">
                <MovimientosCorte :num_pagos="num_pagos" :desglose="desglose" :cambios="cambios" :url_servidor="url_servidor" :tablaMovimientos="tablaMovimientos" :plantel="plantel" @consultar="consultar"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- dialog para diferencias -->
    <v-dialog
      v-model="dialogCifras"
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-card-title primary-title>
          Cifras
          <v-spacer></v-spacer>
          <v-btn small dark color="green" @click="exportar(filterCifras)">
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-card-title>
        <!-- Datos principales del usuario -->
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-radio-group v-model="mostrar_cifras_completas" row>
                <v-radio label="Diferencias" :value="1"></v-radio>
                <v-radio label="Todas" :value="2"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="4">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="ciclo"
                :items="ciclosUnicos"
                label="Selecciona el ciclo"
                persistent-hint
                hide-details
                item-text="ciclo"
                item-value="id_ciclo"
              >
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headersCifras"
            :items="filterCifras"
            class="elevation-0"
            dense
            mobile-breakpoint="200"
          >
            <template v-slot:item.diferencia="{ item }">
              {{ item.diferencia.toFixed(2) }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogCifras = false" small color="green" dark>
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog para ver la imagen -->
    <v-dialog
      v-model="dialgImagen"
      max-width="600px"
    >
      <v-card class="elevation-0">
        <v-card-text>
          <!-- Imagen -->
          <v-col cols="12">
            <v-img
              :src="url_servidor + '/comprobante-pago/' + imagen"
              contain
              max-width="600"
              height="800"
            >
            </v-img>
          </v-col>
        </v-card-text>
        <!-- Guardar la información  -->
        <v-btn
          absolute
          right
          top
          color="grey"
          dark
          fab
          class="elevation-6 mt-8"
          @click="dialgImagen = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn
        >
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

    <IngresosCorte v-if="dialogIngresosCorte.estatus" :dialogIngresosCorte="dialogIngresosCorte" />

  </v-container>
</template>
<script>
import axios from 'axios';

import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga  from "@/components/alertas/carga.vue";

import MisMovimientos           from "@/components/corte/MisMovimientos.vue";
import MovimientosOtraSucursal  from "@/components/corte/MovimientosOtraSucursal.vue";
import MovimientosCorte         from "@/components/corte/MovimientosCorte.vue";
import IngresosCorte            from "@/components/corte/IngresosCorte.vue";

import validarErrores           from "@/mixins/validarErrores";

import XLSX from "xlsx";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default {
  components: {
    Alerta,
    carga,
    MisMovimientos,
    MovimientosOtraSucursal,
    MovimientosCorte,
    IngresosCorte
  },

  mixins: [ validarErrores ],

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    fecha_corte: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    planteles: [],
    plantel: null,
    fullscreen: false, 
    dialogCifras: false,
    url_servidor: null,
    fullscreen: false,
    dialogDiferencias:false,

    url_servidor: null,             //Angel Rodriguez                      
    dialgImagen: false,
    imagen: null,

    snackbar: false,

    headers: [
      { text: "id"           , value: "id_plantel" },
      { text: "Plantel"      , value: "plantel"      , align: "right" },
      { text: "Efectivo"     , value: "efectivo"     , align: "right" },
      { text: "Transferencia", value: "transferencia", align: "right" },
      { text: "Depositos"    , value: "depositos"    , align: "right" },
      { text: "Tarjeta"      , value: "tarjeta"      , align: "right" },
      { text: "Total"        , value: "total"        , align: "right" },
    ],

    headersCifras: [
      { text: "id"           , value: "id_ciclo"      },
      { text: "Ciclo"        , value: "ciclo"         },
      { text: "Plantel"      , value: "plantel"       },
      { text: "Cifra inicial", value: "cifra_inicial" },
      { text: "Cifra final"  , value: "cifra_final"   },
      { text: "Diferencia"   , value: "diferencia"    },
    ],

    headersDiferencias: [
      { text: "Plantel"     , value: "plantel"        },
      { text: "ERP viejo"   , value: "cifraERPVIEJO"  },
      { text: "ERP nuevo"   , value: "cifraERPNUEVO"  },
      { text: "Diferencia"  , value: "diferenteMonto" },
    ],

    diferencias:[],

    tablaPagosSucursal: [],
    desglose: [],
    cifras: [],
    pagosDesdeOtraSucursal: [],
    diferencia_total: 0,
    corte_plantel: 0,
    pagos_otra_suc: 0,
    pagos_propios: 0,
    pagos_de_otra_suc: 0,
    diferencia_global: 0,
    cifra_inicial: 0,
    cifra_final: 0,

    mostrar_cifras_completas: 1,
    ciclosUnicos: [],
    ciclo: null,
    searchMovimientos:'',

    expand: false,

    page: 1,
    pageCount: 0,
    itemsPerPage: 10,

    cicloDiferencia:null,

    parametosComparativa:{
      estatus: false
    },
    cambios:[],
    num_pagos: 0,
    tablaMovimientos:[],
    dialogIngresosCorte: {
      estatus: false
    }
  }),

  watch: {
    plantel(value) {
      if (value && this.fecha_corte) {
        this.consultar();
      }
    },

    fecha_corte(value) {
      if (value && this.plantel) {
        this.consultar();
      }
    },

    cicloDiferencia( value ){
      if(value){ this.getDiferenciaCiclos() }
    },

  },

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),

    filterCifras( ) {
      let cifras = this.cifras;
      
      if (this.mostrar_cifras_completas == 1) {
        cifras = cifras.filter((el) => {
          return el.diferencia > 0;
        });
      }

      if (this.ciclo) {
        cifras = cifras.filter((el) => {
          return el.id_ciclo == this.ciclo;
        });
      }

      return cifras;
    },

  },

  created() {

    if( [18, 19, 17].includes(this.getdatosUsuario.idpuesto) ){
      this.plantel = this.getdatosUsuario.id_plantel
    }
    this.initialize();
    this.url_servidor = "https://escuelakpi.club/kpi/";
  },

  methods: {
    initialize() {
      this.cargar = true;
      this.planteles = [];
      this.$http.get("kpi.planteles.all").then((response) => {
        if ([18, 19, 17].includes(this.getdatosUsuario.idpuesto)) {
          this.planteles = response.data.filter((el) => {
            return el.id_plantel == this.getdatosUsuario.id_plantel;
          });
        } else {
          this.planteles = response.data;
        }

        this.cargar = false;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    consultar() {
      this.tablaPagosSucursal     = [];
      this.desglose               = [];
      this.cifras                 = [];
      this.pagosDesdeOtraSucursal = [];
      this.diferencia_total       = 0;
      this.corte_plantel          = 0;
      this.pagos_otra_suc         = 0;
      this.pagos_propios          = 0;
      this.pagos_de_otra_suc      = 0;
      this.diferencia_global      = 0;
      this.cifra_inicial          = 0;
      this.cifra_final            = 0;
      this.cargar                 = true;

      var payload = {
        fecha_corte: this.fecha_corte,
        id_plantel: this.plantel,
      };

      this.$http.post("corte.sucursal", payload).then((response) => {
        this.tablaPagosSucursal      = response.data.tablaPagosSucursal;
        this.desglose                = response.data.desglose;
        this.cifras                  = response.data.cifras;
        this.pagosDesdeOtraSucursal  = response.data.pagosDesdeOtraSucursal;
        this.diferencia_total        = response.data.diferencia_total;
        this.corte_plantel           = response.data.corte_plantel;
        this.pagos_otra_suc          = response.data.pagos_otra_suc;
        this.pagos_propios           = response.data.pagos_propios;
        this.pagos_de_otra_suc       = response.data.pagos_de_otra_suc;
        this.diferencia_global       = response.data.diferencia_global;
        this.cifra_inicial           = response.data.cifra_inicial;
        this.cifra_final             = response.data.cifra_final;
        this.ciclosUnicos            = response.data.ciclosUnicos;
        this.cambios                 = response.data.cambios;
        this.num_pagos               = response.data.num_pagos;
        this.tablaMovimientos        = response.data.tablaMovimientos;
        this.cargar                  = false;

        this.corte_plantel    = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.corte_plantel )
        this.diferencia_total = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.diferencia_total )
        this.pagos_otra_suc   = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.pagos_otra_suc )
        this.pagos_propios    = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.pagos_propios )


        for( const i in this.tablaPagosSucursal ){
          let { efectivo, depositos, tarjeta, total, transferencia } = this.tablaPagosSucursal[i]
          this.tablaPagosSucursal[i].efectivo      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( efectivo )
          this.tablaPagosSucursal[i].depositos     = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( depositos )
          this.tablaPagosSucursal[i].tarjeta       = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( tarjeta )
          this.tablaPagosSucursal[i].total         = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( total )
          this.tablaPagosSucursal[i].transferencia = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( transferencia )
        }

        for( const i in this.pagosDesdeOtraSucursal ){
          let { efectivo, depositos, tarjeta, total, transferencia } = this.pagosDesdeOtraSucursal[i]
          this.pagosDesdeOtraSucursal[i].efectivo      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( efectivo )
          this.pagosDesdeOtraSucursal[i].depositos     = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( depositos )
          this.pagosDesdeOtraSucursal[i].tarjeta       = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( tarjeta )
          this.pagosDesdeOtraSucursal[i].total         = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( total )
          this.pagosDesdeOtraSucursal[i].transferencia = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( transferencia )
        }

        for( const i in this.tablaMovimientos ){
          let { monto_pagado } = this.tablaMovimientos[i]
          this.tablaMovimientos[i].monto_pagado      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( monto_pagado )
        }

        for( const i in this.desglose ){
          let { desglose, total } = this.desglose[i]

          for( const j in desglose ){
            let { monto_pagado } = desglose[j]
            this.desglose[i].desglose[j].monto_pagado      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( monto_pagado )
          }

          this.desglose[i].total      = new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( total )
        }

      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },


    getDiferenciaCiclos() {

      var payload = {
        id_ciclo: this.cicloDiferencia,
      };

      this.cargar = true

      this.diferencias = []
      this.$http.post("inscripciones.comparaciones.ciclo", payload).then((response) => {
        this.cargar = false
        this.diferencias = response.data;
      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    abrirDialog(value) {
      console.log(value);
      this.alumnos = value;
      this.dialogCifras = true;
    },

    exportar(value) {
      this.exportExcel(value);
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },

    exportExcel(value) {
      let data = XLSX.utils.json_to_sheet(value);
      const workbook = XLSX.utils.book_new();
      const filename = "cifras_" + this.plantel;
      XLSX.utils.book_append_sheet(workbook, data, filename);

      var wbout = XLSX.write(workbook, {
        bookType: "xls",
        bookSST: false,
        type: "binary",
      });

      const file = new File([new Blob([this.s2ab(wbout)])], filename + ".xls");

      let formData = new FormData();
      // //se crea el objeto y se le agrega como un apendice el archivo
      formData.append("file", file);
      // /*getDatosRiesgo the form data*/

      this.$http
        .post("pdfs", formData)
        .then((response) => {
          window.location =
            axios.defaults.baseURL + "pdfs/" + filename + ".xls";
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },

    verImagen(value) {            //Angel Rodriguez              
      this.imagen = value;
      this.dialgImagen = true;
    },

    generarRecibo( ){

      const existePlantel = this.planteles.find( el => el.id_plantel == this.plantel )

      var payload = {
        desglose: this.tablaMovimientos,
        montos  : this.tablaPagosSucursal,
        usuario : this.getdatosUsuario.nombre_completo,
        fecha   : this.fecha_corte,
        plantel : existePlantel ? existePlantel.plantel : ''
      };

      this.cargar = true

      this.diferencias = []

      this.$http.post("corte.recibo", payload).then((response) => {

        this.cargar = false

        const url = axios.defaults.baseURL + 'recibo-pago/' + response.data.folio + '.pdf'

        window.open( url )

        this.validarSuccess( response.data.message )

      }).catch((error) => {
        this.validarError(error);
      }).finally(() => { this.cargar = false; });
    },

    exportar2( ){

      const alumnos = this.tablaMovimientos.map(({ id_ingreso, fecha_pago, monto_pagado, alumno, ciclo, plantel }) => ({ id_ingreso, fecha_pago, monto_pagado, alumno, ciclo, plantel }));

      this.exportExcel( alumnos)

    }
  },
};
</script>