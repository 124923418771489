<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Horas Extras Equipo</span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="3" >
                <v-text-field
                  filled          
                  label="Fecha"
                  type="date"
                  hide-details
                  v-model="fecha"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>             
             <v-spacer></v-spacer>
              <v-col cols="12" md="6">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="horasextras"
                  class="elevation-0"
                  :search="search"
                  :mobile-breakpoint="100"
                  dense
                >

                  <template v-slot:item.escuela="{ item }">
                    <v-chip color="blue"  small dark v-if="item.escuela == 1"
                      >INBI</v-chip
                    >
                    <v-chip color="red" small dark v-if="item.escuela == 2"
                      >FAST</v-chip
                    >
                  </template>


                  <template v-slot:item.estatus="{ item }">
                    <v-chip color="orange"  small dark v-if="item.estatus == 0"
                      >En revisión</v-chip
                    >
                    <v-chip color="green" small dark v-if="item.estatus == 1"
                      >Aceptado</v-chip
                    >
                    <v-chip color="black" small dark v-if="item.estatus == 2"
                      >Rechazado</v-chip
                    >
                  </template>

                  <template v-slot:item.estatus_jefe="{ item }">
                    <v-chip color="orange"  small dark v-if="!item.estatus_jefe"
                      >Pendiente</v-chip
                    >
                    <v-chip color="green" small dark v-if="item.estatus_jefe == 1"
                      >Aceptado</v-chip
                    >
                    <v-chip color="black" small dark v-if="item.estatus_jefe == 2"
                      >Rechazado</v-chip
                    >
                  </template>

                  <template v-slot:item.aceptar="{ item }">
                    <v-btn
                    	v-if="!item.estatus_jefe"
                      color="primary"
                      dark
                      small
                      @click="
                          (horaextramensaje = item), (dialogAceptar = true)
                      "
                    >
                    	ACEPTAR
                    </v-btn>
                  </template>

                    <!--BOTON Rechazar -->
                  <template v-slot:item.rechazar="{ item }">
                    <v-btn
                    	v-if="!item.estatus_jefe"
                      color="secondary"
                      dark
                      small
                      @click="(horaextramensaje = item), (dialog_mensaje_rechazo = true)"
                    >
                    	RECHAZAR
                    </v-btn>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small @click="deleteItem(item)" color="error">
                      mdi-delete
                    </v-icon>
                  </template>

                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize" small>
                      Actualizar
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

     <v-dialog v-model="dialogAceptar" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Aceptar:</strong> ¿Estás seguro que deseas aceptar las horas extras?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="dialogAceptar=false"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="aceptarHorasExtras()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/contrato.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_mensaje_rechazo" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                  class="text-h6"
                  style="display: table-cell; vertical-align: middle"
                >
                  <strong>Aceptar:</strong> ¿Estás seguro que deseas rechazar las horas extras?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn
                      color="error"
                      text
                      large
                      class="mr-2"
                      @click="dialog_mensaje_rechazo=false"
                      >No, cancelar</v-btn
                    >
                    <!-- Guardar la información  -->
                    <v-btn
                      color="#5C5C5C"
                      dark
                      class="elevation-6"
                      large
                      @click="rechazarHorasExtras()"
                      >Si</v-btn
                    >
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/contrato.jpg"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />
  </v-container>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {
  mixins: [validarErrores, funcionesExcel],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
    // Alertas
    parametros: {
      dialogError: false,
      mensaje: "",
      color: "",
    },

    respuestaAlerta: false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogAsistencias: false,
    dialogDelete: false,
    dialogAceptar: false,
    headers: [
      { text: "ID"            , value: "idhoras_extras" },
      { text: "Nombre"        , value: "nombre_completo" },
      { text: "Fecha"         , value: "fecha" },
      { text: "Hora Entrada"  , value: "hora_inicio" },
      { text: "Hora Salida"   , value: "hora_fin" },
      { text: "Horas Extras"  , value: "cantidad_horas" },
      { text: "Minutos Extras", value: "cantidad_minutos" },
      { text: "Escuela"       , value: "escuela" },
      { text: "Plantel"       , value: "plantel" },
      { text: "Razón/Notas"   , value: "notas" },
      { text: "Estatus"       , value: "estatus" },
      { text: "Estatus Jefe"  , value: "estatus_jefe" },
      { text: "Aceptar"       , value: "aceptar" },
      { text: "Rechazar"      , value: "rechazar" },
    ],
   
    horasextras: [],
    editedIndex: -1,
    editedItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: "",
      notas: "",
      estatus: 0,
      id_creo: ""
    },
    defaultItem: {
      idhoras_extras: "",
      fecha: "",
      hora_inicio: "",
      hora_fin: "",
      cantidad_horas: "",
      notas: "",
      estatus: 0,
      id_creo: ""
    },

    dialogSuccess: false,
    overlay: false,
    dialogError: false,
    error: "",
    successMensaje: "",
    errorBack: false,
    textoCopiado: "COPIAR",
    idcurso: 0,
    info: 0,
    dialog_mensaje_rechazo: false,
    idhistorial: [],
    fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    horaextramensaje: null,
    editedItem2: {
      mensaje: "",
    },

    mensajes: [],

    ciclos: [],
    ciclo: null,
    estatus: null,
    horasExtras: "",
    // tabla
    search: "",
  }),

  computed: {
    ...mapGetters("login", ["getdatosUsuario"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Agregar Horas Extras"
        : "Editar Horas Extras";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },

    "editedItem.escuela"(val) {
      this.cursosEscuela = this.cursos.filter((el) => {
        return el.escuela == val;
      });
    },
  },

  async created() {
    await this.getOrganigrama( )
    await this.initialize();
  },

  methods: {
    initialize() {
      this.horasextras = [];
      this.overlay = true;

      const payload = {
        id_creo: this.getdatosUsuario.iderp,
        fecha:  this.fecha
      };

      return this.$http.post("horas_extras.get.horasextrasall", payload).then((response) => {
        this.horasextras = response.data;

        let puesto          = this.getdatosUsuario.puesto
				let mapIdPlanteles  = this.getdatosUsuario.planteles.map((registro) => registro.idplantel);

				if( this.getdatosUsuario.iderp == 568 ){ puesto = 'Jefe de operaciones' }

				if( this.getdatosUsuario.iderp == 626 ){
					puesto         = 'COORDINADOR ZONA' 
					mapIdPlanteles = [32,21,4,6] 
				}

		    if( this.getdatosUsuario.iderp == 7 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [29,24,1,23] 
		    }

		    if( this.getdatosUsuario.iderp == 526 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [22,2,3] 
		    }

		    if( this.getdatosUsuario.iderp == 1314 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [25,28] 
		    }

		    if( this.getdatosUsuario.iderp == 1312 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [27,5,31,17] 
		    }

		    if( this.getdatosUsuario.iderp == 382 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [16,7,11,9] 
		    }

		    if( this.getdatosUsuario.iderp == 161 ){
					puesto         = 'COORDINADOR ZONA' 
		    	mapIdPlanteles = [14,8,12,10] 
		    }

		    if( this.getdatosUsuario.iderp == 28 ){
		    	puesto = 'Jefe Finanzas'
		    }

		    let puestosDependientes = this.organigrama.filter( el => el.puesto_jefe == puesto ).map((registro) => { return registro.idpuesto_auxiliar })
			    
		    if( this.getdatosUsuario.iderp == 954 ){
		    	puestosDependientes = this.organigrama.map((registro) => { return registro.idpuesto_auxiliar })
		    }

				if( this.getdatosUsuario.puesto == 'Encargada de sucursal' && this.getdatosUsuario.iderp != 568 ){
					this.horasextras = this.horasextras.filter( el => mapIdPlanteles.includes( el.id_plantel ) )
				}else if( puesto == 'COORDINADOR ZONA' ){
					this.horasextras = this.horasextras.filter( el => mapIdPlanteles.includes( el.id_plantel ) )
				}else if( puesto == 'ENCARGADA DE SUCURSAL' ){
					this.horasextras = this.horasextras.filter( el => puestosDependientes.includes( el.id_puesto ) && mapIdPlanteles.includes( el.id_plantel )  )
				}else{
					this.horasextras = this.horasextras.filter( el => puestosDependientes.includes( el.id_puesto ) )
				}

        this.overlay = false;
      }).catch((error) => {
        this.validarError( error.response.data.message )
      });
    },

    getOrganigrama( ){
			this.organigrama = [];
      this.overlay = true;

      const payload = {
        id_creo: this.getdatosUsuario.iderp,
        fecha:  this.fecha
      };

      this.$http.get("usuarios.organigrama").then((response) => {
	      this.organigrama = response.data;

						

        this.overlay = false;
      }).catch((error) => {
        this.validarError( error.response.data.message )
      });
    },

    editItem(item) {
      this.editedIndex = this.incidencias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    editItem2(item) {
      this.editedItems = Object.assign({}, item)
      this.dialogAsistencias = true
    },

    deleteItem(item) {
      this.editedIndex = this.horasextras.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // Lo mandapos por el EP
      this.editedItem.deleted = 1;

      this.$http
        .put(
          "horas_extras.update/" + this.editedItem.idhoras_extras,
          this.editedItem
        )
        .then((response) => {
          this.dialogSuccess = true;
          this.overlay = false;
          this.closeDelete();
          this.initialize();
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
    },

    close() {
      this.dialog = false;
      this.dialogAsistencias = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      //   if (this.editedItem.regla == "") {
      //     this.error = "Favor de llenar todos los campos";
      //     this.errorBack = false;
      //     this.overlay = false;
      //     this.dialogError = true;
      //     return;
      //   }
      if (this.editedIndex > -1) {
        // Activamos el loader
        this.overlay = true;
        this.editedItem.deleted = 0;
        // Lo mandapos por el EP
        this.$http
          .put(
            "reglamento.escuela.update/" + this.editedItem.idreglamento,
            this.editedItem
          )
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      } else {
        // Activamos el loader
        this.overlay = true;
        this.editedItem.id_creo = this.getdatosUsuario.iderp;
        this.editedItem.cantidad_horas = this.horasExtras;
        // Lo mandapos por el EP
        this.$http
          .post("horas_extras.add.horasextras", this.editedItem)
          .then((response) => {
            this.dialogSuccess = true;
            this.initialize();
            this.overlay = false;
          })
          .catch((error) => {
            // INdicamos que hay un error en el back
            if (error.body.message) {
              this.error = error.body.message;
            } else {
              this.error = error.body;
            }
            this.errorBack = true;
            this.overlay = false;
            this.dialogError = true;
          });
      }
      this.close();
    },

    copiarPortapapeles() {
      navigator.clipboard
        .writeText(this.error)
        .then(() => {
          this.textoCopiado = "COPIADO";
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },

    //Trae la informacion de toda la fila de alumnos en "Agregar Incidencia"
    infoAlumnos() {
      const alumnoSeleccionado = this.alumnos.find(
        (alumno) => alumno.iderp === this.editedItem.idusuarioerp
      );

      this.info = alumnoSeleccionado.tipo_curso;
     
    },

    getMensajesRechazo() {
      this.cargar = true;
      this.mensajes = [];

      const payload = {
        idhoras_extras: this.idhistorial.idhoras_extras,
        iderp: this.idhistorial.id_creo,
      };
      return this.$http
        .post("consultar.mensajes.rechazo.horasextras", payload)
        .then((response) => {
          this.mensajes = response.data;
          this.cargar = false;
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


     enviarMensajeRechazo() {
      this.cargar = true;
      const payload = {
        idhoras_extras: this.idhistorial.idhoras_extras,
        nota: this.editedItem2.mensaje,
        iderp: this.idhistorial.id_creo,
        id: this.idhistorial.idhoras_extras,
        estatus: 2,
      };
      return this.$http
        .post("add.horasextras.mensaje", payload)
        .then((response) => {
          this.$http
            .post("update.estatus.horasextras", payload)
            .then((response) => {
              this.validarSuccess(response.data.message);
              this.cargar = false;
              this.dialog_mensaje_rechazo = false;
              this.initialize();
              this.editedItem2.mensaje = null;
            })
            .catch((error) => {
              this.validarError(error);
            })
            .finally(() => {
              this.cargar = false;
            });
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


    enviarMensajeRespuesta() {
      this.cargar = true;
      const payload = {
        nota_respuesta: this.editedItem2.mensaje,
        idhoras_extras: this.idhistorial.idhoras_extras,
        id_horas_extras2: this.idhistorial.idhoras_extras2,
        iderp: this.idhistorial.iderp,
        iderp2: this.idhistorial.iderp,

        id: this.idhistorial.idhoras_extras,
        estatus: 0,
      };

      
      return this.$http
        .post("update.horasextras.mensaje.respuesta", payload)
        .then((response) => {
          this.$http
            .post("update.estatus.horasextras", payload)
            .then((response) => {
              this.validarSuccess(response.data.message);
              this.cargar = false;
              this.dialog_mensaje_rechazo = false;
              this.initialize();
              this.editedItem2.mensaje = null;
            })
            .catch((error) => {
              this.validarError(error);
            })
            .finally(() => {
              this.cargar = false;
            });
        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
    },


    aceptarHorasExtras() {

      this.cargar = true;
      const payload = {
        estatus_jefe: 1,
        id          : this.horaextramensaje.idhoras_extras,
        jefe_directo: this.getdatosUsuario.iderp,
      };

      return this.$http.post("horas_extras.update.estatus.jefe", payload).then((response) => {
          this.dialogAceptar = false;
          this.horaextramensaje = null;
          this.initialize();
          this.cargar = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    rechazarHorasExtras() {

      this.cargar = true;
      const payload = {
        estatus_jefe: 2,
        id          : this.horaextramensaje.idhoras_extras,
        jefe_directo: this.getdatosUsuario.iderp,
      };

      return this.$http.post("horas_extras.update.estatus.jefe", payload).then((response) => {
          this.dialog_mensaje_rechazo = false;
          this.horaextramensaje = null;
          this.initialize();
          this.cargar = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },

    goNomina(){
			this.$router.push({name: "Nomina"})
		},

    validarHoraFin() {
      if (
        this.editedItem.hora_inicio !== null &&
        this.editedItem.hora_fin !== null
      ) {
        // Obtener objetos de fecha y hora para hora_inicio y hora_fin
        const horaInicio = new Date(`2023-09-26T${this.editedItem.hora_inicio}`);
        const horaFin = new Date(`2023-09-26T${this.editedItem.hora_fin}`);

        // Realizar la comparación de horas
        if (horaFin <= horaInicio) {
          // Hora Fin es menor o igual a Hora Inicio, lo cual es inválido
          // Puedes manejar esto según tus necesidades, por ejemplo, mostrar un mensaje de error.
          // En este ejemplo, simplemente establecemos Hora Fin en nulo.
          this.editedItem.hora_fin = null;
        }
      }

    }


  },
};
</script>
