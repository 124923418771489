<template>
  <v-container class="mt-5 ml-2 pr-6" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-stepper v-model="e1">
			    <v-stepper-header class="elevation-0">
			      <v-stepper-step
			        :complete="e1 > 1"
			        step="1"
			      >
			        Folio
			      </v-stepper-step>

			      <v-divider></v-divider>

			      <v-stepper-step
			        :complete="e1 > 2"
			        step="2"
			      >
			        Datos
			      </v-stepper-step>

			      <v-divider></v-divider>

			      <v-stepper-step step="3">
			        Firma
			      </v-stepper-step>
			    </v-stepper-header>

			    <v-stepper-items >
			      <v-stepper-content step="1" class="pt-0 pb-1">
			        <v-card>
			        	<v-card-text>
			        	  <v-row justify="center">
			        	  	<v-col cols="12" md="6" lg="4">
			        	  		<v-text-field
			        	  			label="Folio"
			        	  			v-model="folio"
			        	  			dense
			        	  			outlined
			        	  			hide-details
			        	  		></v-text-field>
			        	  	</v-col>
			        	  </v-row>
			        	</v-card-text>		
			        	<v-card-actions>
					        <v-btn
					          color="primary"
					          @click="validaFolio()"
					          small
					        >
					          Siguiente
					        </v-btn>
								</v-card-actions>
			        </v-card>
			        
			      </v-stepper-content>

			      <!-- Paso 2 -->
			      <v-stepper-content step="2" class="pt-0 pb-1">
			        <v-card>
			        	<v-card-text>
		              <v-container>
		                <v-row>
		                  <v-col
		                    cols="12"
		                    sm="6"
		                    md="4"
		                  >
		                    <v-text-field
		                      v-model="nombre_completo"
		                      label="Nombre completo"
		                      outlined
		                      dense
		                      hide-details
		                    ></v-text-field>
		                  </v-col>

		                  <v-col
		                    cols="12"
		                    sm="6"
		                    md="4"
		                  >
		                    <v-text-field
		                      v-model="calle"
		                      label="Calle"
		                      outlined
		                      dense
		                      hide-details
		                    ></v-text-field>
		                  </v-col>

		                  <v-col
		                    cols="12"
		                    sm="6"
		                    md="4"
		                  >
		                    <v-text-field
		                      v-model="numext"
		                      label="Num. Ext"
		                      outlined
		                      dense
		                      hide-details
		                    ></v-text-field>
		                  </v-col>

		                  <v-col
		                    cols="12"
		                    sm="6"
		                    md="4"
		                  >
		                    <v-text-field
		                      v-model="colonia"
		                      label="Colonia"
		                      outlined
		                      dense
		                      hide-details
		                    ></v-text-field>
		                  </v-col>

		                  <v-col
		                    cols="12"
		                    sm="6"
		                    md="4"
		                  >
		                    <v-text-field
		                      v-model="ciudad"
		                      label="Ciudad"
		                      outlined
		                      dense
		                      hide-details
		                    ></v-text-field>
		                  </v-col>

		                  <v-col
		                    cols="12"
		                    sm="6"
		                    md="4"
		                  >
		                    <v-text-field
		                      v-model="cp"
		                      label="CP"
		                      outlined
		                      dense
		                      hide-details
		                    ></v-text-field>
		                  </v-col>

		                  <v-col
		                    cols="12"
		                    sm="6"
		                    md="4"
		                  >
		                    <v-text-field
		                      v-model="estado"
		                      label="Estado"
		                      outlined
		                      dense
		                      hide-details
		                    ></v-text-field>
		                  </v-col>

		                </v-row>
		              </v-container>
		            </v-card-text>
			        	<v-card-actions>
					        <v-btn color="primary" text small @click="e1 = 1">Regresar</v-btn>
					        <v-btn
					          color="primary"
					          @click="updateDatos()"
					          small
					        >
					          Siguiente
					        </v-btn>
								</v-card-actions>
			        </v-card>
			      </v-stepper-content>

			      <v-stepper-content step="3" class="pt-0 pb-1">
			        <v-card outlined class="mb-2">
				  		  <div>
								  <canvas id="signature-pad" class="signature-pad" :width="width" :height="height"></canvas>
								</div>
								<v-card-actions>
					        <v-btn
					          color="primary"
					          @click="savePNG()"
					          small
					          class="mr-2"
					        >
					          Generar contrato
					        </v-btn>

					        <v-btn
					          text
					          color="primary"
					          @click="limpiar()"
					          small
					        >
					        	<span v-if="this.$vuetify.breakpoint.name == 'xs'">
					        		<v-icon color="primary">mdi-eraser</v-icon>
					        	</span>
					        	<span v-else>
					          	Limpiar
					        	</span>
					        </v-btn>
					        <v-spacer></v-spacer>
					        <v-btn color="primary" small @click="e1 = 2" text>
					        	<span v-if="this.$vuetify.breakpoint.name == 'xs'">
					        		<v-icon color="primary">mdi-keyboard-return</v-icon>
					        	</span>
					        	<span v-else>
					          	Regresar
					        	</span>
					        </v-btn>
								</v-card-actions>
				  		</v-card>
			      </v-stepper-content>
			    </v-stepper-items>
			  </v-stepper>
	  	</v-col>
	  </v-row>

	  <!-- Dialog de carga -->
    <v-overlay :value="overlay">
      <v-card
      	width="300"
        color="primary"
        dark
      >
        <v-card-text  class="text-subtitle-1">
          Cargando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogAfimativo"
      hide-overlay
      max-width="600"
    >
      <v-card style="position: relative" class="elevation-0 pt-3" >
  			<v-img src="@/assets/contrato.jpg" contain aspect-ratio="2"></v-img>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogAfimativo = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-actions>
        	<v-spacer></v-spacer>
        	<v-btn color="secondary" class="elevation-12" @click="dialogAfimativo = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card
        color="green"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Datos grabados
        </v-card-title>
        <div class="pl-6 pb-6">
          <br/>
          NOTA: {{successMensaje}}
        </div>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogSuccess = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
	  
    <!-- Dialogo para indicar errores -->
    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-title primary-title class="pb-0">
          Error al recuperar los datos
        </v-card-title>
        <v-card-text>
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn 
        	color="transparent elevation-0"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

	</v-container>
</template>
<script>
import axios from 'axios';
	import SignaturePad from 'signature_pad'

  export default {
    data: () => ({
    	dialogAfimativo: false,
      e1: 1,
      signaturePad:null,
  		file:'',
  		folio:'',

			dialogError: 	false,
			dialogSuccess:false,
			error: 				'',
			successMensaje:'',
			overlay: 			false,
			overlay:false,
			dialogError: false,
			error:'',

			nombre_completo:'',
			direccion:'',
			calle:'',
			numext:'',
			cp:'',
      ciudad:'',
      colonia:'',
      estado:'',
      numext:'',
      id:0
   }),

    created () {
    	if(this.$route.params.folio){
    		this.folio = this.$route.params.folio
    	}
    },

    mounted () {
			let canvas = document.getElementById('signature-pad');
			this.signaturePad = new SignaturePad(canvas);
    },

    methods:{
    	async savePNG (){
    		if(this.signaturePad.isEmpty()){
    			this.openDialogError('Necesitas ingresar una firma')
    		}else{
    			this.overlay = true

	    		let url = this.signaturePad.toDataURL();

	    		// Archivo blob binario
					let blobBin = atob(url.split(',')[1]);
					let array = [];
					// Recorremos el resultado
					for(let i = 0; i < blobBin.length; i++) {
				    array.push(blobBin.charCodeAt(i));
					}

					// Convertimos a bloc
					const file = new Blob([new Uint8Array(array)], {type: 'image/png'});
					// Lo convertimos a archivo para poder agregarle una imagen
					const file2 = new File([file], this.folio+".png",{ type: "image/png" })

	    		// Creamos el documento
	    		let formData = new FormData();
	    		// agregamos el documento
	    		formData.append("file", file2);
	    		// Guardamos el documento
	    		this.$http.post('crear-contrato/' + this.id, formData).then(response=>{
	    			this.e1 = 1
	    			this.overlay = false
	    			this.dialogAfimativo = true
	    			// this.openDialogSuccess('Contrato generado correctamente')
	    		  // window.open(axios.defaults.baseURL + 'contratos/' + this.folio + '.pdf')
	    		}).catch(error=>{
	    			this.openDialogError(error.body.message)
	    		})
    		}
    	},

    	limpiar (){
				this.signaturePad.clear();
    	},

    	updateDatos(){
    		if(this.nombre_completo == '' || this.numext == '' || this.colonia == '' || this.ciudad == '' || this.cp == '' || this.estado == '' || this.calle == ''){
    			this.openDialogError('Llena todos los datos')
    		}else{
	    		if(this.folio != ''){
	    			this.overlay = true
	    			const payload = {
	    				folio: 						this.folio,
	    				nombre_completo: 	this.nombre_completo,
	    				direccion: 				this.calle + ', ' + this.numext + ', ' + this.colonia + ', ' + this.ciudad + ', ' + this.cp + ', ' + this.estado
	    			}
	    			this.$http.post('operaciones.contrato.actualiza.datos', payload).then(response=>{
	    				this.e1 = 3
	    				this.overlay = false
		    		}).catch(error=>{
		    			this.overlay = false
	        		this.openDialogError(error.body.message)
		    		})
	    		}else{
	    			this.openDialogError('Lo sentimos, hubo un error')
	    		}
    		}
    	},

    	validaFolio(){
    		if(this.folio != ''){
    			this.overlay = true
    			const payload = {
    				folio: this.folio
    			}
    			this.$http.post('operaciones.contrato.valida', payload).then(response=>{
	    			if(response.data.estatus == 1){
    					this.e1 = 2
    					this.id = response.data.id
	    			}else{
    					// this.openDialogSuccess(response.data.message)
    					this.dialogAfimativo = true
	    			}
    				this.overlay = false
	    		}).catch(error=>{
	    			this.overlay = false
        		this.openDialogError(error.body.message)
	    		})
    		}else{

    		}
    	},

    	openDialogError (mensaje) {
      	this.error       = mensaje
      	this.dialogError = true
      },

      openDialogSuccess ( mensaje ){
      	this.successMensaje = mensaje
      	this.dialogSuccess  = true
      },
    },

    computed:{
    	width(){
    		return this.$vuetify.breakpoint.width
    	},
    	height(){
    		return this.$vuetify.breakpoint.height - 250
    	}
    }
  }
</script>