<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card-text class="pa-0">

          <div class="black--text text-subtitle-1">
            <b>Reporte generado por:</b> {{ getdatosUsuario.nombre_completo }}
            <br/>
            <v-chip>
              <b>Tipo de reporte: </b> Cambio de grupo
            </v-chip>
            <br/>
          </div>

          <v-row v-if="alumno">
            <v-col cols="12" class="pa-0">
              <v-card-text>
                <b>Matricula:</b> {{ alumno.matricula }}
                <br/>
                <b>Nombre:</b>    {{ alumno.alumno }}
                <br/>
                <v-divider></v-divider>

                <br/>
                <v-row>
                  <v-col cols="12" md="6" >
                    <v-autocomplete
                      class="mt-2"
                      filled
                      dense
                      clearable
                      v-model="grupoAnterior"
                      :items="grupoAlumnos"
                      label="Grupo actual"
                      persistent-hint
                      prepend-inner-icon="mdi-clipboard-outline"
                      return-object
                      item-text="grupo"
                      item-value="id_grupo"
                    >
                    </v-autocomplete>
                    <label v-if="grupoAnterior"><b>{{ grupoAnterior.grupo }}</b></label>
                    <br/>

                    <div v-if="grupoAnterior" class="mb-10">
                      <v-row>
                        <v-col cols="6" class="text-right pb-0">
                          <b>Costo curso:</b>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          {{ grupoAnterior.precio_grupo }}
                        </v-col>

                        <v-col cols="6" class="text-right pb-0">
                          <b>Pagado:</b>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          {{ grupoAnterior.pagado }}
                        </v-col>

                        <v-col cols="12">
                          <b>Concentrado de cambios</b>
                          <br/>
                          <p 
                            style="white-space: pre-line"
                          >
                            {{ textoCambio }}
                          </p>

                        </v-col>
                      </v-row>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6" >
                    <v-autocomplete
                      class="mt-2"
                      filled
                      dense
                      clearable
                      v-model="nuevoGrupo"
                      :items="grupos"
                      label="Nuevo Grupo"
                      persistent-hint
                      prepend-inner-icon="mdi-clipboard-outline"
                      return-object
                      item-text="grupo"
                      item-value="id_grupo"
                    >
                    </v-autocomplete>

                    <label v-if="nuevoGrupo"><b>{{ nuevoGrupo.grupo }}</b></label>
                    <br/>
                    
                    <div v-if="nuevoGrupo" class="mb-10">
                      <v-row>
                        <v-col cols="6" class="text-right pb-0">
                          <b>Costo curso:</b>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          {{ costoCurso }}
                        </v-col>

                        <v-col cols="6" class="text-right pb-0">
                          <b>Total a pagar:</b>
                        </v-col>
                        <v-col cols="6" class="pb-0">
                          {{ total_a_pagar }}
                        </v-col>

                        <v-col cols="6" class="text-right pb-0" v-if="grupoAnterior.pagado - total_a_pagar > 0">
                          <b>Saldo a Favor:</b>
                        </v-col>
                        <v-col cols="6" class="pb-0" v-if="grupoAnterior.pagado - total_a_pagar > 0">
                          {{ grupoAnterior.pagado - total_a_pagar }} 
                        </v-col>

                        <v-col cols="12" class="pb-0">
                          <v-autocomplete
                            v-model="precioSeleccionado"
                            :items="precios"
                            @change="getPrecioPagar"
                            item-text="curso"
                            item-value="id_curso"
                            filled
                            dense
                            readonly
                          ></v-autocomplete>
                        </v-col>


                        <v-autocomplete
                          v-if="grupoAnterior.pagado - total_a_pagar > 0"
                          class="mt-2"
                          filled
                          dense
                          clearable
                          v-model="id_grupo_favor"
                          :items="grupos"
                          label="Grupo a mandar el saldo a favor"
                          persistent-hint
                          prepend-inner-icon="mdi-clipboard-outline"
                          item-text="grupo"
                          item-value="id_grupo"
                        >
                        </v-autocomplete>


                        <v-col cols="12">
                          <v-textarea
                            label="Por favor, ingresa el motivo de tu solicitud"
                            v-model="motivo"
                            filled
                          ></v-textarea>

                          <v-file-input 
                            small-chips 
                            multiple 
                            filled 
                            v-model="files" 
                            accept="image/png, image/jpeg, image/bmp"
                            placeholder="Selecciona tus imagenes" prepend-icon="mdi-camera" label="Evidencias" @change="cargarFotos()">
                          </v-file-input>

                          <v-row v-if="vistaPrevias.length">
                            <v-col cols="12" md="6" v-for="(img, i) in vistaPrevias" :key="i">
                              <v-card class="py-4 shadowCard">
                                <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                                <v-btn  color="error"  small  @click="eliminarFoto(img.url)" top right absolute fab >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>

                <br/>


                <v-alert
                  dense
                  border="left"
                  type="warning"
                  class="mt-2"
                >
                  Recuerda que al hacer un  <strong>CAMBIO</strong> de grupo, al alumno se le  <strong>BORRARÁN</strong> los datos, como lo son: <strong>ASISTENCIAS, EJERCICIOS y EXÁMENES</strong>
                </v-alert>
              </v-card-text>

              <v-card-actions>
              	<v-spacer></v-spacer>
              	<v-btn
			            v-if="nuevoGrupo && grupoAnterior && motivo"
			            color="green"
			            dark
			            tile
                  @click="confirmacion.estatus = true"
			            :loading="loading"
			            :disabled="disabled" 
			          >
			            <v-icon small left>mdi-send</v-icon>
			            Enviar solicitud
			          </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>

    <dialogConfirmacion   
      v-if="confirmacion.estatus"        
      :confirmacion="confirmacion"
      @addCambio="addCambio"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
	var moment = require('moment');
  moment.locale();
	import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';


  import dialogConfirmacion    from '@/components/cambios/dialogConfirmacion.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    components:{
      Alerta,
      carga,
      dialogConfirmacion
    },

    mixins: [ validarErrores, funcionesExcel ],

    props: ['alumno','reporteTexto'],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      search: '',
      
      tipociclo:'',
      grupos:[],
      nuevoGrupo:null,
      tipocambios:[],
      tipoCambio:null,
      alumnos:[],

      grupoAlumnos:   [],
      grupoAnterior:  null,
      
      loading:        false,
      disabled:       false,
      motivo:         null,

      total_a_pagar: 0,
      precios:[],

      dataGrupoActual: null,
      dataNuevoGrupo:null,
      precioSeleccionado2: null,
      descuentoHermanos:0,

      tipo_reporte:null,

      url:'',

      fotos:[],
      files: [],
      vistaPrevias:[],

      textoCambio: "",

      costoCurso: 0,
      id_grupo_favor:0,
      confirmacion: {
        estatus: false
      }

    }),

    watch:{
      grupoAnterior () {
        if(this.grupoAnterior){
          this.getNuevoGrupoAlumno ()
        }else{
          this.grupos = []
        }
      },

      nuevoGrupo( value ){
        console.log( value, this.grupoAnterior )
        this.precioSeleccionado = value ? value.id_curso : null

        this.textoCambio = ''

        if( value && value.id_plantel != this.grupoAnterior.id_plantel ){
          this.textoCambio = 'Cambio de Plantel'
        }

        if( value && value.id_ciclo != this.grupoAnterior.id_ciclo ){
          this.textoCambio += `
          Cambio de Ciclo`
        }

        if( value && value.id_horario != this.grupoAnterior.id_horario ){
          this.textoCambio += `
          Cambio de Horario`
        }

        if( value && value.id_nivel != this.grupoAnterior.id_nivel ){
          this.textoCambio += `
          Cambio de Nivel`
        }

        if( value ){
          this.getPrecioPagar()
        }



      },

      dataNuevoGrupo( value ){
        this.precioSeleccionado = value ? value.id_curso : null
      },

      // precioSeleccionado( value ){
        // if( value ){ this.getPrecioPagar() }
      // },

      precioSeleccionado2( value ){
        if( value ){ this.getPrecioPagar2() }
      },

      tipo_reporte( val ){
        if( val ){ this.step += 1 }
      }
    },

    async created () {
      if([1,2,3,4,5,6,17,19,21,22,23,24,25,26].includes( this.getdatosUsuario.id_plantel ) ){
        this.tipociclo = `grupo NOT LIKE '%FE%'`
      }else{ this.tipociclo = `grupo LIKE '%FE%'` }
      
      // Cargar los cursos actuales
      await this.getCursos()

      await this.getGrupoUsuario( )

      await this.getTitpoCambios()
    },

    methods: {
      getCursos( ){
        this.cargar = true
        this.precios = []
        this.$http.get("all.precios").then((response) => {
          this.cargar = false
          this.precios = response.data.filter( el => el.curso.split("-")[1].match("3") );
        }).catch((error) => {
          this.validarError(error);
        }).finally(() => {
          this.cargar = false;
        });
      },

      getTitpoCambios () {
        this.cargar = true
        return this.$http.get('cambios.tipo.cambio').then(response=>{
          this.tipocambios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPrecioPagar( ) {
        this.total_a_pagar = 0
        const payload = {
          id_alumno: this.alumno.id_alumno,
          id_grupo:  this.alumno.id_grupo,
          escuela:   this.alumno.grupo.match('FAST') ? 2 : 1,
          id_curso:  this.precioSeleccionado,
          id_ciclo:  this.alumno.id_ciclo,
          alumno_ni: false
        }
        return this.$http.post('pago.grupo.nuevo.descuento', payload).then(response=>{
          console.log( response )


          if( this.precioSeleccionado ){
            this.total_a_pagar = response.data[0].precio_lista

            let existeCosto = this.precios.find( el => el.id_curso == this.precioSeleccionado )

            console.log( existeCosto )

            this.costoCurso = existeCosto ? existeCosto.precio_lista : 0

          }else{
            this.costoCurso = 0
          }


          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPrecioPagar2( ) {

        const payload = {
          id_alumno: this.alumno.id_alumno,
          id_grupo:  this.dataGrupoActual.id_grupo,
          escuela:   this.dataNuevoGrupo.grupo.match('FAST') ? 2 : 1,
          id_curso:  this.precioSeleccionado2,
          id_ciclo:  this.dataNuevoGrupo.id_ciclo,
          alumno_ni: false
        }

        return this.$http.post('pago.grupo.nuevo.descuento', payload).then(response=>{

          this.total_a_pagar      = response.data[0].precio_lista
          this.descuentoHermanos  = response.data[0].descuentoHermanos

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getNuevoGrupoAlumno () {

        console.log( this.alumno.grupo )
        if(this.grupoAnterior){

          let tipociclo = ''
          if( !this.alumno.grupo.match('FAST') ){
            tipociclo = `grupo NOT LIKE '%FE%'`
          }else{ tipociclo = `grupo LIKE '%FE%'` }

          this.cargar = true
          const payload =  { 
            tipociclo, 
            id_ciclo: this.grupoAnterior.id_ciclo
          }
          return this.$http.post('cambios.nuevo.grupos.ciclo',payload).then(response=>{
            this.grupos = response.data
            console.log( this.grupos )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }
      },

      getGrupoUsuario ( ){
        this.cargar = true
        this.grupoAlumnos = []
        return this.$http.get('cambios.grupo.usuario/' + this.alumno.id_alumno).then(response=>{
          this.grupoAlumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async addCambio () {

        // Validar que esten llenos los campos
        if(this.nuevoGrupo && this.grupoAnterior && this.motivo ){

      		this.cargar = true
        	
        	let fotos = []
          if(this.vistaPrevias.length){
            let respuesta = await this.subirFotosServidor( )
            fotos = respuesta.data
          }

          // Activar el disabled del boton para que no le den doble click
          // this.loading  = true
          // this.disabled = true
          // Desesestructuración de alumno 
          const { id_alumno, id_plantel, monto_pagado_total } = this.alumno

          // Generar el payload a mandar
          let payload = {
            id_alumno,
            idtipo_cambio:   0,
            solicito_cambio: this.getdatosUsuario.iderp,
            id_plantel,
            id_grupo_actual: this.grupoAnterior.id_grupo,
            id_grupo_nuevo:  this.nuevoGrupo.id_grupo,
            estatus:         1,
            diferencia:      monto_pagado_total,
            motivo:          this.motivo,
            fotos,
            tipo_reporte       : null,
            id_ingreso         : null,
            nuevo_comprobante  : null,
            viejo_metodo:      0,
            nuevo_metodo:      0,
            viejo_monto:       0,
            nuevo_monto:       0,
            vieja_fecha:       null,
            nueva_fecha:       null,
            textoCambio:       this.textoCambio,
            id_grupo_favor:    this.id_grupo_favor ? this.id_grupo_favor : 0
          }

          this.cargar = true

          // Enviar los datos 
          this.$http.post('cambios.add',payload).then(response=>{

            this.validarSuccess( 'Datos grabados correctamente')

            this.$emit('getAlumnosxEscuela')

          }).catch(error=>{
            this.validarError( error.response.data.message )
          })
        }else{
          this.estatusError('Favor de ingresar el grupo y el tipo de cambio')
        }
      },

      subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`cambios.evidencia`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },

      cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
                // creamos una variable tipo FormData
          let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          this.getBase64(element, formData)
        })
      },

      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log(file)
          me.vistaPrevias.push({
            url: reader.result, 
            formData: formData, 
            image_name: file.name,
            file
          })
        };
      },

      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },
    },

    computed:{
      ...mapGetters ('login',['getdatosUsuario']),

      currentTitle () {
        switch (this.step) {
          case 1: return 'Selecciona el alumno'
          case 2: return 'Selecciona tipo de reporte'
          default: return 'Confirma tu reporte'
        }
      },
    }
  }
</script>

<style>

  .v-menu__content::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-menu__content::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-button:increment,.v-menu__content::-webkit-scrollbar-button{
    display: none;
  }

  .v-menu__content::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-menu__content::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }
</style>