import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

//camara
import { Camera, CameraResultType } from '@capacitor/camera';
import { defineCustomElements }     from '@ionic/pwa-elements/loader';
import { Clipboard }                from '@capacitor/clipboard';
defineCustomElements(window);

const axios            = require('axios');


export default {
	methods: {
		async takePicture () {
			// Abrir la camara
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.DataUrl, format: "jpeg"
      });

      // Descargar imagen 
      var imageUrl = image.dataUrl;

      // Pasar a tipo archivo la imagen
      var img_b64 = imageUrl; //base64 de la imagen
      var png = img_b64.split(',')[1];
      var binary = this.fixBinary(window.atob(png));// <-- Usamos la fn "fixBinary"
      var the_file = new Blob([binary], {type: 'image/png'});// <-- Sacamos el encode
      this.archivos = new File([the_file], 'imagen_firma.png', { type: 'image/png' });

      this.file = this.archivos

      // Can be set to the src of an image now
      this.vistaPrevia = {
        url:imageUrl
      }
    },

    fixBinary (bin) {
      var length = bin.length;
      var buf = new ArrayBuffer(length);
      var arr = new Uint8Array(buf);
      for (var i = 0; i < length; i++) {
        arr[i] = bin.charCodeAt(i);
      }
      return buf;
    },


    async validarUsuario ( ) {
      const { type, value } = await Clipboard.read();
    },

    cargarArchivo(){
      if(!this.archivos){ return }
      //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
      this.procesarImagen2( )
      this.getBase64(this.archivos)
    },

    getBase64(file) {
      var me = this
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        me.vistaPrevia = {
          url: reader.result, 
          image_name: file.name
        }
      };
    },


    procesarImagen2( ){
      this.cargar = true
      this.isPracticaja = false
      // Creando los parametros
      var formData = new FormData();
      formData.append("file"             , this.archivos );
      formData.append("language"         , "spa" );
      formData.append("apikey"           , "K88754805388957" ); /* K88754805388957    K84790465788957 */ 
      formData.append("isOverlayRequired", false );
      formData.append("detectOrientation", true );
      formData.append("scale"            , true );
      formData.append("isTable"          , true );
      formData.append("OCREngine"        , 5 );

      //Send OCR Parsing request asynchronously
      axios({
        url: 'https://api.ocr.space/parse/image',
        data: formData,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST'
      }).then( ocrParsedResult => {
        //Get the parsed results, exit code and error message and details
        var parsedResults                = ocrParsedResult.data["ParsedResults"];
        var ocrExitCode                  = ocrParsedResult.data["OCRExitCode"];
        var isErroredOnProcessing        = ocrParsedResult.data["IsErroredOnProcessing"];
        var errorMessage                 = ocrParsedResult.data["ErrorMessage"];
        var errorDetails                 = ocrParsedResult.data["ErrorDetails"];
        var processingTimeInMilliseconds = ocrParsedResult.data["ProcessingTimeInMilliseconds"];

        //If we have got parsed results, then loop over the results to do something
        if (parsedResults!= null) {
          // Guardamos las palabras
          let palabras        = parsedResults[0]['TextOverlay']['Lines']
          this.texto_completo = parsedResults[0]['ParsedText']

          this.buscarBanco( palabras[0]['LineText'], palabras )

          // VALIDAR SI EL BANCO ES BBVA BUSCANDO SI AL FINAL DEL RECIBO DICE BBVA
          const wordBBVA   = palabras.filter( el => { return el.LineText == 'BBVA' })
          const folioBBVA  = palabras.find( el => el.LineText.match('Folio:'))

          let isBBVA       = false

          for( const i in wordBBVA ){
            const position = palabras.indexOf(wordBBVA[i])
            if( position == ( palabras.length - 1 )){ isBBVA = true }
          }

          if( folioBBVA && !isBBVA){
            const position = palabras.indexOf(folioBBVA)
            const result   = palabras[position + 1]
            if( result.LineText == 'BBVA'){ isBBVA = true }
          }

          ////////////////////////////////////////////////////////////////////////////


          // VALIDAR SI EL BANCO DE LA TRANSFERENCIA ES SANTANDER
          const wordSanta  = palabras.filter( el => { return el.LineText == 'Santander' })
          this.isSantander = false

          for( const i in wordSanta ){
            const position = palabras.indexOf(wordSanta[i])
            if( position == ( palabras.length - 1 )){ this.isSantander = true }
          }

          ///////////////////////////////////////////////////////////////////////////////

          // VALIDAR SI ES DIRECTO EN BANCOMER
          this.isDirectoBancoBBVA = ['BBVA', 'BBUA'].includes(palabras[0]['LineText'])

          // VALIDAR SI ES DIRECTO EN BANREGIO
          this.isDirectoBANREGIO = palabras.find( el => el.LineText.replace(/[0]/g,'O').match('BANREGIO GRUPO') )
          this.isDirectoBANREGIO = this.isDirectoBANREGIO ? true : palabras.find( el => el.LineText.replace(/[0]/g,'O').match('BANREDIO GRUPO')  )


          // VALIDAR SI ES PRACTICAJA DE BANCOMER
          const rfcBBVA = palabras.find( el => el.LineText.match('31LJ2') )
          this.isPracticaja = rfcBBVA ? true : false

          // VALIDAR SI ES SEVEN
          this.isSeven = palabras.find( el => el.LineText.match('7-ELEVEN') || el.LineText.match('7 ELEVEN') )

          // VALIDAR SI ES OXXO
          this.isOXXO = palabras.find( el => el.LineText.match('Oxxo') || el.LineText.match('Uxxo') || el.LineText.match('OXXO') || el.LineText.match('OxXo') 
          || el.LineText.match('0xxo') || el.LineText.match('@xxo') || el.LineText.match('oxxo') || el.LineText.match('0XXO') || el.LineText.match('OxxO') || el.LineText.match('Oaxa')
          || el.LineText.match('U0') || el.LineText.match('UxXO') || el.LineText.match('125KG8') )

          // VALIDAR SI ES WEB
          this.isWeb = palabras.find( el => el.LineText.match('Matricula') )

          // VALIDAR SI ES TERMINAL
          this.isTarjeta = palabras.find( el => el.LineText.match('8747359') || el.LineText.match('8747380') || el.LineText.match('8747391') || el.LineText.match('8747383') || el.LineText.match('8747364') || el.LineText.match('8747365') || el.LineText.match('8747367') || el.LineText.match('8747361') || el.LineText.match('8747413') || el.LineText.match('8747415'))

          // VALIDAR SI ES SPIN
          this.isSpin =  palabras.find( el => el.LineText.match('spin') )

          // Recorremos las palabras
          for( const i in palabras){

            // Validamos si existe ese símbolo $
            if( palabras[i]['LineText'].indexOf("$") >= 0 && !this.isPracticaja && !this.isDirectoBancoBBVA && !this.isDirectoBANREGIO){

              palabras[i]['LineText'] = palabras[i]['LineText'].replace(/[°]/g, '0')
              let preMonto = 0

              if(palabras[i]['LineText'].indexOf("$") >= 0){
                const preMontoSplit = palabras[i]['LineText'].split('$')
                preMonto            = preMontoSplit[ preMontoSplit.length - 1 ]
                let comas = preMonto.split(',')
                if( comas[ comas.length - 1 ].indexOf(".") == -1 && comas[ comas.length - 1 ].indexOf(",") >= 0 ){
                  if( comas[ comas.length - 1 ].length > 3 ){
                    const inicio = comas[ comas.length - 1 ].substr( 0, 3 )
                    const fin    = comas[ comas.length - 1 ].substr( 4, comas.length - 1 )
                    preMonto = comas[ 0 ] + inicio + '.' + fin

                    console.log( preMonto )
                  }
                }
              }else{
                // Reemplazamos los signos y hacemos un trim
                preMonto = palabras[i]['LineText'].replace(/[$,]/g,'').trim()
              }
              
              preMonto = preMonto.replace(/[^0-9.]/g, '').trim()

              // SEVEN, SOLO SEVEN
              if( this.isSeven ){
                // SE LE TIENE QUE RESTAR LA COMISIÓN
                if(  parseFloat(preMonto) > 15 ){ this.total_pagado = parseFloat( preMonto ) - 10 }
              }

              // WEB O TARJETA 
              if( this.isWeb || this.isTarjeta){
                this.total_pagado = parseFloat( preMonto ) 
              }

              // ES TRANFERENCIA SI NO ES DE NINGUNA DE ESTAS OPCIONES
              if( !this.isSeven && !this.isOXXO && !this.isWeb && !this.isTarjeta && !this.isSpin){
                this.isTransferencia = true

                // VALIDAR SI LA TRANSFERENCIA ES POR MEDIO DE BANCOMER
                if( isBBVA ){
                  // separamos
                  let primerosDigitos = preMonto.substr( 0, (preMonto.length - 2) )
                  let ultimosDigitos  = preMonto.substr( (preMonto.length - 2), (preMonto.length - 1) )

                  // Agregamos el punto y obtenemos el monto
                  this.total_pagado = parseFloat( primerosDigitos + '.' + ultimosDigitos)
                  this.cargar = false
                  return
                }else{
                  preMonto = parseFloat( preMonto )
                  // SI NO, EL MONTO SE GUARDA DIRECTO
                  if( preMonto > this.total_pagado )
                    this.total_pagado = preMonto

                }
              }
            }

            if( this.isTarjeta){
              if(palabras[i]['LineText'].indexOf("IMPORTE") >= 0){

                if( palabras[ parseInt(i) + 1 ]['LineText'].indexOf(" ") ){
                  const position = palabras[ parseInt(i) + 1 ]['LineText'].indexOf(" ")
                  const longitud = palabras[ parseInt(i) + 1 ]['LineText'].length
                  let preMonto = palabras[ parseInt(i) + 1 ]['LineText'].substr(position, longitud).replace(/[^0-9.]/g, '').trim()
                  if( parseFloat( preMonto ) ){
                    // Como hay varios MXN hay que validar que el más alto es el que se tranfirio
                    this.total_pagado = parseFloat( preMonto )
                    this.cargar = false
                    return
                  }
                }else{
                  let preMonto = palabras[ parseInt(i) + 1 ]['LineText'].replace(/[^0-9.]/g, '').trim()
                  if( parseFloat( preMonto ) ){
                    // Como hay varios MXN hay que validar que el más alto es el que se tranfirio
                    this.total_pagado = parseFloat( preMonto )
                    this.cargar = false
                    return
                  }
                }

              }
            }

            if( palabras[i]['LineText'].indexOf("MN") >= 0 && !this.isPracticaja && !this.isDirectoBANREGIO || palabras[i]['LineText'].indexOf("MXN") >= 0 && !this.isPracticaja && !this.isDirectoBANREGIO){
              this.isTransferencia = true

              let preMonto = 0

              if(palabras[i]['LineText'].indexOf("$") >= 0){
                const preMontoSplit = palabras[i]['LineText'].split('$')
                preMonto            = preMontoSplit[ preMontoSplit.length - 1 ]
              }else{
                // Reemplazamos los signos y hacemos un trim
                preMonto = palabras[i]['LineText'].replace(/[$,]/g,'').trim()
              }

              preMonto = preMonto.replace(/[^0-9.]/g, '').trim()
              preMonto = parseFloat( preMonto )

              if( preMonto > this.total_pagado )
                this.total_pagado = preMonto

              this.cargar = false
              return
            }

            // Tranferencia por santander
            if( this.isSantander ){
              this.isTransferencia = true
              if( palabras[i]['LineText'].indexOf("MXN") >= 0 ){
                let preMonto = palabras[i]['LineText']
                // Eliminar el texto y solo dejar numeros y el punto
                preMonto = preMonto.replace(/[^0-9.]/g, '').trim()
                // PArseamos el preMonto
                preMonto = parseFloat( preMonto )
                // Como hay varios MXN hay que validar que el más alto es el que se tranfirio
                if( preMonto > this.total_pagado )
                  this.total_pagado = preMonto

              }
            }

            // ES PRACTICAJA
            if( this.isPracticaja ){

              if( palabras[i]['LineText'].indexOf("$") >= 0){
                let preMonto = palabras[i]['LineText'].replace(/[$,MNX]/g,'').trim()
                preMonto = preMonto.replace(/[^0-9.]/g, '').trim()
                preMonto = parseFloat( preMonto )

                this.total_pagado  = preMonto
                this.cargar = false
                return
              }

              if( palabras[i]['LineText'].indexOf("IMPORTE") >= 0 ){
                // Parsear el texto
                let preMonto = parseFloat(palabras[ parseInt(i) + 1 ]['LineText'].replace(/[$,]/g,''))

                // Validar si es un numero
                if( preMonto ){ 

                  this.total_pagado = preMonto 
                  this.cargar = false
                  return
                }else{ // en caso de que no...

                  // Guardar el siguiente dato
                  preMonto = parseFloat(palabras[ parseInt(i) - 1 ]['LineText'].replace(/[$,]/g,''))

                  if( preMonto ){
                    this.total_pagado = preMonto 
                    this.cargar = false
                    return
                  }else{ // en caso de que no...
                    // Guardar el siguiente dato
                    preMonto = parseFloat(palabras[ parseInt(i) + 2 ]['LineText'].replace(/[$,]/g,''))

                    // Validar si es un numero
                    if( preMonto ){ // y lo guardamos
                      this.total_pagado = preMonto 
                      this.cargar = false
                      return
                    }else{

                      // Guardar el siguiente dato
                      preMonto = parseFloat(palabras[ parseInt(i) + 3 ]['LineText'].replace(/[$,]/g,''))

                      // Validar si es un numero
                      if( preMonto ){ // y lo guardamos
                        this.total_pagado = preMonto 
                        this.cargar = false
                        return
                      }
                    }
                  }
                }
              }
            }

            if( !this.isSeven && !this.isOXXO && !this.isWeb && !this.isTarjeta && !this.isSantander && !this.isPracticaja && !this.isDirectoBancoBBVA && !this.isDirectoBANREGIO){
              // Validamos si es tranferencia por spi
              if( this.isSpin ){
                this.banco = 'spin'
                if( palabras[i]['LineText'].indexOf("Monto") >= 0 ){

                  let preMonto = palabras[ parseInt(i) + 1 ]['LineText'].replace(/[^0-9.]/g, '').trim()

                  if( parseFloat( preMonto ) ){
                    // Como hay varios MXN hay que validar que el más alto es el que se tranfirio
                    this.total_pagado = parseFloat( preMonto )
                    this.cargar = false
                    return
                  }
                }
              }else{
                if( palabras[i]['LineText'].indexOf("MXN") >= 0){
                  if( parseFloat( palabras[ parseInt(i) - 1 ]['LineText'] ) ){
                    let preMonto = palabras[ parseInt(i) - 1 ]['LineText']
                    // Eliminar el texto y solo dejar numeros y el punto
                    preMonto = preMonto.replace(/[^0-9.]/g, '').trim()
                    // PArseamos el preMonto
                    preMonto = parseFloat( preMonto )
                    // Como hay varios MXN hay que validar que el más alto es el que se tranfirio
                    this.total_pagado = preMonto
                    this.cargar = false
                    return
                  }

                  let preMonto = palabras[i]['LineText']
                  // Eliminar el texto y solo dejar numeros y el punto
                  preMonto = preMonto.replace(/[^0-9.]/g, '').trim()
                  // PArseamos el preMonto
                  preMonto = parseFloat( preMonto )
                  // Como hay varios MXN hay que validar que el más alto es el que se tranfirio
                  if( preMonto > this.total_pagado )
                    this.total_pagado = preMonto
                }else{
                  if( palabras[i]['LineText'].indexOf("MN") >= 0){
                    let preMonto = palabras[i]['LineText']
                    // Eliminar el texto y solo dejar numeros y el punto
                    preMonto = preMonto.replace(/[^0-9.]/g, '').trim()
                    // PArseamos el preMonto
                    preMonto = parseFloat( preMonto )
                    // Como hay varios MXN hay que validar que el más alto es el que se tranfirio
                    if( preMonto > this.total_pagado )
                      this.total_pagado = preMonto
                  }
                }
              }
            }

            if( this.isDirectoBancoBBVA ){

              if( palabras[i]['LineText'].indexOf("MXP") >= 0 ){

                let preMonto = palabras[ parseInt(i) + 2 ]['LineText']
                preMonto = preMonto.replace(/[^0-9]/g, '').trim()

                let primerosDigitos = preMonto.substr( 0, (preMonto.length - 2) )
                let ultimosDigitos  = preMonto.substr( (preMonto.length - 2), (preMonto.length - 1) )

                if( parseFloat(primerosDigitos + '.' + ultimosDigitos) )
                  this.total_pagado = parseFloat(primerosDigitos + '.' + ultimosDigitos)


                this.cargar = false
                return
              }else if( palabras[i]['LineText'].indexOf("$") >= 0 ){

                let preMonto = palabras[i]['LineText']
                preMonto = preMonto.replace(/[^0-9]/g, '').trim()

                let primerosDigitos = preMonto.substr( 0, (preMonto.length - 2) )
                let ultimosDigitos  = preMonto.substr( (preMonto.length - 2), (preMonto.length - 1) )

                if( parseFloat(primerosDigitos + '.' + ultimosDigitos) )
                  this.total_pagado = parseFloat(primerosDigitos + '.' + ultimosDigitos)

                this.cargar = false
                return
              }else{
                let preMonto = palabras[i]['LineText']
                preMonto = preMonto.replace(/[,]/g,'').trim()
                if( parseFloat(preMonto) )
                  this.total_pagado = parseFloat(preMonto)
              }

            }

            if( this.isDirectoBANREGIO ){
              const position = palabras[i]['LineText'].indexOf("DEPOSITO")
              if( position >= 0 ){
                let preMonto = palabras[ parseInt(i) + 1 ]['LineText']
                preMonto = preMonto.replace(/[,]/g,'').trim()
                if( parseFloat(preMonto) )
                  if( preMonto > this.total_pagado )
                    this.total_pagado = parseFloat(preMonto)
              }
            }

            if( !this.total_pagado && !this.isPracticaja && !this.isTransferencia && !this.isSeven && !this.isOXXO && !this.isWeb && !this.isTarjeta && !this.isDirectoBancoBBVA && !this.isDirectoBANREGIO){
              if( palabras[i]['LineText'].indexOf("Monto") >= 0 ){
                let preMonto = palabras[ parseInt(i) + 1 ]['LineText'].replace(/[^0-9.]/g, '').trim()
                if( parseFloat( preMonto ) ){
                  // Como hay varios MXN hay que validar que el más alto es el que se tranfirio
                  this.total_pagado = parseFloat( preMonto )
                  this.cargar = false
                  return
                }
              }
            } 

          }

          if( this.isOXXO ){
            const wordPagoTotal = palabras.find( el=> el.LineText.match('PAGO TOTAL') ||  el.LineText.match('PASO TOTAL'))
            const positionPago  = palabras.indexOf( wordPagoTotal )

            let wordDeposito  = palabras.find( el=> el.LineText.match('DEPOSITO'))
            if( !wordDeposito ){ wordDeposito  = palabras.find( el=> el.LineText.match('AUTORIZACION')) }
            const positionDepo  = palabras.indexOf( wordDeposito )

            const newArrayPalabras = palabras.slice( positionPago, positionDepo ).reverse()

            for( const i in newArrayPalabras){
              let newPalabra = parseFloat(newArrayPalabras[i]['LineText'].replace(/[^0-9]/g, '').trim())

              if( newPalabra ){
                newPalabra = newPalabra.toString()
                let primerosDigitos = newPalabra.substr( 0, (newPalabra.length - 2) )
                let ultimosDigitos  = newPalabra.substr( (newPalabra.length - 2), (newPalabra.length - 1) )
                if( parseFloat(primerosDigitos + '.' + ultimosDigitos) ){
                  this.total_pagado = parseFloat(primerosDigitos + '.' + ultimosDigitos) - 12
                  this.cargar = false
                  return
                }
              }
            }
          }

          console.log( this.total_pagado)


          this.cargar = false
        }
      }).catch( error => { 
        // this.removeFile()
        this.cargar = false
        this.validarErrorDirecto('La imagen no se pudo leer, favor de comunicarse con sistemas')
      })
    },

    

    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      
      if (!files.length) {
        this.dragging = false;
        return;
      }
      
      this.createFile(files[0]);
    },

    createFile(file) {
      if (!file.type.match('image.*')) {
        alert('please select txt file');
        this.dragging = false;
        return;
      }
      
      if (file.size > 10000000) {
        alert('please check file size no over 5 MB.')
        this.dragging = false;
        return;
      }
      
      this.file = file;
      this.archivos = file;
      this.getBase64(this.archivos)
      this.procesarImagen2( )
      this.dragging = false;
    },

    removeFile() {
      this.total_pagado = 0
      this.isPracticaja       = false
      this.isTransferencia    = false
      this.isSeven            = false
      this.isOXXO             = false
      this.isWeb              = false
      this.isTarjeta          = false
      this.isDirectoBancoBBVA = false
      this.isDirectoBANREGIO  = false
      this.vistaPrevia        = null
      this.archivos           = null
      this.file               = '';
      this.banco              = ''
      this.isSpin             = false
    },

    buscarBanco( val, palabras ){
      const buscarBancoAzteca = val.match('Banco Azteca')
      this.banco = buscarBancoAzteca ? 'Banco Azteca' : ''
      if( buscarBancoAzteca ){ return }

      const buscarBancoCitiBanamex = val.match('citibanamex')
      this.banco = buscarBancoCitiBanamex ? 'citibanamex' : ''
      if( buscarBancoCitiBanamex ){ return }

      const wordSanta  = palabras.filter( el => { return el.LineText == 'Santander' })
      for( const i in wordSanta ){
        const position = palabras.indexOf(wordSanta[i])
        if( position == ( palabras.length - 1 )){ this.banco = 'Santander' ; return  }
      }

    },

  }
}