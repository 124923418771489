<template>
  <v-container class="mt-4 px-5" fluid>

	  <v-row justify="center">
	    <v-col cols="12">
	    	<v-card class="shadowCard">
	    	  <v-card-title primary-title>
	    	    Seguimiento de certificación
	    	    <v-spacer></v-spacer>
	    	    
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( alumnos  , 'SEGUIMIENTO_CODIGO')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getCodigos()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	    	  </v-card-title>
	        <v-col cols="12">
            <v-card class="elevation-0">

            	<!-- Buscador  -->
          		<v-card-text>
          			<v-row justify="end">
          				<v-col cols="12" md="6" lg="4">
		          		  <v-text-field
		          		    label="Buscar"
		          		    filled
		          		    dense
		          		    v-model="search"
		          		    hide-details
		          		  ></v-text-field>
          				</v-col>
          			</v-row>
          		</v-card-text>
              <v-data-table
                dense
                :headers="tableHeader"
                :items="alumnos"
                item-key="name"
						    @page-count="pageCount = $event"
						    :search="search"
              >

              	<template v-slot:item.status="props">
						      <v-edit-dialog
						        :return-value.sync="props.item.status"
						        @save="save(props.item)"
						        @cancel="cancel"
						        @open="open"
						        @close="close"
						      >
						   			<span v-if="!props.item.status"> Clic para agregar un status </span>
						        {{ props.item.status }}
						        <template v-slot:input>
						        	<v-select
			                  outlined
			                  dense
			                  clearable
			                  v-model="props.item.status"
			                  :items="['Completado']"
			                  label="Selecciona el alumno"
			                  persistent-hint
			                  class="mt-4"
			                >
			                </v-select>
						        </template>
						      </v-edit-dialog>
						    </template>

              	<template v-slot:item.nivel="props">
						      <v-edit-dialog
						        :return-value.sync="props.item.nivel"
						        @save="save(props.item)"
						        @cancel="cancel"
						        @open="open"
						        @close="close"
						      >
						   			<span v-if="!props.item.nivel"> Clic para agregar un nivel </span>
						        {{ props.item.nivel }}
						        <template v-slot:input>
			                <v-autocomplete
			                  outlined
			                  dense
			                  clearable
			                  v-model="props.item.nivel"
			                  :items="['A1','A2','B1','B2','C1','C2']"
			                  label="Selecciona el alumno"
			                  persistent-hint
			                  item-text="nombre_completo"
			                  item-value="nombre_completo"
			                  class="mt-4"
			                >
			                </v-autocomplete>
						        </template>
						      </v-edit-dialog>
								</template>

						    <template v-slot:item.escuela="{ item }">
		              <v-chip small v-if="item.escuela==1" color="#2EDAD5"> INBI </v-chip>
            			<v-chip small v-else color="#000D4A" dark> FAST </v-chip>
		            </template>

						    <template v-slot:item.estatus="{ item }">
		              <v-chip v-if="item.estatus == 0 "  small color="red"                   dark> En proceso      </v-chip>
		              <v-chip v-if="item.estatus == 1 "  small color="pink accent-4"         dark> Nombre completo </v-chip>
		              <v-chip v-if="item.estatus == 2 "  small color="deep-purple darken-3"  dark> Generado        </v-chip>
		              <v-chip v-if="item.estatus == 3 "  small color="blue darken-3"         dark> Generado        </v-chip>
		              <v-chip v-if="item.estatus == 4 "  small color="cyan darken-3"         dark> Impreso         </v-chip>
		              <v-chip v-if="item.estatus == 5 "  small color="teal accent-3"         dark> Eniviado        </v-chip>
		              <v-chip v-if="item.estatus == 6 "  small color="orange darken-3"       dark> Recibido        </v-chip>
		              <v-chip v-if="item.estatus == 7 "  small color="green"                 dark> Entregado       </v-chip>
		            </template>

		            <template v-slot:item.actions="{ item }">
		              <v-chip v-if="item.estatus == 1 "  small color="deep-purple darken-3"  class="elevation-12"  dark @click="crear( item,3)">      Generar  </v-chip>
		              <v-chip v-if="item.estatus == 3 "  small color="cyan darken-3"         class="elevation-12"  dark @click="actualizar( item, 4)"> Impreso  </v-chip>
		              <v-chip v-if="item.estatus == 4 "  small color="teal accent-3"         class="elevation-12"  dark @click="actualizar( item, 5)"> Enviado  </v-chip>
		              <v-chip v-if="item.estatus == 7 "  small color="green"                 class="elevation-12"  dark @click="ver( item, 4)">        VER      </v-chip>
		            </template>

		            <template v-slot:item.descargar="{ item }">
		              <v-chip v-if="item.estatus >= 3 "  small color="deep-purple darken-3"  class="elevation-12"  dark @click="descargar( item )"><v-icon>mdi-download</v-icon> </v-chip>
		            </template>
		            

              </v-data-table>
            </v-card>
	        </v-col>
	    	</v-card>
	    </v-col>
	  </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
     
	</v-container>
</template>

<script>
import axios from 'axios';
	import UploadExcelComponent from '@/components/UploadExcel.vue'

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
      UploadExcelComponent
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

	  alumnos:[],
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      search:'',

      conceptos:[{ idconcepto:1 , nombre: 'PRESUPUESTO'}, {idconcepto:2, nombre:'PAGO'}, {idconcepto:3, nombre:'REPORTE'}],
      concepto:{},

      municipio:{},

      tableHeader: [ 
		{ text:'ID'         		, value:'idcertificacion'},
		{ text:'Codigo'     		, value:'codigo'},
		{ text:'Fecha de solicitud' , value:'fecha_creacion'},
        { text:'Id alumno'  		, value:'id_alumno'},
        { text:'Nombre'     		, value:'nombre'},
        { text:'Plantel'    		, value:'plantel'},
        { text:'Nivel'      		, value:'nivel'},
        { text:'Escuela'    		, value:'escuela'},
        { text:'Estatus'    		, value:'estatus'},
        { text:'Pasar a:'   		, value:'actions'},
        { text:'Descargar'  		, value:'descargar'},
      ],   

      tableData: [],  // DATOS A MOSTRAR 
      reportes : [],  // TABLA REPORTES

      alerta: { activo: false, texto:'', color:'error'},
      cargar: false,
      
      codigos:[],
      alumno: null,
      dialogSuccess:false,
      cargar: false,
      dialogError: 	false,
			error: 				'',
			successMensaje:'',
			errorBack:false,
      textoCopiado:'COPIAR',

      dialogInfo: false,
      foto_download:'',
	  }),

	  async created(){
	  	this.foto_download = axios.defaults.baseURL + 'imagenes-cambridge/'
	  	await this.getCodigos()
	  },

	  computed:{
	    ...mapGetters('login',['getdatosUsuario']),
	    tamanioPantalla () {
	      return this.$vuetify.breakpoint.height -380
	    },
	  },

	  methods: {

	  	getCodigos () {
	  		this.cargar = true
	  		this.alumnos = []
	  		return this.$http.get('academico.alumnos.codigos').then( response =>{
	    		this.alumnos = response.data
				console.log(this.alumnos[0])
	    		this.cargar = false
	      }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
	  	},

	    validarCodigos () {
	    	this.tableData = []
	    	let payload = { codigos: this.codigos }
	    	this.$http.post('academico.getcodigos', payload).then( response =>{
	    		this.tableData = response.data
	      }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
	    },

	    async save (item) {
	      const { idcertificacion, nivel, escuela, status } = item
	      await this.updateCodigo( idcertificacion, nivel, escuela, status )

	      this.validarSuccess('Datos grabados correctamente')
	    },

	    updateCodigo ( idcertificacion, nivel, escuela, status ) {
	      let payload = {
	      	idcertificacion,
	        nivel,
	        escuela,
	        status
	      }
	      return this.$http.put(`academico.codigo.nivel`, payload).then(response=>{
	        this.validarSuccess(response.data.mensaje)
	      }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
	    },

	    cancel () {
	      this.snack = true
	      this.snackColor = 'error'
	      this.snackText = 'Canceled'
	    },

	    open () {
	      this.snack = true
	      this.snackColor = 'info'
	      this.snackText = 'Dialog opened'
	    },
	    
	    close () {
	      console.log('Dialog closed')
	    },

	    actualizar (alumno, estatus) {
        this.cargar = true
        alumno.estatus = estatus

        this.$http.post('academico.update.certificado.cabridge/', alumno).then(response=>{
          this.cargar =       false
          this.dialogSuccess = true
          this.getCodigos()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

	    ver(item){
        this.alumno = item
        this.dialogVer = true
      },

      crear( item, estatus ){
        this.alumno  = item
        this.cargar = true

        this.$http.post('academico.crear.certificado.cabridge', this.alumno).then(response=>{
          this.cargar       = false
          this.dialogSuccess = true
          this.getCodigos()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

	    s2ab(s) {
	      var buf = new ArrayBuffer(s.length);
	      var view = new Uint8Array(buf);
	      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
	      return buf;
	    },

	    exportar(){
	      let data = XLSX.utils.json_to_sheet(this.grupoRolesPerfecto)
	      const workbook = XLSX.utils.book_new()
	      const filename = 'rolesPerfecto'
	      XLSX.utils.book_append_sheet(workbook, data, filename)

	      var wbout = XLSX.write(workbook, {
	        bookType: 'xls',
	        bookSST: false,
	        type: 'binary'
	      });

	      const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
	      
	      let formData = new FormData();
	      // //se crea el objeto y se le agrega como un apendice el archivo 
	      formData.append('file',file);
	      // /*getDatosRiesgo the form data*/

	      this.$http.post('pdfs',formData).then(response=> {
	        window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
	      }).catch(error=> {
	        console.log(error);
	      });
	    },

	    informacion(){
	    	this.dialogInfo = true
	    },

	    copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      },

      descargar ( item ){
      	const escuela = item.escuela == 1 ? '-INBI.jpg' : '-FAST.jpg'
        window.open( this.foto_download + item.idcertificacion + escuela)
      },
	  }
	}
</script>