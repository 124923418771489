<template>
  <v-container fluid class="mt-5 pl-6 pr-6">
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-body-1">Kpi General</span>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()"
              tile
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn 
              color="primary" 
              @click="consultar()" 
              small
              tile
            >
              Consultar
            </v-btn>
          </v-card-title>

          <v-card-text>

            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  v-model="cicloSup"
                  :items="ciclos"
                  label="Selecciona siguiente ciclo"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                  readonly
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="resultado.riPlantelesInbi"
              sort-by="calories"
              class="elevation-0 mt-4"
              hide-default-footer
              :items-per-page="20"
              dense
              mobile-breakpoint="200"
              id="kpi"
            >
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  small
                >
                  Actualizar
                </v-btn>
              </template>

          

              <template v-slot:item.reinscribibles="{ item }" align="center" class="text-center">
                <v-chip  small >{{ item.reinscribibles }}</v-chip>
              </template>

              <template v-slot:item.totalAlumnosSiguientes="{ item }" align="center" class="text-center">
                <v-chip  small >{{ item.totalAlumnosSiguientes }}</v-chip>
              </template>

              <template v-slot:item.porcentajeAvance="{ item }" align="center" class="text-center">
                <v-chip  small >{{ (( item.totalAlumnosSiguientes / item.reinscribibles   ) * 100 ).toFixed( 2 )  }}</v-chip>
              </template>

              <template v-slot:item.meta_alumnos="{ item }" align="center" class="text-center">
                <v-chip  small >{{ ( item.reinscribibles * .90 ).toFixed( 0 ) }} Alumnos</v-chip>
              </template>

            </v-data-table>

            <v-data-table
              :headers="headers"
              :items="resultado.riPlantelesFast"
              sort-by="calories"
              class="elevation-0 mt-4"
              hide-default-footer
              :items-per-page="20"
              dense
              mobile-breakpoint="200"
              id="kpi2"
            >

              <template v-slot:item.reinscribibles="{ item }" align="center" class="text-center">
                <v-chip  small >{{ item.reinscribibles }}</v-chip>
              </template>

              <template v-slot:item.totalAlumnosSiguientes="{ item }" align="center" class="text-center">
                <v-chip  small >{{ item.totalAlumnosSiguientes }}</v-chip>
              </template>

              <template v-slot:item.porcentajeAvance="{ item }" align="center" class="text-center">
                <v-chip  small >{{ (( item.totalAlumnosSiguientes / item.reinscribibles   ) * 100 ).toFixed( 2 )  }}</v-chip>
              </template>

              <template v-slot:item.meta_alumnos="{ item }" align="center" class="text-center">
                <v-chip  small >{{ ( item.reinscribibles * .90 ).toFixed( 0 ) }} Alumnos</v-chip>
              </template>

              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                  small
                >
                  Actualizar
                </v-btn>
              </template>
            </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'

  import XLSX from 'xlsx'
  // Componentes
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';


  export default {
    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      snackbar:false,
      headers: [
        { text: 'Plantel'          , value: 'plantel' },
        { text: 'A. Ciclo Actual'  , value: 'totalAlumnosActuales' },
        { text: 'Reinscribibles'   , value: 'reinscribibles' },
        { text: 'Reinscritos'      , value: 'totalAlumnosSiguientes' },
        { text: 'META'             , value: 'meta_alumnos' },
        { text: '%Avance'          , value: 'porcentajeAvance' },
      ],

      datosMeta: null,
      dialogMeta:false,
      desserts: [],
      ciclos:[],

      ciclo:null,
      cicloSup:null,
      cicloInf: null,

      planteles:[],
      plantel:null,
      cicloAnterior:[],
      cicloSiguiente:[],
      resultado:[],
      total:0,
      faltantes:0,
      siguientes:0,
      porcentaje:0,
      idcicloERPinf:0,
      idcicloERPSup:0,
      cicloSiguienteAvance:[],
      resultadoFinal:[],

      headersNiSucursal: [
        { text: 'Sucursal'     , value: 'plantel' },
        { text: 'Acumulado'    , value: 'acumulado' },
        { text: 'Avance'       , value: 'avance' },
      ],
      fast:{},
      inbi:{},
			totalFast: 0,
			dataTablaFastUno:[],
			totalInbi: 0,
			dataTablaInbiUno:[],
			dialogCarga: false,

			headersVend: [
        { text: 'Vendedora'    , value: 'nombre_completo' },
        { text: 'Acumulado'       , value: 'total' },
        { text: 'Avance'        , value: 'avance' },
      ],

      dataTablaFastUnoVend:[],
			dataTablaInbiUnoVend:[],
			avance:0,
			acumulado:0,
			avanceFinal:0,
			acumuladoFinal:0,
      alumnos:[],
      dialogIrregulares: false,
      dialogBecados:false,
      dialogAlumnos:false
    }),

    watch: {
      ciclo () {
        if(this.ciclo){
          // Buscar la pisición en la que se encuentra el ciclo
          const positionCiclo = this.ciclos.indexOf(this.ciclo)

          // Validar que sea uno valido
          if(positionCiclo == 0 ){
            return this.validarErrorDirecto('El ciclo anterior esta cerrado, favor de abrir el ciclo anterior')
          }

          // Guardar un ciclo después
          this.cicloSup = this.ciclos[ positionCiclo + 1 ]

          // Guardar un ciclo anteriro
          this.cicloInf = this.ciclos[ positionCiclo - 1 ]

          // Consultar la información
          this.consultar()
        }
      }
    },

    computed: {

      ...mapGetters( 'login' , ['getdatosUsuario'] ),

    },

    async created () {
      this.cargar = true
      await this.getCiclosActivos( )
      await this.initialize()
      this.cargar = false
    },

    methods: {
      getCiclosActivos( ){
        this.$http.get('kpi.ciclos.all').then(response=>{
          this.ciclos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      initialize () {
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.planteles.all').then(response=>{
          for(const i in response.data){
            var payload = {
              plantel: response.data[i].plantel,
              alumnosActual: 0,
              alumnosSiguiente: 0,
              faltan: 0,
              porcentaje: 0,
            }
            this.planteles.push(payload)
          }
          
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async consultar () {
        this.resultado = []
        this.total = 0
        this.faltantes = 0
        this.siguientes = 0
        this.porcentaje = 0
        this.totalFinal      = 0
        this.faltantesFinal  = 0
        this.siguientesFinal = 0
        this.porcentajeFinal = 0

        for(const i in this.planteles){
          this.planteles[i].plantel =  this.planteles[i].plantel
          this.planteles[i].alumnosActual =  0
          this.planteles[i].alumnosSiguiente = 0
          this.planteles[i].faltan = 0
          this.planteles[i].porcentaje = 0
          this.planteles[i].avance = 0
          this.planteles[i].acumulado = 0
        }


        this.resultado       = []
        this.resultadoFinal  = []
        this.total           = 0
        this.faltantes       = 0
        this.siguientes      = 0
        this.porcentaje      = 0
        this.acumulado       = 0
        this.avance          = 0

        await this.riFast()
        await this.niSucursal()
        await this.niVend()
        
      },

      riFast () {
        this.cargar = true
        const payload = {
          cicloPre:  this.cicloInf,
          cicloAct: this.ciclo,
          cicloSig:  this.cicloSup
        }
        return this.$http.post('kpi.cantactual', payload ).then(response=>{
          this.resultado = response.data

          let planteles = []

	      	if( [1336].includes( this.getdatosUsuario.iderp ) ){
	      	  planteles = [2,3,22,23]
	      	}

	      	if( [1077].includes( this.getdatosUsuario.iderp ) ){
	      	  planteles = [24]
	      	}

	      	if( [1017].includes( this.getdatosUsuario.iderp ) ){
	      	  planteles = [16,17]
	      	}

	      	if( [334].includes( this.getdatosUsuario.iderp ) ){
	      	  planteles = [1]
	      	}

          if( [886].includes( this.getdatosUsuario.iderp ) ){
            planteles = [14, 10, 12, 8]
          }

          this.resultado.riPlantelesInbi = this.resultado.riPlantelesInbi.filter( el => planteles.includes( el.id_plantel ))
          this.resultado.riPlantelesFast = this.resultado.riPlantelesFast.filter( el => planteles.includes( el.id_plantel ))

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      niSucursal(){
        this.cargar = true
        this.fast = {}
        this.inbi = {}

        this.$http.post('kpi.inscripciones.porciclo', this.ciclo ).then(response => {
          this.fast = response.data.fast
          this.inbi = response.data.inbi
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      niVend () {
        this.dialogCarga = true
        this.fast = {}
        this.inbi = {}
        this.$http.get('kpi.ni.vendedora.ciclo.fast/' + this.cicloSup.id_ciclo_relacionado).then(response=>{
          this.dataTablaFastUnoVend = response.data
          var payload = {
            nombre_completo: 'Total',
            avance:0,
            total:0,
          }
          for(const i in this.dataTablaFastUnoVend.tabla){
            payload.avance += this.dataTablaFastUnoVend.tabla[i].avance
            payload.total += this.dataTablaFastUnoVend.tabla[i].total
          }
          this.dataTablaFastUnoVend.tabla.push(payload)
          this.$http.get('kpi.ni.vendedora.ciclo.inbi/' + this.cicloSup.id_ciclo).then(response=>{
            this.dataTablaInbiUnoVend = response.data
            var payload = {
              nombre_completo: 'Total',
              avance:0,
              total:0,
            }
            for(const i in this.dataTablaInbiUnoVend.tabla){
              payload.avance += this.dataTablaInbiUnoVend.tabla[i].avance
              payload.total += this.dataTablaInbiUnoVend.tabla[i].total
            }
            this.dataTablaInbiUnoVend.tabla.push(payload)
            this.dialogCarga = false
            return
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      verIrregulares( alumnos ){
        this.alumnos = alumnos
        this.dialogIrregulares = true
      },

      verBecados( alumnos ){
        this.alumnos = alumnos
        this.dialogBecados = true
      },

      verAlumnos( alumnos ){
        console.log( alumnos )
        this.alumnos       = alumnos
        this.dialogAlumnos = true
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      validarError ( value ) {
        this.cargar = false 
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.body.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },

      validarErrorDirecto ( mensaje ) {
        this.cargar = false 
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = '#FF2C2C'
        this.respuestaAlerta = true 
      },


      exportar(){
       
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
    //   	var vacio = [{
    //   		plantel:'',
    //   		acumulado:'',
    //   		avance:'',
    //   	}]
    //   	var dataFastSuc  = this.dataTablaFastUno.tabla.concat(vacio)
    //   	var dataFastSuc  = dataFastSuc.concat(this.dataTablaInbiUno.tabla)

    //   	var vacio2 = [{
    //   		nombre_completo:'',
    //   		avance:'',
    //   		total:'',
    //   	}]

    //   	var dataFastVend = this.dataTablaFastUnoVend.tabla.concat(vacio2)
    //   	var dataFastVend = dataFastVend.concat(this.dataTablaInbiUnoVend.tabla)

    //     let aSuc = []
    //     for(const i in dataFastSuc){
    //     	let payload = {
				// 		plantel  : dataFastSuc[i].plantel,
    //   			avance   :dataFastSuc[i].avance,
    //   			acumulado:dataFastSuc[i].acumulado,
    //     	}
    //     	aSuc.push(payload)
    //     }


				// let aVend = []
    //     for(const i in dataFastVend){
    //     	let payload = {
				// 		nombre_completo: dataFastVend[i].nombre_completo,
    //   			avance:dataFastVend[i].avance,
    //   			total:dataFastVend[i].total,
    //     	}
    //     	aVend.push(payload)
    //     }

    //     let data     = XLSX.utils.json_to_sheet(this.resultado)
    //     let dataSuc  = XLSX.utils.json_to_sheet(aSuc)
    //     let dataVend = XLSX.utils.json_to_sheet(aVend)
        let dataAnt = XLSX.utils.json_to_sheet(this.resultado.riPlantelesInbi)
        let dataACt = XLSX.utils.json_to_sheet(this.resultado.riPlantelesFast)



        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        // XLSX.utils.book_append_sheet(workbook, data, 'KPI')
        // XLSX.utils.book_append_sheet(workbook, dataSuc, 'NI sucursal')
        // XLSX.utils.book_append_sheet(workbook, dataVend, 'NI vendedora')
        XLSX.utils.book_append_sheet(workbook, dataAnt, 'KPI General INBI')
        XLSX.utils.book_append_sheet(workbook, dataACt, 'KPI General FAST')

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });
      },

      exportExcelData( alumnos, nameExcel ){
        let data = XLSX.utils.json_to_sheet(alumnos)

        const workbook = XLSX.utils.book_new()
        const filename = nameExcel
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });
      },

      
    },
  }
</script>
<style>

  

  #kpi td:nth-child(2) {
    background-color: #E8E8E8 !important;
  }

  #kpi td:nth-child(3) {
    background-color: #E8E8E8 !important;
  }

  #kpi td:nth-child(4) {
    background-color: #E8E8E8 !important;
  }

  #kpi td:nth-child(5) {
    background-color: #E8E8E8 !important;
  }


  #kpi td:nth-child(13) {
    background-color: #E8E8E8 !important;
  }

  #kpi td:nth-child(14) {
    background-color: #E8E8E8 !important;
  }

  #kpi td:nth-child(15) {
    background-color: #E8E8E8 !important;
  }

  #kpi td:nth-child(16) {
    background-color: #E8E8E8 !important;
  }



  #kpi2 td:nth-child(2) {
    background-color: #E8E8E8 !important;
  }

  #kpi2 td:nth-child(3) {
    background-color: #E8E8E8 !important;
  }

  #kpi2 td:nth-child(4) {
    background-color: #E8E8E8 !important;
  }

  #kpi2 td:nth-child(5) {
    background-color: #E8E8E8 !important;
  }

  #kpi2 td:nth-child(13) {
    background-color: #E8E8E8 !important;
  }

  #kpi2 td:nth-child(14) {
    background-color: #E8E8E8 !important;
  }

  #kpi2 td:nth-child(15) {
    background-color: #E8E8E8 !important;
  }

  #kpi2 td:nth-child(16) {
    background-color: #E8E8E8 !important;
  }



  #kpi3 td:nth-child(2) {
    background-color: #E8E8E8 !important;
  }

  #kpi3 td:nth-child(3) {
    background-color: #E8E8E8 !important;
  }

  #kpi3 td:nth-child(4) {
    background-color: #E8E8E8 !important;
  }

  #kpi3 td:nth-child(5) {
    background-color: #E8E8E8 !important;
  }

  #kpi3 td:nth-child(13) {
    background-color: #E8E8E8 !important;
  }

  #kpi3 td:nth-child(14) {
    background-color: #E8E8E8 !important;
  }

  #kpi3 td:nth-child(15) {
    background-color: #E8E8E8 !important;
  }

  #kpi3 td:nth-child(16) {
    background-color: #E8E8E8 !important;
  }


</style>