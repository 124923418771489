<template>
	<v-card class="elevation-0">

		<!-- TITULO -->
		<v-card-title primary-title class="pa-0 pb-4">
			<v-spacer></v-spacer>

			<v-btn 
				color="red" 
				dark 
				small 
				class="text-capitalize mr-2" 
				@click="$emit('nuevoalumno')"
				tile
			>
				<v-icon small left>mdi-account</v-icon>
				Nuevo alumno
			</v-btn>

			<v-btn 
				color="primary" 
				dark 
				small 
				class="text-capitalize" 
				@click="getAlumnos"
				tile
			>
				<v-icon small left>mdi-refresh</v-icon>
				Actualizar
			</v-btn>
		</v-card-title>

		<!-- CONTENIDO -->
  	<v-row justify="end">

  		<!-- BUSCADOR -->
  		<v-col cols="12" md="6">
		  	<v-text-field
		  		v-model="search"
		  	  label="Buscar"
		  	  filled
		  	  dense
		  	  single-line
		  	  append-icon="mdi-magnify"
		  	  hide-details
		  	></v-text-field>
		  </v-col>

		  <!-- TABLA DE ALUMNOS -->
		  <v-col cols="12">
		  	<v-data-table
			    :headers="headers"
			    :items="filterAlumnos"
			    class="elevation-0"
			    :mobile-breakpoint="100"
          dense
          :loading="cargar"
			  >
			  	<template v-slot:item.estatus="{ item }">
			    	<v-chip 
			    		color="yellow" 
			    		x-small
			    		v-if="item.idprospectos"
			    	>
			    		Prospecto
			    	</v-chip>
			    	<v-chip 
			    		color="green" 
			    		x-small
			    		dark
			    		v-else
			    	>
			    		Alumno
			    	</v-chip>
			    </template>

			    <template v-slot:item.actions="{ item }">
			    	<v-btn 
			    		color="primary" 
			    		x-small
			    		@click="seleccionarAlumno(item)"
			    		class="mr-2"
			    	>
				      <v-icon small>mdi-check</v-icon>
			    	</v-btn>
			    </template>

			    <template v-slot:no-data>
			      <v-btn
			        color="primary"
			        @click="getAlumnos()"
			        small
			      >
			        Actualizar
			      </v-btn>
			    </template>

			  </v-data-table>
  		</v-col>
  	</v-row>

  	<v-divider></v-divider>

    <v-card-actions class="pb-0">
      <v-btn
        @click="cancelar()"
        color="black"
        dark
        tile
        small
        class="text-capitalize"
      >
      	<v-icon left small>mdi-cancel</v-icon>
        Cancelar
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

  	props:[
			'inscribir'
	  ],

    data: () => ({
    	// Datos del alumno inscrito
    	alumnoNuevo: false,
    	alumnos:[],
    	id_alumno: null,

			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      formaspago: [],
      headers: [
        { text: 'ID'              , value: 'id_alumno'     },
        { text: 'Matricula'       , value: 'matricula'     },
        { text: 'Alumno'          , value: 'alumno'        },
        { text: 'Teléfono'        , value: 'telefono'      },
        { text: 'Celular'         , value: 'celular'       },
        { text: 'Tutor'           , value: 'tutor'         },
        { text: 'Estatus'         , value: 'estatus'       },
        { text: 'Actions'         , value: 'actions'     , sortable: false },
      ],
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),

    	filterAlumnos( ){
    		// Valdiar que este buscando algo 
    		if(this.search){
    			// filtar los alumnos
    			return this.alumnos.filter( el => {

    				// normalizar los datos completos del alumno
    				let datos_completos = el.datos_completos.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"")

    				// Normalizar el datos buscado
    				const buscar        = this.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"")

    				// Realizar el match
    				return datos_completos.match(buscar)
    			})
    		}else{ return this.alumnos }
    	}

    },

    async created () {
    	// Obtner el listado de los alumnos inscritossss, solo inscritos
      await this.getAlumnos()
    },


    methods: {
      getAlumnos( ) {
      	this.cargar = true
      	this.alumnos = []
        return this.$http.get('inscripciones.alumnos').then(response=>{
        	this.cargar = false
        	this.alumnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      seleccionarAlumno( value ){

      	let payload = {
      		grupo: value, 
      		hermanos: false
      	}
    		this.search = ''
    		this.$emit('alumnoSeleccionado', payload )
      },

      cancelar( ){
      	this.search = ''
      	this.$emit('cancelar')
      }
    },
  }
</script>