<template>
    <v-container>
        <v-dialog persistent v-model="isOpen" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="600px" @keydown.ctrl="pegar">
            <v-card>
                <v-card-title>
                <span class="headline">Folio: {{ ticket.idticket }}</span>
                <v-spacer></v-spacer>
                <v-btn fab small text @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-container class="py-0" v-for="(respuestas, i) in historial" :key="i" >
                        <v-row justify="space-between" v-if="i==0" >
                            <v-col cols="6" class="d-flex justify-start py-2 px-0">
                                <p class="ma-0"><strong>Motivo del ticket</strong></p>
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end py-2 px-0">
                                <p class="ma-0">{{ respuestas.fecha }}</p>
                            </v-col>
                        </v-row>
                        <mensajeIzq :respuestas="respuestas" :ticket="ticket"></mensajeIzq>
                        <mensajeDer :respuestas="respuestas" :ticket="ticket"></mensajeDer>
                    </v-container>
                    <br>
                    <!--RECHAZAR-->
                    <v-container v-if="rechazar && vista=='MisTickets'">
                        <p><strong>Rechazo de ticket</strong></p>
                        <v-textarea rows="3" auto-grow outlined v-model="respuestaRechazo" label="Motivo de rechazo" 
                            hide-details height="auto">
                        </v-textarea>
                        <v-file-input small-chips multiple v-model="files" 
                            accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
                            placeholder="Selecciona tus imagenes"
                            prepend-icon="mdi-camera" label="Imágenes"
                            @change="cargarFotos()">
                            </v-file-input>
                        <v-row justify="start">
                            <v-col cols="3" class="pa-1 my-5" v-for="(img, i) in vistaPrevias" :key="i">
                            <v-card class="py-4 shadowCard">
                                <v-img v-if="!img.video" :src="img.url" contain aspect-ratio="2"></v-img>
                                <video v-else :src="img.url" contain aspect-ratio="2" style="width: 100%; height: 100%;" class="d-flex"></video>
                                <v-btn color="error" x-small @click="eliminarFoto(img.url)"
                                top right absolute fab>
                                <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                
                <!--MIS TICKETS-->
                <v-divider v-if="ticket.estatus==5"></v-divider>
                <v-card-actions v-if="ticket.estatus==5" class="pb-4">
                    <v-btn color="dark" dark small @click="rechazar=true" v-if="!rechazar"> Rechazar </v-btn>
                    <v-btn color="dark" dark small @click="cancelarRechazo()" v-else> Cancelar </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success darken-1" small @click="dialogCerrarTicket = true" v-if="!rechazar"> Aceptar Respuesta </v-btn>
                    <v-btn v-else color="success darken-1" small @click="rechazarDefinitivo()"> Enviar Ticket </v-btn>
                </v-card-actions>
                <v-card-actions v-if="(ticket.estatus == 2 || ticket.estatus == 7)" class="pb-4">
                    <v-btn color="error" dark small  @click="dialogEliminarTicket = true" v-if="ticket.estatus == 2 && historial.length <= 1"> Eliminar </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="dark" dark small  @click="editarTicket()"> Editar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Confirmar cerrar ticket -->
        <v-dialog v-model="dialogCerrarTicket" max-width="300px">
        <v-card class="elevation-0 pb-4">
            <v-card-title>
            <span>Confirmacion</span>
            <v-spacer></v-spacer>
            <v-btn fab small text @click="dialogCerrarTicket = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
            ¿Su ticket quedo resuelto?
            </v-card-text>
            <v-card-actions>
            <v-btn color="error" small rounded @click="dialogCerrarTicket = false">
                Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" small rounded @click="cerrarTicket(1,6)">
                Confirmar
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- Confirmar eliminar ticket -->
        <v-dialog v-model="dialogEliminarTicket" max-width="300px">
        <v-card class="elevation-0 pb-4">
            <v-card-title>
            <span>Confirmacion</span>
            <v-spacer></v-spacer>
            <v-btn fab small text @click="dialogEliminarTicket = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
            ¿Desea eliminar este ticket? (Esta accion no se puede revertir)
            </v-card-text>
            <v-card-actions>
            <v-btn color="error" small rounded @click="dialogEliminarTicket = false">
                Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" small rounded @click="confirmarEliminarTicket()">
                Confirmar
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- Editar ticket -->
        <v-dialog v-model="dialogEditarTicket" max-width="600px">
        <v-card class="elevation-0 pb-4">
            <v-card-title>
            <span>Edicion de ticket</span>
            <v-spacer></v-spacer>
            <v-btn fab small text @click="dialogEditarTicket = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-textarea rows="3" auto-grow outlined v-model="respuestaRechazo" label="Motivo del ticket" 
                    hide-details height="auto">
                </v-textarea>
                <v-file-input small-chips multiple v-model="files" 
                    accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
                    placeholder="Selecciona tus imagenes"
                    prepend-icon="mdi-camera" label="Imágenes"
                    @change="cargarFotos()">
                </v-file-input>
                <v-row justify="start" v-if="lastHistorial">
                    <v-col cols="3" class="pa-1 my-5" v-for="(img, i) in lastHistorial.evidencias" :key="i">
                    <v-card class="py-4 shadowCard" @click="verImagen( img.foto, img.es_solucion )">
                        <v-img :src="(ticket.tipousuario ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : 
                          ticket.movil ? (ticket.id_unidad_negocio==1 ? urlINBI : urlFAST) : url) + img.foto"  contain aspect-ratio="2"></v-img>
                    </v-card>
                    </v-col>
                </v-row>
                <v-row justify="start">
                    <v-col cols="3" class="pa-1 my-5" v-for="(img, i) in vistaPrevias" :key="i">
                    <v-card class="py-4 shadowCard">
                        <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                        <v-btn color="error" x-small @click="eliminarFoto(img.url)"
                        top right absolute fab>
                        <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
            <v-btn color="error" small rounded @click="dialogEditarTicket = false">
                Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" small rounded @click="confirmarEditarTicket()">
                Confirmar
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- Visualizar imagenes -->
        <Imagen @close="closeImagen" :respuestaImagen="respuestaImagen" :isOpen="dialogImagen" :imagen="imagen" :ticket="ticket"/>

        <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
        <carga                v-if="cargar"/>
    </v-container>
</template>


<script>
    import axios from 'axios';
    import {mapGetters} from 'vuex'
    import validarErrores  from '@/mixins/validarErrores'
    import Imagen                from '@/components/tickets/Imagen.vue'
    import mensajeIzq            from '@/components/tickets/mensajeIzq.vue'
    import mensajeDer            from '@/components/tickets/mensajeDer.vue'
    import Alerta                from '@/components/alertas/Alerta.vue'
    import carga                 from '@/components/alertas/carga.vue'

    import { Clipboard } from "@capacitor/clipboard";

    export default {
        components:{
            Imagen,
            Alerta,
            carga,
            mensajeIzq,
            mensajeDer
        },
        mixins: [ validarErrores ],

        props: [
            'isOpen',
            'ticket',
            'historial',
            'vista'
        ],
        computed: {
        ...mapGetters('login',['getdatosUsuario']),
        },

        watch:{
            isOpen(){
                // si el dialogo historial se abre
                if(this.isOpen){
                    this.lastHistorial = this.historial[this.historial.length-1]
                }
            },
        },

        
        data: () => ({
            /*IMAGENES*/
            imagen : '',
            dialogImagen : false,
            respuestaImagen: false,
            vistaPrevias: [],
            files: [],

            /*MIS TICKETS*/
            rechazar: false,
            editar:false,
            respuestaRechazo : '',
            lastHistorial : '' , 
            dialogCerrarTicket: false, 
            dialogEliminarTicket: false, 
            dialogEditarTicket: false,    
        }),
        created () {
            this.url = axios.defaults.baseURL + 'evidencia-tickets/'
            this.urlINBI = 'https://lmsinbi.club/evidencia-tickets/'
            this.urlFAST = 'https://lmsfast.club/evidencia-tickets/'
        },

        methods:{
            /*MANEJO DE DIALOGOS*/
            verImagen( imagen, respuestaImagen ){
                this.imagen = imagen
                this.respuestaImagen = respuestaImagen
                this.dialogImagen = true
            },
            closeDialog(){
                if(this.rechazar) {this.cancelarRechazo()}
                this.$emit('close');        
            },
            closeImagen(){
                this.dialogImagen = false
            },

            /* EDITAR TICKETS */
            editarTicket(){
                this.dialogEditarTicket = true;
                this.vistaPrevias=[]
                this.respuestaRechazo = this.lastHistorial.motivo.slice()
            },
            confirmarEliminarTicket(){
                this.$http.delete('tickets.delete/'+this.ticket.idticket).then(response=>{
                    this.validarSuccess( response.data.message )
                    this.dialogEliminarTicket = false
                    this.$emit('recargar');
                    this.$emit('close')
                }).catch( error =>{
                    this.validarError( error.response.data.message )
                }).finally( () => { 
                    this.cargar = false
                })
            },
            cancelarEditarTicket(){
                this.dialogEditarTicket = false;
                this.vistaPrevias=[]
                this.respuestaRechazo = ''
            },
            async confirmarEditarTicket(){
                if(this.respuestaRechazo != ''){
                    this.cargar = true
                    let fotos = []
                    if(this.vistaPrevias.length){
                        let respuesta = await this.subirFotosServidor( )
                        fotos = respuesta.data
                    }
                    let payload = {
                        motivo : this.respuestaRechazo,
                        fotos
                    }
                    this.$http.put('historial_tickets.update.edit/'+this.lastHistorial.idhistorial_ticket, payload).then(response=>{
                        this.validarSuccess( response.data.message )
                        this.cancelarEditarTicket()
                        this.$emit('close')
                    }).catch( error =>{
                        this.validarError( error.response.data.message )
                    }).finally( () => { 
                        this.cargar = false
                    })
                } else {
                    this.validarErrorDirecto( 'Mensaje vacio' )
                }
            },

            /* CERRAR TICKETS */
            cerrarTicket (historialEstatus, ticketEstatus) {
                this.cargar=true
                //actualizar estatus de historial ticket
                this.$http.put('historial_tickets.update.estatus/'+this.lastHistorial.idhistorial_ticket, {estatus: historialEstatus}).then(response=>{
                }).catch( error =>{
                    this.validarError( error.response.data.message )
                }).finally( () => { })

                //actualizar estatus de ticket
                this.$http.put('tickets.update.estatus/'+this.ticket.idticket,{estatus: ticketEstatus}).then(response=>{
                    this.dialogCerrarTicket = false;
                    this.$emit('recargar');
                    this.closeDialog()
                }).catch( error =>{
                    this.validarError( error.response.data.message )
                }).finally( () => { 
                    this.cargar = false 

                })
            },

            /* RECHAZAR TICKETS */
            cancelarRechazo(){
                this.rechazar = false
                this.vistaPrevias = []
                this.files = null
            },
            async rechazarDefinitivo(){
                if(this.respuestaRechazo != ''){
                    this.cargar = true
                    let fotos = []
                    if(this.vistaPrevias.length){
                        let respuesta = await this.subirFotosServidor( )
                        fotos = respuesta.data
                    }
                    var historial = {
                        idticket: this.ticket.idticket,
                        resupesta: "",
                        motivo: this.respuestaRechazo,
                        respuestaauxi: "",
                        fotos,
                        idhistorial_ticket : this.lastHistorial.idhistorial_ticket
                    };

                    //actualizar estatus de historial ticket y ticket
                    this.cerrarTicket(1, 7)

                    //agregar historial
                    this.$http
                        .post("historial_tickets.add", historial)
                        .then((response) => {
                        this.validarSuccess( response.data.message )
                        this.respuestaRechazo = "";
                        this.vistaPrevias =[]
                        this.files=[]
                        this.plantel = { id_plantel: 0, plantel: "" };
                        this.$emit('recargar')
                        this.$emit('close')
                    }).catch( error =>{
                        this.validarError( error.response.data.message )
                    }).finally( () => { this.cargar = false })
                }
            },

            /*FUNCIONES DE CARGA DE IMAGENES*/
            cargarFotos(){
                if(this.files.length < 1){
                    return
                }

                this.files.forEach((element, index) => {
                // creamos una variable tipo FormData
                let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
                formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
                if(element.size > 26214400){ //26214400 = 25MB
                    this.validarErrorDirecto('El archivo sobrepasa el limite del peso, max 25 MB')
                } else {
                    this.getBase64(element, formData)
                }
                })
                
            },
            //eliminar la foto de las vistas previas
            eliminarFoto(value){
                this.vistaPrevias.forEach((element, index)=>{
                if(element.url == value){
                    this.vistaPrevias.splice(index,1);
                }
                })
            },
            //eliminar foto que ya esta en servidor
            eliminarFotoBDD(value){
                this.historial_ticket.evidenciasRespuesta.forEach((element, index)=>{
                if(element.foto == value.foto){
                    this.historial_ticket.evidenciasRespuesta.splice(index,1);
                }
                })
                //enviamos el nombre del archivo ya que al venir del auxiliar siempre esta en el mismo servidor
                this.$http.delete('tickets.eliminar.evidencia/' + value.foto).then(response=>{
                    console.log(response)
                }).catch( error =>{
                    this.validarError( error.response.data.message )
                }).finally( () => {  })

                this.$http.delete('historial_tickets.eliminar_evidencia/'+value.idevidencia_historial_tickets).then(response=>{
                    console.log(response)
                }).catch( error =>{
                this.validarError( error.response.data.message )
                }).finally( () => { this.cargar = false })
            },
            getBase64(file, formData) {
                var me = this
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                var video = false
                const nombreSplit = file.name.split('.')
                const extension = nombreSplit[nombreSplit.length-1]
                if(['mp4', 'mov', 'wmv', 'avi'].includes(extension)){
                    video = true
                }
                me.vistaPrevias.push({
                        url: reader.result, 
                        formData: formData, 
                        image_name: file.name,
                        video : video,
                        file
                    })
                };
            },
            subirFotosServidor( ){
                let formData = new FormData();

                for( const i in this.vistaPrevias ){
                formData.append('file', this.vistaPrevias[i].file )
                }

                return new Promise((resolve,reject)=>{
                // obtener la extensión del archivo
                // Hacemos la petición
                this.$http.post(`tickets.subir.evidencia`, formData ).then(response=>{
                    resolve( response )
                }).catch( error =>{
                    this.validarError( error.response.data.message )
                }).finally( () => { this.cargar = false })
                })
            },
            convertFile(dataurl, filename) {
                var arr = dataurl.split(","),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }

                return new File([u8arr], filename, { type: mime });
            },
            createFile(file) {
                if (file.size > 10000000) {
                    alert("please check file size no over 5 MB.");
                    this.dragging = false;
                    return;
                }

                this.file = file;
                this.archivos = file;
                this.getBase64(this.archivos);
                this.dragging = false;
            },
            async pegar(event) {
                if (event.code == "KeyV") {
                    const { type, value } = await Clipboard.read();

                    // Validamos que sea de tipo imagen
                    if (type == "image/png") {
                    // Convertimos el base64 en una imagen
                    const file = this.convertFile(value, "imagen.png");
                    this.createFile(file);
                    }
                }
            },
        }
    }
</script>