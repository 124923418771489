<template>
	<v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title>
            <span class="text-subtitle-1">Tickets por Area</span>
            <v-divider class="mx-2" inset vertical></v-divider>
              <v-radio-group v-model="escuela" row>
                <v-radio label="Todos" :value="0"></v-radio>
                <v-radio label="Fast" :value="2"></v-radio>
                <v-radio label="Inbi" :value="1"></v-radio>
              </v-radio-group>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-checkbox v-model="urgente" :label="`Urgente`"></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn class="mr-2" small dark color="green" @click="exportar()">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn color="primary" @click="obtenerTickets" small>
              Actualizar
            </v-btn>
          </v-card-title>
          <v-card-text>
            <!-- Filtros -->
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-radio-group v-model="estatusFiltro" row dense hide-details small>
                  <v-radio color="orange" label="Abierto"    :value="1"></v-radio>
                  <v-radio color="blue"   label="Asignado"   :value="2"></v-radio>
                  <v-radio color="red"    label="Pendiente"  :value="3"></v-radio>
                  <v-radio color="grey"   label="Revisión"   :value="4"></v-radio>
                  <v-radio color="green"  label="Respondido" :value="5"></v-radio>
                  <v-radio color="yellow" label="Cerrado"    :value="6"></v-radio>
                  <v-radio color="black"  label="Rechazado"  :value="7"></v-radio>
                  <v-radio label="Todos" :value="8"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <!-- Buscador -->
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  label="Buscar"
                  v-model="search"
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

  			  <v-data-table
  			    :headers="headers"
  			    :items="filterTickets"
            :item-class="itemRowBackground"
  			    sort-by="calories"
  			    class="elevation-0"
            :search="search"
            multi-sort
            denses   
  			  >

          	<template v-slot:item.estatus="{ item }">
  			      <v-chip small color="orange" dark v-if="item.estatus==1"> Abierto    </v-chip>
              <v-chip small color="blue"   dark v-if="item.estatus==2"> Asignado   </v-chip>
              <v-chip small color="red"    dark v-if="item.estatus==3"> Pendiente  </v-chip>
              <v-chip small color="grey"   dark v-if="item.estatus==4"> Revisión   </v-chip>
              <v-chip small color="green"  dark v-if="item.estatus==5"> Respondido </v-chip>
              <v-chip small color="yellow"      v-if="item.estatus==6"> Cerrado    </v-chip>
              <v-chip small color="black"  dark v-if="item.estatus==7"> Rechazado  </v-chip>
  			    </template>

            <template v-slot:item.fecha_apertura="{ item }">
              <span>{{ item.fecha_apertura_correccion }}</span>
            </template>

            <template v-slot:item.id_unidad_negocio="{ item }">
              <v-chip small v-if="item.id_unidad_negocio==1" color="#2EDAD5"> INBI </v-chip>
              <v-chip small v-else color="#000D4A" dark> FAST </v-chip>
            </template>

            <template v-slot:item.ver="{ item }">
              <!-- Ver respuesta del auxiliar -->
              <v-btn text @click="verRespuestaAuxi(item)" small v-if="item.estatus == 4 || item.estatus == 3 "> RESPONDER</v-btn>
              <!-- Responder directamente -->
              <v-btn text small @click="responder(item)"        v-if="item.estatus == 2 || item.estatus == 7 "> Responder</v-btn>
            </template>

            <template v-slot:item.historial="{ item }">
              <v-btn color="primary" small @click="historialTicket(item)" icon><v-icon>mdi-file-document</v-icon></v-btn>
            </template>

  			    <template v-slot:item.actions="{ item }">
  			      <v-btn color="success" small @click="verMotivo(item)" v-if="!item.nombre_auxiliar && item.estatus!=6 && item.estatus != 5">
                Asignar
              </v-btn>
  			    </template>

            <template v-slot:item.fecha_entrega="props">
              <v-edit-dialog :return-value.sync="props.item.fecha_entrega" @save="saveFecha( props.item )" large save-text="Guardar" cancel-text="Cancelar">
                <v-btn  color="orange" dark small  v-if="!props.item.fecha_entrega && props.item.estatus != 6" >
                  Agregar fecha
                </v-btn>
                
                <v-chip v-if="props.item.fecha_entrega"  small  dark :color="props.item.diferencia_dias < 0 && props.item.estatus != 6 ? 'red' : 'grey' ">
                  {{ props.item.fecha_entrega }}
                </v-chip>

                <template v-slot:input>
                  <v-text-field v-model="props.item.fecha_entrega" label="Fecha Creación" single-line type="date"></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

  			    <template v-slot:no-data>
  			      <v-btn color="primary" @click="initialize" small>
  			        Reset
  			      </v-btn>
  			    </template>

  			  </v-data-table>
        </v-card>
			</v-col>
		</v-row>
    <!-- Asignar a un auxiliar -->
		<v-dialog v-model="dialogAsignarAux" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}: {{ ticket.idticket }}</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogAsignarAux=false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container> 
            <v-row  class="mt-0">
              <v-col cols="8" class="pa-2">
                <p class="ma-0"><strong>Motivo de tu ticket</strong></p>
              </v-col>
            </v-row>
            <mensajeIzq :respuestas="firstHistorial" :ticket="ticket"></mensajeIzq>
            <br> <br>
            <v-row>
              <v-col cols="12" >
                <v-autocomplete
                	outlined
                	dense
                  :items="auxiliares"
                  v-model="auxiliar"
                  label="Selecciona un auxiliar"
                  return-object
                  item-value="idauxi_area"
                  item-text="nombre_completo"
                ></v-autocomplete>
            	</v-col>
            </v-row>
            
          </v-container>
          <v-card-actions class="pa-0">
            <v-btn color="blue"   dark small @click="cargarNotas()">Ver notas {{ mensajes.length }}</v-btn>
            <v-btn color="blue"   dark small @click="dialogHistorialReasignacion = true"><v-icon>mdi-file-document</v-icon> {{ historialAsignacion.length }}</v-btn>
            <v-btn color="orange" dark small @click="reasignarTicket()">Reasignar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success darken-1" dark small @click="save"> Guardar </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Reponder directamente un ticket sin asignar a un auxiliar -->
    <v-dialog v-model="dialogRespuesta" max-width="600px" @keydown.ctrl="pegar">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}: {{ ticket.idticket }}</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogRespuesta=false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text class="pb-0" v-for="(respuestas, i) in historial" :key="i">
          <v-container>
            <v-row justify="space-between">
              <v-col cols="4" class="pa-2">
                <p class="ma-0"><strong>Motivo del ticket</strong></p>
              </v-col>
            </v-row>
            <mensajeIzq :respuestas="respuestas" :ticket="ticket"></mensajeIzq>
            <mensajeDer :respuestas="respuestas" :ticket="ticket"></mensajeDer>
            <br v-if="respuestas.estatus==0">
            <v-divider v-if="respuestas.estatus==0"></v-divider>
            <v-textarea class="mt-4" v-if="respuestas.estatus==0" rows="3" outlined v-model="respuesta" label="Solución"></v-textarea>
            <v-file-input
              v-if="respuestas.estatus==0"
              small-chips
              multiple
              filled
              dense
              single-line
              v-model="files"
              accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
              placeholder="Selecciona tus imagenes"
              prepend-icon="mdi-camera"
              label="Imágenes"
              @change="cargarFotos()"
            ></v-file-input>

            <v-row class="mb-4" v-if="respuestas.estatus==0">
              <v-col cols="2" v-for="(img, i) in vistaPrevias" :key="i">
                <v-card class="py-4 shadowCard">
                  <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                  <v-btn color="error" x-small @click="eliminarFoto(img.url)" top right absolute fab>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions  class="pa-0 pb-5" v-if="i == ( historial.length - 1 )">
            <v-btn color="blue"   dark small @click="cargarNotas()">Ver notas {{ mensajes.length }}</v-btn>
            <v-btn color="blue"   dark small @click="dialogHistorialReasignacion = true"><v-icon>mdi-file-document</v-icon> {{ historialAsignacion.length }}</v-btn>
            <v-btn color="orange" dark small @click="reasignarTicket()">Reasignar</v-btn>
            <v-spacer></v-spacer>
            <v-btn small color="success darken-1" @click="saveRespuestaAdmin(respuestas)">
              Responder
            </v-btn>
          </v-card-actions>
        </v-card-text>

      </v-card>
    </v-dialog>
    <!-- Aceptar respuesta auxiliar -->
    <v-dialog v-model="dialogRespuestaAuxi" max-width="600px" @keydown.ctrl="pegar">
      <v-card>
        <v-card-title>
          <span class="headline">Responder al usuario</span>
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogRespuestaAuxi=false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container class="pt-0">
            <v-row  class="mt-0">
              <v-col cols="8" class="pa-2">
                <p class="ma-0"><strong>Motivo de tu ticket</strong></p>
              </v-col>
            </v-row>
            <mensajeIzq :respuestas="lastHistorial" :ticket="ticket"></mensajeIzq>
            <mensajeDerAux :respuestas="lastHistorial" :ticket="ticket"></mensajeDerAux>
            <br>
            <v-divider></v-divider>
            <v-row class="mt-4">
              <v-textarea
                rows="3"
                outlined
                v-model="miRespuesta"
                label="Respuesta para el usuario"
                hide-details
                auto-grow
              ></v-textarea>
            </v-row>
            <v-file-input  class="mt-4"
              small-chips
              multiple
              filled
              dense
              single-line
              v-model="files"
              accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
              placeholder="Selecciona tus imagenes"
              prepend-icon="mdi-camera"
              label="Imágenes"
              @change="cargarFotos()"
            ></v-file-input>

            <v-row class="mb-4">
              <v-col cols="2" v-for="(img, i) in vistaPrevias" :key="i">
                <v-card class="py-4 shadowCard">
                  <v-img :src="img.url" contain aspect-ratio="2"></v-img>
                  <v-btn color="error" x-small @click="eliminarFoto(img.url)" top right absolute fab>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="pa-0">
            <v-btn color="blue"   dark small @click="cargarNotas()">Ver notas {{ mensajes.length }}</v-btn>
            <v-btn color="blue"   dark small @click="dialogHistorialReasignacion = true"><v-icon>mdi-file-document</v-icon> {{ historialAsignacion.length }}</v-btn>
            <v-btn color="orange" dark small @click="reasignarTicket()">Reasignar</v-btn>
           
            <v-btn color="blue" dark small @click="dialogAceptar = true">Regresar al Auxiliar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="responderTicket()">
              Responder
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMensajes" hide-overlay persistent max-width="800">
      <v-card style="position: relative">
        <v-card-title primary-title>
          Agregar nota
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogMensajes = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <span v-for="(mensaje, i) in mensajes" :key="i">
            <v-row v-if="mensaje.id_usuario != getdatosUsuario.iderp">
              <v-col cols="12">
                <v-card class="ml-6 pa-3 mt-2" max-width="400" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
                  <div class="text-subtitle-1">{{ mensaje.nota }} </div>
                  <v-card-actions class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.nombre_completo }}</div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="mensaje.id_usuario == getdatosUsuario.iderp">
              <v-col cols="12" align="right">
                <v-card class="mr-6 mt-2 pa-3" max-width="400" dark color="#0465ac" shaped style="border-radius: 15px; border-top-right-radius: 0;">
                  <div class="text-subtitle-1">{{ mensaje.nota }}</div>
                </v-card>
              </v-col>
            </v-row>
          </span>
        </v-card-text>

        <v-card-text class="pb-0 pt-6">
          <v-text-field
            v-model="mensajeRechazo"
            :append-outer-icon="mensajeRechazo ? 'mdi-send' : ''"
            outlined
            clearable
            label="Message"
            type="text"
            @click:append-outer="enviarMensaje()"
            hide-details
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialogMensajes = false">Cancelar</v-btn>
          <v-btn color="success" text @click="enviarMensaje()">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogHistorialReasignacion" hide-overlay max-width="800">
      <v-card style="position: relative">
        <v-card-title primary-title>
          Historial Reasignacion
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogHistorialReasignacion = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersHistorialAsignacion"
            :items="historialAsignacion"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogReasignar" hide-overlay persistent max-width="400">
      <v-card style="position: relative">
        <v-card-title primary-title >
          Reasignar ticket
          <v-spacer></v-spacer>
          <v-btn fab small text @click="dialogReasignar = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" >
              <v-autocomplete
                outlined
                dense
                :items="areas"
                v-model="area"
                label="Selecciona departamento"
                return-object
                item-value="iddepartamento"
                item-text="departamento"
              ></v-autocomplete>

              <v-autocomplete
                outlined
                dense
                :items="puestos"
                v-model="puesto"
                label="Selecciona un puesto"
                return-object
                item-value="idpuesto"
                item-text="puesto"
              ></v-autocomplete>

              <v-autocomplete
                outlined
                dense
                :items="jefes"
                v-model="jefe"
                label="Selecciona un jefe"
                return-object
                item-value="id_usuario"
                item-text="nombre_completo"
              ></v-autocomplete>

            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialogReasignar = false">Cancelar</v-btn>
          <v-btn color="success" text @click="reasignar()">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Visualizar imagenes -->
    <Imagen @close="closeImagen" :respuestaImagen="es_solucion" :isOpen="dialogImagen" :imagen="imagen" :ticket="ticket"/>

    <!-- Rechazar respuesta de Auxiliar-->
    <v-dialog v-model="dialogAceptar" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table">
                <div
                class="text-h6"
                style="display: table-cell; vertical-align: middle"
                >
                <strong>Aceptar:</strong> ¿Estás seguro que deseas cambiar el estatus a pendiente?
                <v-card-actions class="pt-6">
                  <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                  <v-btn
                  color="error"
                  text
                  large
                  class="mr-2"
                  @click="dialogAceptar=false"
                  >No, cancelar</v-btn
                  >
                  <!-- Guardar la información  -->
                  <v-btn
                  color="#5C5C5C"
                  dark
                  class="elevation-6"
                  large
                  @click="dialogAceptar = false, updateTicketEstatus()"
                  >Si</v-btn
                  >
                </v-card-actions>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-img src="@/assets/contrato.jpg"></v-img>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    </v-dialog>

    <!-- Visualizar historial -->
    <verTicket @close="closeModal" :isOpen="dialogHistorial" :historial="historial" :ticket="ticket"></verTicket>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
  var moment = require('moment');
  moment.locale('es');

  import funcionesExcel  from '@/mixins/funcionesExcel'
  import {mapGetters, mapActions} from 'vuex'

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import verTicket             from '@/components/tickets/verTicket.vue';
  import mensajeIzq            from '@/components/tickets/mensajeIzq.vue';
  import mensajeDer            from '@/components/tickets/mensajeDer.vue';
  import mensajeDerAux         from '@/components/tickets/mensajeDerAux.vue';
  import Imagen                from '@/components/tickets/Imagen.vue'

  import { Clipboard } from "@capacitor/clipboard";
  
  export default {
    components:{
      Alerta,
      carga,
      verTicket,
      mensajeIzq,
      mensajeDerAux,
      mensajeDer,
      Imagen
    },

    mixins: [ funcionesExcel ],

    data: () => ({
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      firstHistorial: {},
      lastHistorial: {},
      historial:{},
      ticket:{},

      tickets: [],
      mensajes:[],

      respuestaAlerta:false,
      cargar: false,
      historialAsignacion:[],

      dialogHistorial:false,
      dialogRespuesta: false,
      dialogRespuestaAuxi:false,
      dialogAsignarAux: false,
      dialogHistorialReasignacion: false,
      
      dialogAceptar: false,
      dialogReasignar:false,
      dialogMensajes:false,
      dialogError:false,

      search:'',
      respuesta:'',
      miRespuesta:'',
      mensajeRechazo:'',
      error:'',

    	auxiliares:[],
      auxiliar:{idauxi_area:0,nombre_completo:''},

      headers: [
        { text: 'Folio',       value: 'idticket',         },
        { text: 'Usuario',     value: 'nombre_completo'   },
        { text: 'Escuela',     value: 'id_unidad_negocio' },
        { text: 'Sucursal',    value: 'plantel'           },
        { text: 'Tema',        value: 'tema'              },
        { text: 'Subtema',     value: 'subtema'           },
        { text: 'F. creación', value: 'fecha_apertura'    },
        { text: 'F. entrega',  value: 'fecha_entrega'     },
        { text: 'Estatus',     value: 'estatus'           },
        { text: 'Encargado',   value: 'nombre_jefe'       },
        { text: 'Auxiliar',    value: 'nombre_auxiliar'   },
        { text: 'Actions',     value: 'actions', sortable: false },
        { text: 'Responder',   value: 'ver'               },
        { text: 'Historial',   value: 'historial', sortable: false },
      ],

      headersHistorialAsignacion: [
        { text: 'ID',                   value: 'idhistorial_ticket_reasignacion'},
        { text: 'Fecha',                value: 'fecha_reasignacion'             },
        { text: 'Departamento Origen',  value: 'area_viejo'                     },
        { text: 'Usuario',              value: 'usuario_viejo'                  },
        { text: 'Departamento Destino', value: 'area_nuevo'                     },
        { text: 'Usuario',              value: 'usuario_nuevo'                  },
      ],
      
      areas:[],
      area: null,

      puestos:[],
      puesto:null,

      jefes:[],
      jefe: null,

      estatusFiltro:2,

      url:'',
      urlINBI:'',
      urlFAST:'',

      escuela:0,
      urgente:0,

      dialogImagen:false,
      imagen: '',
      es_solucion:false,
      vistaPrevias: [],
      fotos:[],
      files: [],
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return this.editedIndex === -1 ? 'Agregar ticket' : 'Responder Ticket'
      },

      filterTickets () {
        if(this.tickets){
          let newtickets = this.tickets;
          if(this.estatusFiltro != 8){
             newtickets = newtickets.filter(el => el.estatus == this.estatusFiltro)
          }
          if (this.escuela) {
            newtickets = newtickets.filter(el => el.id_unidad_negocio == this.escuela)
          }
          if(this.urgente){
            newtickets = newtickets.filter(el => el.urgente == this.urgente)
          }
          return newtickets
        }
      },
    },

    watch: {
      area(){
        this.puestos = this.area.puestos
      },

      puesto(){
        this.jefes = this.puesto.jefes
      }
    },

    async created () {
      this.url = axios.defaults.baseURL + 'evidencia-tickets/'
      this.urlINBI = 'https://lmsinbi.club/evidencia-tickets/'
      this.urlFAST = 'https://lmsfast.club/evidencia-tickets/'

      this.cargar = true
      await this.getAreas()
      await this.getAuxiliares()
      await this.initialize()
    },

    methods: {
      initialize () {
        this.obtenerTickets()
      },

      obtenerTickets(){
        this.cargar = true
        this.tickets = []
        if(this.getdatosUsuario.cargo == 'Jefe'){
          return this.$http.get('tickets.area/'+this.getdatosUsuario.iderp ).then(response=>{
            for(const i in response.data){
              // if(response.data[i].nombre_jefe == this.getdatosUsuario.nombre_completo){
                response.data[i].fecha_apertura_correccion = moment(response.data[i].fecha_apertura).format('ll, h:mm a');
                this.tickets.push(response.data[i])
              // }
            }
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { 
            this.cargar = false
          })
        }
      },
      getAreas () {
        this.cargar = true
        this.tickets = []
        this.$http.get('departamentos.tickets').then(response=>{
          this.areas = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { })
      },
      getAuxiliares () {
        this.auxiliares = []
        return this.$http.get('auxiliares.porarea/'+this.getdatosUsuario.permiso.idareas_ticket).then(response=>{
          this.auxiliares = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { })
      },

      closeModal(){
        this.dialogHistorial = false
      },

      //dialogo AsignarAux
      verMotivo(value){
      	this.ticket = value
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then((response) => {
          this.firstHistorial = response.data.HistorialTicket[0]
          this.lastHistorial = response.data.HistorialTicket[response.data.HistorialTicket.length - 1]
          this.mensajes = response.data.notas
          this.historialAsignacion = response.data.asignacion
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { 
          this.dialogAsignarAux = true 
          this.cargar = false 
        })
        
      },

      verImagen( imagen, es_solucion ){
        this.imagen       = imagen
        this.es_solucion = es_solucion
        this.dialogImagen = true
      },
      closeImagen(){
        this.dialogImagen = false
      },

      save () {
      	this.ticket.idauxi_area = this.auxiliar.id_usuario
      	this.ticket.estatus = 4

        this.$http.put('tickets.update.asignacion.auxi/'+this.ticket.idticket, this.ticket).then(response=>{
        	
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { 
          this.validarSuccess('Ticket asignado a auxiliar')
          this.dialogAsignarAux = false
          this.cargar = false 
        })
      },

      verRespuestaAuxi(value){
        this.ticket = value
        this.historial = []
        
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then((response) => {
          this.firstHistorial = response.data.HistorialTicket[0]
          this.lastHistorial = response.data.HistorialTicket[response.data.HistorialTicket.length - 1]
          this.mensajes = response.data.notas
          this.historialAsignacion = response.data.asignacion
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { 
          this.dialogRespuestaAuxi = true
          this.cargar = false 
        })
      },

      responder(value){
        this.cargar = true
        this.ticket = value
        this.historial = []
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then(response=>{
          for(const i in response.data.HistorialTicket){
            response.data.HistorialTicket[i].fecha = moment(response.data.HistorialTicket[i].fecha).format('MMMM Do YYYY, h:mm:ss a');
            response.data.HistorialTicket[i].fecharespuesta = moment(response.data.HistorialTicket[i].fecharespuesta).format('MMMM Do YYYY, h:mm:ss a');
            //esta es la fecha y hora en la que se comenzo el registro de respuestas
            if(response.data.HistorialTicket[i].fecharespuesta == 'marzo 4º 2024, 3:31:27 pm'){
              response.data.HistorialTicket[i].fecharespuesta = ''
            }
            this.historial.push(response.data.HistorialTicket[i])
          }
          this.firstHistorial = response.data.HistorialTicket[0]
          this.lastHistorial = response.data.HistorialTicket[response.data.HistorialTicket.length - 1]
          this.mensajes = response.data.notas
          this.historialAsignacion = response.data.asignacion
          this.ver = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => {
          this.dialogRespuesta = true 
          this.cargar = false
        })
      },

      async responderTicket() {
        if(this.miRespuesta != ''){
          let fotos = []
          if(this.vistaPrevias.length){
            let respuesta = await this.subirFotosServidor( )
            fotos = respuesta.data
          }
          let payload = {
            idhistorial_ticket: this.lastHistorial.idhistorial_ticket,
            motivo: this.ticket.motivo,
            respuesta: this.miRespuesta,
            respuestaauxi: this.lastHistorial.respuestaauxi,
            idticket: this.ticket.idticket,
            estatus:0,
            id_usuario: this.ticket.id_usuario,
            folio: this.ticket.idticket,
            fotos,
            idauxi_area:        this.lastHistorial.idauxi_area,
            idjefe_area:        this.getdatosUsuario.id_usuario
          }
          var estatus = {estatus: 5}
          this.$http.put('historial_tickets.update/'+this.lastHistorial.idhistorial_ticket, payload).then(response=>{
            this.$http.put('tickets.update.estatus/'+this.ticket.idticket,estatus).then(response=>{
              this.miRespuesta = ''
              this.dialogRespuestaAuxi = false
              this.vistaPrevias = []
              this.obtenerTickets()
            }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        } else {
          this.validarErrorDirecto(' Respuesta vacia ')
        }
      },

      async saveRespuestaAdmin(respuestas){
        if(this.respuesta != ''){
          let fotos = []
          if(this.vistaPrevias.length){
              let respuesta = await this.subirFotosServidor( )
              fotos = respuesta.data
            }
          var payload = {
            idhistorial_ticket: respuestas.idhistorial_ticket,
            motivo: this.ticket.motivo,
            respuestaauxi: '',
            respuesta: this.respuesta,
            idticket: this.ticket.idticket,
            estatus:0,
            id_usuario: this.ticket.id_usuario,
            folio: this.ticket.idticket,
            fotos,
            idauxi_area:        this.lastHistorial.idauxi_area,
            idjefe_area:        this.getdatosUsuario.id_usuario,
          }
  
          var estatus = {estatus: 5}
  
          this.$http.put('historial_tickets.update/'+respuestas.idhistorial_ticket, payload).then(response=>{
            this.$http.put('tickets.update.estatus/'+this.ticket.idticket,estatus).then(response=>{
              this.mensaje= ''
              this.respuesta = ''
              this.dialogRespuesta = false
              this.vistaPrevias = []
              this.obtenerTickets()
            }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        } else {
          this.validarErrorDirecto(' Respuesta vacia ')
        }
      },

      //Ver Ticket
      historialTicket(value){
        this.cargar = true
        this.ticket = value
        this.historial = []
        this.$http.get('historial_tickets.estatus/'+this.ticket.idticket).then(response=>{
          for(const i in response.data.HistorialTicket){
            response.data.HistorialTicket[i].fecha = moment(response.data.HistorialTicket[i].fecha).format('MMMM Do YYYY, h:mm:ss a');
            response.data.HistorialTicket[i].fecharespuesta = moment(response.data.HistorialTicket[i].fecharespuesta).format('MMMM Do YYYY, h:mm:ss a');
            //esta es la fecha y hora en la que se comenzo el registro de respuestas
            if(response.data.HistorialTicket[i].fecharespuesta == 'marzo 4º 2024, 3:31:27 pm'){
              response.data.HistorialTicket[i].fecharespuesta = ''
            }
            this.historial.push(response.data.HistorialTicket[i])
          }
          this.firstHistorial = response.data.HistorialTicket[0]
          this.lastHistorial = response.data.HistorialTicket[response.data.HistorialTicket.length - 1]
          this.mensajes = response.data.notas
          this.historialAsignacion = response.data.asignacion
          this.dialogHistorial = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Sirve para cargar las notas del ticket
      cargarNotas () {
        this.$http.get('tickets.notas/' + this.ticket.idticket).then(response=>{
          this.mensajes        = response.data
          this.cargar         = false
          this.dialogMensajes  = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Subir la nota del ticket
      enviarMensaje () {
        if(this.mensajeRechazo != '' && this.mensajeRechazo != null){
          this.cargar = true
          let payload = {
            idticket:    this.ticket.idticket,
            id_usuario:  this.getdatosUsuario.iderp,
            nota:        this.mensajeRechazo,
          }

          this.$http.post('tickets.nota',payload).then(response=>{
            this.dialogRechazar = false
            this.mensajeRechazo = ''
            this.dialogMensajes = true
            this.mensajes       = []
            this.cargarNotas()
            this.cargar = false
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }else{
          this.error = 'Ingresa un mensaje'
          this.dialogError = true
          this.dialogRechazar = false
        }
      },  

      reasignarTicket () {
        this.dialogReasignar = true
      },

      reasignar() {
        if(this.area && this.jefe){
          this.ticket.idhistorial_ticket = this.lastHistorial.idhistorial_ticket
          this.ticket.id_area_viejo = this.ticket.id_area
          this.ticket.idjefe_area_viejo = this.ticket.idjefe_area
          this.ticket.id_area     = this.puesto.idpuesto
          this.ticket.idjefe_area = this.jefe.id_usuario
          this.ticket.estatus     = 2
          this.ticket.idauxi_area = 0
          
          this.$http.put('tickets.update.asignacion/'+this.ticket.idticket, this.ticket).then(response=>{
            this.dialogAsignarAux = false
            this.dialogReasignar = false
            this.area = null
            this.jefe = null
            this.initialize()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })

        }else{
          this.validarError( 'Datos vacios' )
        }
      },

      updateTicketEstatus() {
          this.cargar = true
          let payload = {
            idticket:    this.ticket.idticket,
            estatus:     3
          }
          this.$http.post('tickets.update.estatus', payload).then(response=>{
            this.dialogAceptar = false
            this.initialize()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
      },
      saveFecha( value ){
        this.cargar = true

        this.$http.post('tickets.fecha.entrega',value).then(response=>{
          this.cargar = false
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      /*MANEJO DE IMAGENES*/
      subirFotosServidor( ){
        let formData = new FormData();

        for( const i in this.vistaPrevias ){
          formData.append('file', this.vistaPrevias[i].file )
        }

        return new Promise((resolve,reject)=>{
          // obtener la extensión del archivo
          // Hacemos la petición
          this.$http.post(`tickets.subir.evidencia`, formData ).then(response=>{
            resolve( response )
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        })
      },
      cargarFotos(){
        if(this.files.length < 1){
          return
        }

        this.files.forEach((element, index) => {
          // creamos una variable tipo FormData
          let formData = new FormData();
          //se crea el objeto y se le agrega como un apendice el archivo 
          formData.append('file', element);
          //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
          if(element.size > 26214400){ //26214400 = 25MB
              this.validarErrorDirecto('El archivo sobrepasa el limite del peso, max 25 MB')
          } else {
              this.getBase64(element, formData)
          }
        })
      },
      getBase64(file, formData) {
        var me = this
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
        var video = false
        const nombreSplit = file.name.split('.')
        const extension = nombreSplit[nombreSplit.length-1]
        if(['mp4', 'mov', 'wmv', 'avi'].includes(extension)){
            video = true
        }
        me.vistaPrevias.push({
                url: reader.result, 
                formData: formData, 
                image_name: file.name,
                video : video,
                file
            })
        };
      },
      eliminarFoto(value){
        this.vistaPrevias.forEach((element, index)=>{
          if(element.url == value){
            this.vistaPrevias.splice(index,1);
          }
        })
      },
      convertFile(dataurl, filename) {
                var arr = dataurl.split(","),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }

                return new File([u8arr], filename, { type: mime });
      },
      createFile(file) {
          if (file.size > 10000000) {
              alert("please check file size no over 5 MB.");
              this.dragging = false;
              return;
          }

          this.file = file;
          this.archivos = file;
          this.getBase64(this.archivos);
          this.dragging = false;
      },
      async pegar(event) {
          if (event.code == "KeyV") {
              const { type, value } = await Clipboard.read();

              // Validamos que sea de tipo imagen
              if (type == "image/png") {
              // Convertimos el base64 en una imagen
              const file = this.convertFile(value, "imagen.png");
              this.createFile(file);
              }
          }
      },
      /*MANEJO DE ESTILOS Y OTROS*/
      itemRowBackground: function (item) {
        return item.urgente ? 'style-1' : ''
      },
      getColor (estatus) {
        if (estatus == 3) return 'red'
        else if (estatus == 1) return 'orange'
        else if (estatus == 2) return 'blue'
      },
      exportar(){
        this.exportExcel( this.filterTickets, 'tickets_list')
      },
      /*MANEJO DE ERRORES*/
      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },
      validarError ( value ) {
        this.cargar = false
        if( value.status == 400 || value.status == 500){
          this.parametros.mensaje      = value.data.message
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        } else {
          this.parametros.mensaje      = value
          this.parametros.dialogError  = true 
          this.parametros.color        = '#FF2C2C'
          this.respuestaAlerta = true 
        }
      },
    },

  }
</script>

<style>
  .mensajeIzq{
    white-space: pre-line;
    background-color: rgb(238, 235, 235);
    border-radius: 0px 10px 10px 10px;
  }
  .mensajeDer{
    white-space: pre-line;
    background-color: rgb(193, 231, 243);
    border-radius: 10px 0px 10px 10px;
  }
  .style-1 {
    background-color: rgb(253, 169, 169);
  }
  .style-1:hover {
    background-color: rgb(238, 131, 131) !important;
  }
</style>