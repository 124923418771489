<template>
  <v-container class="mt-3 px-5" fluid>
    <v-card class="shadowCard">
      <v-toolbar flat>
        <!-- Titulo -->
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <!-- Iconos que abren dialogos -->
        <HeaderTotales 
          :cantNuevos="cantNuevos"
          :prospectosBasura="prospectosBasura"
          :paramResagados="paramResagados"
          :prospectosCerrados="prospectosCerrados"
          :paramCerrados="paramCerrados"
          :buenos="buenos"
          :paramBuenos="paramBuenos"
          :malos="malos"
          :paramMalos="paramMalos"
          :fechas="fechas"
        />

        <v-spacer></v-spacer>

        <!-- Abrir el cuado de reporte de seguimiento de los prospectos -->
        <v-tooltip  color="orange" bottom> 
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile small class="mr-3" v-bind="attrs" v-on="on" color="orange" dark @click="dialogCalidadProspectos.estatus = true">
              <v-icon small left>mdi-clipboard-edit</v-icon> 
              Reporte 
            </v-btn>
          </template>
          <span>Prospectos clasificados como malos y buenos por vendedora</span>
        </v-tooltip>

        <!-- Abrir el cuado de reporte de seguimiento de los prospectos -->
        <v-tooltip  color="red" bottom> 
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile small class="mr-3" v-bind="attrs" v-on="on" color="red" dark @click="dialogProspectosProspectos.estatus = true">
              <v-icon small left>mdi-clipboard-edit</v-icon> 
              R. Estatus 
            </v-btn>
          </template>
          <span>Prospectos por vendedora y estatus</span>
        </v-tooltip>

        <!-- Abrir el cuado de reporte de seguimiento de los prospectos -->
        <v-tooltip  color="blue" bottom> 
          <template v-slot:activator="{ on, attrs }">
            <v-btn tile small class="mr-3" v-bind="attrs" v-on="on" color="blue" dark @click="initialize">
              <v-icon small>mdi-refresh</v-icon> 
            </v-btn>
          </template>
          <span>Actualizar</span>
        </v-tooltip>

        <v-btn color="success" small @click="exportarClasificacion()" tile>
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Buscador y filtros -->
      <v-card-text class="py-0">
        <v-row class="pb-0">
          <v-col cols="12" md="10" lg="9" class="pb-0">
            <v-radio-group v-model="estatusFiltro" row>
              <v-badge bordered color="red" overlap :content="canAtrasados" class="mr-10">
                <v-radio label="Atrasado" :value="1" color="red"></v-radio>
              </v-badge>
              <v-badge bordered color="green" overlap :content="cantNuevos" class="mr-10">
                <v-radio label="Nuevo" :value="2" color="green"></v-radio>
              </v-badge>
              <v-badge bordered color="orange" overlap :content="cantSeguimiento" class="mr-10">
                <v-radio label="Seguimiento" :value="3" color="orange"></v-radio>
              </v-badge>
              <v-badge bordered color="black" overlap :content="cantTodas">
                <v-radio label="Todos" :value="6" color="black"></v-radio>
              </v-badge>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="4" md="4" lg="3" xl="2" v-if="(![18,73].includes( this.getdatosUsuario.idpuesto)) || 
          (([18,73].includes( this.getdatosUsuario.idpuesto)) && [7,161,334,568,626,382,526].includes( getdatosUsuario.iderp ))" class="pb-0">
            <v-autocomplete outlined dense clearable v-model="vendedoraFiltro" :items="vendedoras" label="Selecciona la vendedora" persistent-hint
              hide-details return-object item-text="nombre" item-value="iderp" >
            </v-autocomplete>
          </v-col>

          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="2" lg="3" xl="2" class="pb-0">
            <v-text-field v-model="search" dense outlined label="Buscar" append-icon="mdi-magnify"></v-text-field>  
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pt-0 pb-0" v-if="estatusFiltro != 6">
        <div v-if="estatusFiltro == 1 || estatusFiltro == 2">
          <v-alert
            border="left"
            colored-border
            type="info"
            elevation="2"
          >
            <div v-if="estatusFiltro == 1">
              Para cumplir con la etapa, es necesario cumplir con los siguientes criterios:<br/>
              1-. Ingresaron hace más de 1 día<br/>
              2-. No se le ha brindado información<br/>
              3-. No tiene tareas asignadas
            </div>

            <div v-if="estatusFiltro == 2">
              Para cumplir con la etapa, es necesario cumplir con los siguientes criterios:<br/>
              1-. Ingresaron el día de hoy<br/>
              2-. No se le ha brindado información<br/>
              3-. No tiene tareas asignadas
            </div>
          </v-alert>
        </div>

        <v-row v-if="estatusFiltro == 3 || estatusFiltro == 4 || estatusFiltro == 5">
          <v-col cols="12" md="6" lg="4">
            <v-alert
              border="left"
              colored-border
              type="info"
              elevation="2"
            >
              <strong>INDUCCIÓN | {{ cantInduccion }} |</strong><br/>
              Citerios:<br/>
              1-. El prospecto se encuentra en inducción<br/>
            </v-alert>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-alert
              border="left"
              colored-border
              type="info"
              elevation="2"
            >
              <strong>Seg. con información | {{ cantConInfo }} |</strong><br/>
              Criterios:<br/>
              1-. El prospecto ya cuenta con información<br/>
            </v-alert>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-alert
              border="left"
              colored-border
              type="info"
              elevation="2"
            >
              <strong>Seg. sin información | {{ cantSinInfo }} |</strong><br/>
              Criterios:<br/>
              1-. El prospecto no cuenta con información<br/>
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>  

      <!-- Tablas -->
      <v-card-text class="pt-0">
        <v-tabs
          dense
          v-model="tab"
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab dense class="text-capitalize">
            <v-icon left>mdi-account</v-icon>Sin seguimiento | {{ filterProspectos.length }} |
          </v-tab>

          <v-tab dense class="text-capitalize">
            <v-icon left>mdi-account</v-icon>Con seguimiento | {{ filterProspectosSeguimiento.length }} |
          </v-tab>

          <v-tab dense class="text-capitalize">
            <v-icon left>mdi-account</v-icon>Tarea Agendada, Inducción | {{ filterProspectosTareas.length }} |
          </v-tab>

          <!-- Apartado de las secciones de los tabs -->
          <v-tabs-items v-model="tab">

            <!-- Sin seguimiento -->
            <v-tab-item>
              <v-col cols="12" lg="12" class="pl-0">
                <v-card class="elevation-0" >
                  <div>
                    <v-subheader class="pl-0">
                      <v-btn color="success" smal icon @click="exportarContactos()"><v-icon>mdi-microsoft-excel</v-icon></v-btn>
                      <strong>Progreso {{ progreso }} %</strong>
                    </v-subheader>
                    <v-progress-linear
                      :value="progreso"
                      height="5"
                      rounded
                      readonly
                    >
                    </v-progress-linear>
                  </div>

                  <!-- :items="prospectosSeguimiento" -->
                  <v-data-table
                    :headers="headers"
                    :items="filterProspectos"
                    :sort-desc="[true, false,false,false,false]"
                    class="elevation-0"
                    :search="search"
                    dense
                    item-key="folio"
                    show-select
                    v-model="contactExport"
                  >

                    <!-- Datos dinámicos de las tablas -->
                    <template v-slot:item.folio="{ item }">
                      <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip 
                            small 
                            color="primary" 
                            @click="pegarPortapapeles(item.escuela, item.folio )"
                            v-on="on"
                            icon
                          >
                            {{ item.folio }}
                          </v-chip>
                        </template>
                        <span>{{ alertaUrlCopy }}</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.sucursal_interes_nombre="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.sucursal_interes_nombre"
                        @save="saveIneteres( props.item )"
                        large
                        save-text="Guardar"
                      >
                        {{ props.item.sucursal_interes_nombre ? props.item.sucursal_interes_nombre : props.item.sucursal_interes ? props.item.sucursal_interes : '' }}
                        <template v-slot:input>
                          <v-autocomplete
                            label="Plantel"
                            id="id"
                            filled
                            dense
                            single-line
                            hide-details
                            v-model="props.item.sucursal_interes"
                            :items="filterSucursales"
                            item-text="plantel"
                            item-value="idplantel"
                            clearable
                          ></v-autocomplete>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.curso_interes_nombre="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.curso_interes_nombre"
                        @save="saveIneteres( props.item )"
                        large
                        save-text="Guardar"
                      >
                        {{ props.item.curso_interes_nombre ? props.item.curso_interes_nombre : props.item.curso_interes ? props.item.curso_interes : '' }}
                        <template v-slot:input>
                          <v-autocomplete
                            label="Curso"
                            id="id"
                            filled
                            dense
                            single-line
                            hide-details
                            v-model="props.item.curso_interes"
                            :items="filterCursos"
                            item-text="curso"
                            item-value="id"
                            clearable
                          ></v-autocomplete>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.nivel="{ item }">
                      <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip 
                            small 
                            :color=" item.nivel == 0 ? 'orange' : item.nivel == null ? 'red' : 'success' " 
                            @click="verNivel( item )"
                            v-on="on"
                            icon
                            dark
                          >
                            <v-icon v-if="item.nivel == 0">mdi-file-alert</v-icon>
                            <v-icon v-else-if="item.nivel == null">mdi-file-cancel-outline</v-icon>
                            <v-icon v-else="item.nivel == null">mdi-file-check</v-icon>
                          </v-chip>
                        </template>
                        <span v-if="item.nivel == 0">Con examen, pero sin nivel</span>
                        <span v-else-if="item.nivel == null">Sin examen</span>
                        <span v-else="item.nivel == null">Con nivel</span>
                      </v-tooltip>
                    </template>

                    <!-- Datos dinámicos de las tablas -->
                    <template v-slot:item.actions="{ item }">
                      <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="verProspecto(item, item.idetapa)"
                          >
                            <!-- v-if="item.idcontador == 0" -->
                            <v-icon small>
                              mdi-open-in-new
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Ver</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.existeConmutador="{ item }">
                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="green" v-if="item.existeConmutador.length">
                        <template v-slot:activator="{ on, attrs }" v-if="item.existeConmutador.length">
                          <v-chip small  color="green"    dark  v-if="item.existeConmutador.length" v-bind="attrs" v-on="on" class="green--text">
                            <v-icon color="white">mdi-card-account-phone</v-icon>
                          </v-chip>
                        </template>
                        <span>
                          <span v-for="( mensaje, i ) in item.existeConmutador" :key="i">
                            {{ mensaje.mensaje }} 
                            <br/>
                          </span> 
                        </span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.idetapa="{ item }">
                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="red" v-if="item.idetapa == 1">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip small  color="red" dark  v-bind="attrs" v-on="on">Atrasado</v-chip>
                        </template>
                        <span>El proespecto esta esperando que se contacten con el</span>
                      </v-tooltip>

                      <!-- chip para prospectos nuevos -->
                      <v-tooltip bottom color="green" v-if="item.idetapa == 2">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 2">
                          <v-chip small  color="green"  dark  v-if="item.idetapa == 2" v-bind="attrs" v-on="on">Nuevo</v-chip>
                        </template>
                        <span>Prospecto llego hoy y no tiene información</span>
                      </v-tooltip>

                      <!-- chip para prospectos en inducción -->
                      <v-tooltip bottom color="pink" v-if="item.idetapa == 3">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 3">
                          <v-chip small  color="pink"   dark  v-if="item.idetapa == 3" v-bind="attrs" v-on="on"><v-icon v-if="item.dia_induccion == 1" color="yellow" left>mdi-bell</v-icon>Inducción</v-chip>
                        </template>
                        <span>El prospecto se encuentra en inducción</span>
                      </v-tooltip>

                      <!-- chip para prospectos en seguimiento con información -->
                      <v-tooltip bottom color="orange" v-if="item.idetapa == 4">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 4">
                          <v-chip small  color="orange" dark  v-if="item.idetapa == 4" v-bind="attrs" v-on="on">Con Info</v-chip>
                        </template>
                        <span>Al prospecto ya se le brindo información</span> 
                      </v-tooltip>

                      <!-- chip para prospectos con información -->
                      <v-tooltip bottom color="brown" v-if="item.idetapa == 5">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 5">
                          <v-chip small  color="brown"  dark  v-if="item.idetapa == 5" v-bind="attrs" v-on="on">Sin Info</v-chip>
                        </template>
                        <span>Al prospecto no se le ha podido brindar información</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.tareasP="{ item }">
                      <div v-if="item.tareasP.length > 0">
                        
                        <!-- Chip, tarea agendada -->
                        <v-tooltip bottom :color="item.tareasP[0].cumplida == 1 ? 'red' : 'yellow'" v-if="item.tareasP[0].tipo_tarea == 1">
                          <template v-slot:activator="{ on, attrs }" >
                            <v-chip 
                              small  
                              :color="item.tareasP[0].cumplida == 1 ? 'red' : 'yellow'"
                              v-bind="attrs" 
                              v-on="on"
                              :dark="item.tareasP[0].cumplida == 1 ? true : false"
                            >
                              <v-icon>mdi-calendar-clock</v-icon>
                            </v-chip>
                          </template>

                          <span class="white--text" v-if="item.tareasP[0].cumplida == 1 && item.tareasP[0].tipo_tarea == 1" dark>Existe una tarea agendada: {{ item.tareasP[0].fecha }} , {{ item.tareasP[0].hora }}</span>
                          <span class="black--text" v-if="item.tareasP[0].cumplida == 0 && item.tareasP[0].tipo_tarea == 1">Existe una tarea agendada: {{ item.tareasP[0].fecha }} , {{ item.tareasP[0].hora }}</span>
                        </v-tooltip>


                        <!-- Chip, tarea como nota -->
                        <v-tooltip bottom color="#004D40" v-if="item.tareasP[0].tipo_tarea == 2">
                          <template v-slot:activator="{ on, attrs }" >
                            <!-- Chip, tarea como nota -->
                            <v-chip 
                              dark
                              small  
                              color="#004D40"
                              v-bind="attrs" 
                              v-on="on"
                            >
                              <v-icon>mdi-calendar-blank</v-icon>
                            </v-chip>
                          </template>

                          <span class="white--text" dark>Existe una tarea como nota: {{ item.tareasP[0].fecha }}</span>
                        </v-tooltip>

                      </div>
                    </template>

                    <template v-slot:item.clasificacion="{ item }">
                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="green" v-if="item.clasificacion == 1">
                        <template v-slot:activator="{ on, attrs }" v-if="item.clasificacion == 1">
                          <v-chip small  color="green"    dark  v-if="item.clasificacion == 1" v-bind="attrs" v-on="on" class="green--text"></v-chip>
                        </template>
                        <span> Buen prospecto </span>
                      </v-tooltip>

                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="red" v-if="item.clasificacion == 2">
                        <template v-slot:activator="{ on, attrs }" v-if="item.clasificacion == 2">
                          <v-chip small  color="red"    dark  v-if="item.clasificacion == 2" v-bind="attrs" v-on="on" class="red--text"></v-chip>
                        </template>
                        <span>Mal prospecto </span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.tiempo_transcurrido="{ item }">

                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="red"  small dark class="pa-1 mr-1" v-if="item.tiempo_transcurrido >= 30 " v-bind="attrs" v-on="on"> {{ item.tiempo_transcurrido }} días</v-chip>
                        </template>
                        <span>El prospecto ya lleva mucho tiempo y no responde, cerrar!!</span>
                      </v-tooltip>

                      <v-tooltip bottom color="green">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="green"  small dark class="pa-1 mr-1" v-if="item.tiempo_transcurrido < 30 " v-bind="attrs" v-on="on"> {{ item.tiempo_transcurrido }} días</v-chip>
                        </template>
                        <span>El prospecto tiene {{ item.tiempo_transcurrido }} días que ingreso</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.intentoLlamada="{ item }">
                      <v-tooltip bottom color="red" v-if="item.intentoLlamada == 9 || item.intentoLlamada == 10">
                        <template v-slot:activator="{ on, attrs }" v-if="item.intentoLlamada == 9 || item.intentoLlamada == 10">
                          <v-btn fab x-small color="red" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
                            <v-icon x-small> mdi-phone-hangup </v-icon>
                          </v-btn>
                        </template>
                        <span v-if="item.intentoLlamada == 9">Se realizo un intento de llamada</span>
                        <span v-if="item.intentoLlamada == 10">Se realizo un intento de llamada por whatsApp</span>
                      </v-tooltip>

                      <v-tooltip bottom color="blue" v-if="item.intentoLlamada == 11 || item.intentoLlamada == 12">
                        <template v-slot:activator="{ on, attrs }" v-if="item.intentoLlamada == 11 || item.intentoLlamada == 12">
                          <v-btn fab x-small color="blue" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
                            <v-icon x-small> mdi-phone </v-icon>
                          </v-btn>
                        </template>
                        <span v-if="item.intentoLlamada == 11">Se realizo una llamada normal</span>
                        <span v-if="item.intentoLlamada == 12">Se realizo una llamada por whatsApp</span>
                      </v-tooltip>

                      <v-tooltip bottom color="orange" v-if="![9,10,11,12].includes(item.intentoLlamada) && item.medio_informacion == 1">
                        <template v-slot:activator="{ on, attrs }" v-if="![9,10,11,12].includes(item.intentoLlamada) && item.medio_informacion == 1">
                          <v-btn fab x-small color="orange" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
                            <v-icon x-small> mdi-store </v-icon>
                          </v-btn>
                        </template>
                        <span>Llegó directo a sucursal</span>
                      </v-tooltip>
                      
                    </template>

                    <template v-slot:no-data>
                      <v-btn color="primary" @click="initialize">
                        Actualizar
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-tab-item>

            <!-- CON SEGUIMIENTO -->
            <v-tab-item>
              <v-col cols="12" lg="12" class="pl-0">
                <v-card class="elevation-0" >
                  <v-data-table
                    :headers="headers"
                    :items="filterProspectosSeguimiento"
                    :sort-desc="[true, false,false,false,false]"
                    class="elevation-0"
                    :search="search"
                    dense
                    item-key="folio"
                    show-select
                    v-model="contactExport"
                  >
                    <!-- Datos dinámicos de las tablas -->
                    <template v-slot:item.folio="{ item }">
                      <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip 
                            small 
                            color="primary" 
                            @click="pegarPortapapeles(item.escuela, item.folio )"
                            v-on="on"
                            icon
                          >
                            {{ item.folio }}
                          </v-chip>
                        </template>
                        <span>{{ alertaUrlCopy }}</span>
                      </v-tooltip>

                    </template>

                    <template v-slot:item.sucursal_interes_nombre="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.sucursal_interes_nombre"
                        @save="saveIneteres( props.item )"
                        large
                        save-text="Guardar"
                      >
                        {{ props.item.sucursal_interes_nombre ? props.item.sucursal_interes_nombre : props.item.sucursal_interes ? props.item.sucursal_interes : '' }}
                        <template v-slot:input>
                          <v-autocomplete
                            label="Plantel"
                            id="id"
                            filled
                            dense
                            single-line
                            hide-details
                            v-model="props.item.sucursal_interes"
                            :items="filterSucursales"
                            item-text="plantel"
                            item-value="idplantel"
                            clearable
                          ></v-autocomplete>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.curso_interes_nombre="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.curso_interes_nombre"
                        @save="saveIneteres( props.item )"
                        large
                        save-text="Guardar"
                      >
                        {{ props.item.curso_interes_nombre ? props.item.curso_interes_nombre : props.item.curso_interes ? props.item.curso_interes : '' }}
                        <template v-slot:input>
                          <v-autocomplete
                            label="Curso"
                            id="id"
                            filled
                            dense
                            single-line
                            hide-details
                            v-model="props.item.curso_interes"
                            :items="filterCursos"
                            item-text="curso"
                            item-value="id"
                            clearable
                          ></v-autocomplete>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.nivel="{ item }">

                      <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip 
                            small 
                            :color=" item.nivel == 0 ? 'orange' : item.nivel == null ? 'red' : 'success' " 
                            @click="verNivel( item )"
                            v-on="on"
                            icon
                            dark
                          >
                            <v-icon v-if="item.nivel == 0">mdi-file-alert</v-icon>
                            <v-icon v-else-if="item.nivel == null">mdi-file-cancel-outline</v-icon>
                            <v-icon v-else="item.nivel == null">mdi-file-check</v-icon>
                          </v-chip>
                        </template>
                        <span v-if="item.nivel == 0">Con examen, pero sin nivel</span>
                        <span v-else-if="item.nivel == null">Sin examen</span>
                        <span v-else="item.nivel == null">Con nivel</span>
                      </v-tooltip>
                    </template>

                    <!-- Datos dinámicos de las tablas -->
                    <template v-slot:item.actions="{ item }">
                      <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="verProspecto(item, item.idetapa)"
                          >
                            <!-- v-if="item.idcontador == 0" -->
                            <v-icon small>
                              mdi-open-in-new
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Ver</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.existeConmutador="{ item }">
                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="green" v-if="item.existeConmutador.length">
                        <template v-slot:activator="{ on, attrs }" v-if="item.existeConmutador.length">
                          <v-chip small  color="green"    dark  v-if="item.existeConmutador.length" v-bind="attrs" v-on="on" class="green--text">
                            <v-icon color="white">mdi-card-account-phone</v-icon>
                          </v-chip>
                        </template>
                        <span>
                          <span v-for="( mensaje, i ) in item.existeConmutador" :key="i">
                            {{ mensaje.mensaje }} 
                            <br/>
                          </span> 
                        </span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.clasificacion="{ item }">
                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="green" v-if="item.clasificacion == 1">
                        <template v-slot:activator="{ on, attrs }" v-if="item.clasificacion == 1">
                          <v-chip small  color="green"    dark  v-if="item.clasificacion == 1" v-bind="attrs" v-on="on" class="green--text"></v-chip>
                        </template>
                        <span> Buen prospecto </span>
                      </v-tooltip>

                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="red" v-if="item.clasificacion == 2">
                        <template v-slot:activator="{ on, attrs }" v-if="item.clasificacion == 2">
                          <v-chip small  color="red"    dark  v-if="item.clasificacion == 2" v-bind="attrs" v-on="on" class="red--text"></v-chip>
                        </template>
                        <span>Mal prospecto </span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.idetapa="{ item }">
                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="red" v-if="item.idetapa == 1">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 1">
                          <v-chip small  color="red"    dark  v-if="item.idetapa == 1" v-bind="attrs" v-on="on">Atrasado</v-chip>
                        </template>
                        <span>El proespecto esta esperando que se contacten con el</span>
                      </v-tooltip>

                      <!-- chip para prospectos nuevos -->
                      <v-tooltip bottom color="green" v-if="item.idetapa == 2">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 2">
                          <v-chip small  color="green"  dark  v-if="item.idetapa == 2" v-bind="attrs" v-on="on">Nuevo</v-chip>
                        </template>
                        <span>Prospecto llego hoy y no tiene información</span>
                      </v-tooltip>

                      <!-- chip para prospectos en inducción -->
                      <v-tooltip bottom color="pink" v-if="item.idetapa == 3">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 3">
                          <v-chip small  color="pink"   dark  v-if="item.idetapa == 3" v-bind="attrs" v-on="on"><v-icon v-if="item.dia_induccion == 1" color="yellow" left>mdi-bell</v-icon>Inducción</v-chip>
                        </template>
                        <span>El prospecto se encuentra en inducción</span>
                      </v-tooltip>

                      <!-- chip para prospectos en seguimiento con información -->
                      <v-tooltip bottom color="orange" v-if="item.idetapa == 4">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 4">
                          <v-chip small  color="orange" dark  v-if="item.idetapa == 4" v-bind="attrs" v-on="on">Con Info</v-chip>
                        </template>
                        <span>Al prospecto ya se le brindo información</span> 
                      </v-tooltip>

                      <!-- chip para prospectos con información -->
                      <v-tooltip bottom color="brown" v-if="item.idetapa == 5">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 5">
                          <v-chip small  color="brown"  dark  v-if="item.idetapa == 5" v-bind="attrs" v-on="on">Sin Info</v-chip>
                        </template>
                        <span>Al prospecto no se le ha podido brindar información</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.tareasP="{ item }">
                      <div v-if="item.tareasP.length > 0">
                        
                        <!-- Chip, tarea agendada -->
                        <v-tooltip bottom :color="item.tareasP[0].cumplida == 1 ? 'red' : 'yellow'" v-if="item.tareasP[0].tipo_tarea == 1">
                          <template v-slot:activator="{ on, attrs }" >
                            <v-chip 
                              small  
                              :color="item.tareasP[0].cumplida == 1 ? 'red' : 'yellow'"
                              v-bind="attrs" 
                              v-on="on"
                              :dark="item.tareasP[0].cumplida == 1 ? true : false"
                            >
                              <v-icon>mdi-calendar-clock</v-icon>
                            </v-chip>
                          </template>

                          <span class="white--text" v-if="item.tareasP[0].cumplida == 1 && item.tareasP[0].tipo_tarea == 1" dark>Existe una tarea agendada: {{ item.tareasP[0].fecha }} , {{ item.tareasP[0].hora }}</span>
                          <span class="black--text" v-if="item.tareasP[0].cumplida == 0 && item.tareasP[0].tipo_tarea == 1">Existe una tarea agendada: {{ item.tareasP[0].fecha }} , {{ item.tareasP[0].hora }}</span>
                        </v-tooltip>


                        <!-- Chip, tarea como nota -->
                        <v-tooltip bottom color="#004D40" v-if="item.tareasP[0].tipo_tarea == 2">
                          <template v-slot:activator="{ on, attrs }" >
                            <!-- Chip, tarea como nota -->
                            <v-chip 
                              dark
                              small  
                              color="#004D40"
                              v-bind="attrs" 
                              v-on="on"
                            >
                              <v-icon>mdi-calendar-blank</v-icon>
                            </v-chip>
                          </template>

                          <span class="white--text" dark>Existe una tarea como nota: {{ item.tareasP[0].fecha }}</span>
                        </v-tooltip>

                      </div>
                    </template>

                    <template v-slot:item.tiempo_transcurrido="{ item }">

                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="red"  small dark class="pa-1 mr-1" v-if="item.tiempo_transcurrido >= 30 " v-bind="attrs" v-on="on"> {{ item.tiempo_transcurrido }} días</v-chip>
                        </template>
                        <span>El prospecto ya lleva mucho tiempo y no responde, cerrar!!</span>
                      </v-tooltip>

                      <v-tooltip bottom color="green">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="green"  small dark class="pa-1 mr-1" v-if="item.tiempo_transcurrido < 30 " v-bind="attrs" v-on="on"> {{ item.tiempo_transcurrido }} días</v-chip>
                        </template>
                        <span>El prospecto tiene {{ item.tiempo_transcurrido }} días que ingreso</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.intentoLlamada="{ item }">
                      <v-tooltip bottom color="red" v-if="item.intentoLlamada == 9 || item.intentoLlamada == 10">
                        <template v-slot:activator="{ on, attrs }" v-if="item.intentoLlamada == 9 || item.intentoLlamada == 10">
                          <v-btn fab x-small color="red" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
                            <v-icon x-small> mdi-phone-hangup </v-icon>
                          </v-btn>
                        </template>
                        <span v-if="item.intentoLlamada == 9">Se realizo un intento de llamada</span>
                        <span v-if="item.intentoLlamada == 10">Se realizo un intento de llamada por whatsApp</span>
                      </v-tooltip>


                      <v-tooltip bottom color="blue" v-if="item.intentoLlamada == 11 || item.intentoLlamada == 12">
                        <template v-slot:activator="{ on, attrs }" v-if="item.intentoLlamada == 11 || item.intentoLlamada == 12">
                          <v-btn fab x-small color="blue" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
                            <v-icon x-small> mdi-phone </v-icon>
                          </v-btn>
                        </template>
                        <span v-if="item.intentoLlamada == 11">Se realizo una llamada normal</span>
                        <span v-if="item.intentoLlamada == 12">Se realizo una llamada por whatsApp</span>
                      </v-tooltip>

                      <v-tooltip bottom color="orange" v-if="![9,10,11,12].includes(item.intentoLlamada) && item.medio_informacion == 1">
                        <template v-slot:activator="{ on, attrs }" v-if="![9,10,11,12].includes(item.intentoLlamada) && item.medio_informacion == 1">
                          <v-btn fab x-small color="orange" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
                            <v-icon x-small> mdi-store </v-icon>
                          </v-btn>
                        </template>
                        <span>Llegó directo a sucursal</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:no-data>
                      <v-btn color="primary" @click="initialize">
                        Actualizar
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-tab-item>

            <!-- Con seguimiento -->
            <v-tab-item>
              <v-col cols="12" lg="12" class="pl-0">
                <v-card class="elevation-0">
                  <v-data-table
                    :headers="headers"
                    :items="filterProspectosTareas"
                    :sort-desc="[true, false,false,false,false]"
                    class="elevation-0"
                    :search="search"
                    dense
                    item-key="folio"
                    show-select
                    v-model="contactExport"
                  >

                    <template v-slot:item.nivel="{ item }">

                      <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip 
                            small 
                            :color=" item.nivel == 0 ? 'orange' : item.nivel == null ? 'red' : 'success' " 
                            @click="verNivel( item )"
                            v-on="on"
                            icon
                            dark
                          >
                            <v-icon v-if="item.nivel == 0">mdi-file-alert</v-icon>
                            <v-icon v-else-if="item.nivel == null">mdi-file-cancel-outline</v-icon>
                            <v-icon v-else="item.nivel == null">mdi-file-check</v-icon>
                          </v-chip>
                        </template>
                        <span v-if="item.nivel == 0">Con examen, pero sin nivel</span>
                        <span v-else-if="item.nivel == null">Sin examen</span>
                        <span v-else="item.nivel == null">Con nivel</span>
                      </v-tooltip>
                    </template>

                    <!-- Datos dinámicos de las tablas -->
                    <template v-slot:item.folio="{ item }">
                      <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip 
                            small 
                            color="primary" 
                            @click="pegarPortapapeles(item.escuela, item.folio )"
                            v-on="on"
                            icon
                          >
                            {{ item.folio }}
                          </v-chip>
                        </template>
                        <span>{{ alertaUrlCopy }}</span>
                      </v-tooltip>

                    </template>

                    <template v-slot:item.sucursal_interes_nombre="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.sucursal_interes_nombre"
                        @save="saveIneteres( props.item )"
                        large
                        save-text="Guardar"
                      >
                        {{ props.item.sucursal_interes_nombre ? props.item.sucursal_interes_nombre : props.item.sucursal_interes ? props.item.sucursal_interes : '' }}
                        <template v-slot:input>
                          <v-autocomplete
                            label="Plantel"
                            id="id"
                            filled
                            dense
                            single-line
                            hide-details
                            v-model="props.item.sucursal_interes"
                            :items="filterSucursales"
                            item-text="plantel"
                            item-value="idplantel"
                            clearable
                          ></v-autocomplete>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <template v-slot:item.curso_interes_nombre="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.curso_interes_nombre"
                        @save="saveIneteres( props.item )"
                        large
                        save-text="Guardar"
                      >
                        {{ props.item.curso_interes_nombre ? props.item.curso_interes_nombre : props.item.curso_interes ? props.item.curso_interes : '' }}
                        <template v-slot:input>
                          <v-autocomplete
                            label="Curso"
                            id="id"
                            filled
                            dense
                            single-line
                            hide-details
                            v-model="props.item.curso_interes"
                            :items="filterCursos"
                            item-text="curso"
                            item-value="id"
                            clearable
                          ></v-autocomplete>
                        </template>
                      </v-edit-dialog>
                    </template>

                    <!-- Datos dinámicos de las tablas -->
                    <template v-slot:item.actions="{ item }">
                      <v-tooltip top >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            @click="verProspecto(item, item.idetapa)"
                          >
                            <!-- v-if="item.idcontador == 0" -->
                            <v-icon small>
                              mdi-open-in-new
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Ver</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.existeConmutador="{ item }">
                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="green" v-if="item.existeConmutador.length">
                        <template v-slot:activator="{ on, attrs }" v-if="item.existeConmutador.length">
                          <v-chip small  color="green"    dark  v-if="item.existeConmutador.length" v-bind="attrs" v-on="on" class="green--text">
                            <v-icon color="white">mdi-card-account-phone</v-icon>
                          </v-chip>
                        </template>
                        <span>
                          <span v-for="( mensaje, i ) in item.existeConmutador" :key="i">
                            {{ mensaje.mensaje }} 
                            <br/>
                          </span> 
                        </span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.clasificacion="{ item }">
                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="green" v-if="item.clasificacion == 1">
                        <template v-slot:activator="{ on, attrs }" v-if="item.clasificacion == 1">
                          <v-chip small  color="green"    dark  v-if="item.clasificacion == 1" v-bind="attrs" v-on="on" class="green--text"></v-chip>
                        </template>
                        <span> Buen prospecto </span>
                      </v-tooltip>

                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="red" v-if="item.clasificacion == 2">
                        <template v-slot:activator="{ on, attrs }" v-if="item.clasificacion == 2">
                          <v-chip small  color="red"    dark  v-if="item.clasificacion == 2" v-bind="attrs" v-on="on" class="red--text"></v-chip>
                        </template>
                        <span>Mal prospecto </span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.idetapa="{ item }">
                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="red" v-if="item.idetapa == 1">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 1">
                          <v-chip small  color="red"    dark  v-if="item.idetapa == 1" v-bind="attrs" v-on="on">Atrasado</v-chip>
                        </template>
                        <span>El proespecto esta esperando que se contacten con el</span>
                      </v-tooltip>

                      <!-- chip para prospectos nuevos -->
                      <v-tooltip bottom color="green" v-if="item.idetapa == 2">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 2">
                          <v-chip small  color="green"  dark  v-if="item.idetapa == 2" v-bind="attrs" v-on="on">Nuevo</v-chip>
                        </template>
                        <span>Prospecto llego hoy y no tiene información</span>
                      </v-tooltip>

                      <!-- chip para prospectos en inducción -->
                      <v-tooltip bottom color="pink" v-if="item.idetapa == 3">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 3">
                          <v-chip small  color="pink"   dark  v-if="item.idetapa == 3" v-bind="attrs" v-on="on"><v-icon v-if="item.dia_induccion == 1" color="yellow" left>mdi-bell</v-icon>Inducción</v-chip>
                        </template>
                        <span>El prospecto se encuentra en inducción</span>
                      </v-tooltip>

                      <!-- chip para prospectos en seguimiento con información -->
                      <v-tooltip bottom color="orange" v-if="item.idetapa == 4">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 4">
                          <v-chip small  color="orange" dark  v-if="item.idetapa == 4" v-bind="attrs" v-on="on">Con Info</v-chip>
                        </template>
                        <span>Al prospecto ya se le brindo información</span> 
                      </v-tooltip>

                      <!-- chip para prospectos con información -->
                      <v-tooltip bottom color="brown" v-if="item.idetapa == 5">
                        <template v-slot:activator="{ on, attrs }" v-if="item.idetapa == 5">
                          <v-chip small  color="brown"  dark  v-if="item.idetapa == 5" v-bind="attrs" v-on="on">Sin Info</v-chip>
                        </template>
                        <span>Al prospecto no se le ha podido brindar información</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.tareasP="{ item }">
                      <div v-if="item.tareasP.length > 0">
                        
                        <!-- Chip, tarea agendada -->
                        <v-tooltip bottom :color="item.tareasP[0].cumplida == 1 ? 'red' : 'yellow'" v-if="item.tareasP[0].tipo_tarea == 1">
                          <template v-slot:activator="{ on, attrs }" >
                            <v-chip 
                              small  
                              :color="item.tareasP[0].cumplida == 1 ? 'red' : 'yellow'"
                              v-bind="attrs" 
                              v-on="on"
                              :dark="item.tareasP[0].cumplida == 1 ? true : false"
                            >
                              <v-icon>mdi-calendar-clock</v-icon>
                            </v-chip>
                          </template>

                          <span class="white--text" v-if="item.tareasP[0].cumplida == 1 && item.tareasP[0].tipo_tarea == 1" dark>Existe una tarea agendada: {{ item.tareasP[0].fecha }} , {{ item.tareasP[0].hora }}</span>
                          <span class="black--text" v-if="item.tareasP[0].cumplida == 0 && item.tareasP[0].tipo_tarea == 1">Existe una tarea agendada: {{ item.tareasP[0].fecha }} , {{ item.tareasP[0].hora }}</span>
                        </v-tooltip>


                        <!-- Chip, tarea como nota -->
                        <v-tooltip bottom color="#004D40" v-if="item.tareasP[0].tipo_tarea == 2">
                          <template v-slot:activator="{ on, attrs }" >
                            <!-- Chip, tarea como nota -->
                            <v-chip 
                              dark
                              small  
                              color="#004D40"
                              v-bind="attrs" 
                              v-on="on"
                            >
                              <v-icon>mdi-calendar-blank</v-icon>
                            </v-chip>
                          </template>

                          <span class="white--text" dark>Existe una tarea como nota: {{ item.tareasP[0].fecha }}</span>
                        </v-tooltip>

                      </div>
                    </template>

                    <template v-slot:item.tiempo_transcurrido="{ item }">

                      <!-- chip para prospectos atrasados -->
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="red"  small dark class="pa-1 mr-1" v-if="item.tiempo_transcurrido >= 30 " v-bind="attrs" v-on="on"> {{ item.tiempo_transcurrido }} días</v-chip>
                        </template>
                        <span>El prospecto ya lleva mucho tiempo y no responde, cerrar!!</span>
                      </v-tooltip>

                      <v-tooltip bottom color="green">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="green"  small dark class="pa-1 mr-1" v-if="item.tiempo_transcurrido < 30 " v-bind="attrs" v-on="on"> {{ item.tiempo_transcurrido }} días</v-chip>
                        </template>
                        <span>El prospecto tiene {{ item.tiempo_transcurrido }} días que ingreso</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.intentoLlamada="{ item }">
                      <v-tooltip bottom color="red" v-if="item.intentoLlamada == 9 || item.intentoLlamada == 10">
                        <template v-slot:activator="{ on, attrs }" v-if="item.intentoLlamada == 9 || item.intentoLlamada == 10">
                          <v-btn fab x-small color="red" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
                            <v-icon x-small> mdi-phone-hangup </v-icon>
                          </v-btn>
                        </template>
                        <span v-if="item.intentoLlamada == 9">Se realizo un intento de llamada</span>
                        <span v-if="item.intentoLlamada == 10">Se realizo un intento de llamada por whatsApp</span>
                      </v-tooltip>


                      <v-tooltip bottom color="blue" v-if="item.intentoLlamada == 11 || item.intentoLlamada == 12">
                        <template v-slot:activator="{ on, attrs }" v-if="item.intentoLlamada == 11 || item.intentoLlamada == 12">
                          <v-btn fab x-small color="blue" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
                            <v-icon x-small> mdi-phone </v-icon>
                          </v-btn>
                        </template>
                        <span v-if="item.intentoLlamada == 11">Se realizo una llamada normal</span>
                        <span v-if="item.intentoLlamada == 12">Se realizo una llamada por whatsApp</span>
                      </v-tooltip>

                      <v-tooltip bottom color="orange" v-if="![9,10,11,12].includes(item.intentoLlamada) && item.medio_informacion == 1">
                        <template v-slot:activator="{ on, attrs }" v-if="![9,10,11,12].includes(item.intentoLlamada) && item.medio_informacion == 1">
                          <v-btn fab x-small color="orange" dark class="pa-1 mr-1"  v-bind="attrs" v-on="on">
                            <v-icon x-small> mdi-store </v-icon>
                          </v-btn>
                        </template>
                        <span>Llegó directo a sucursal</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:no-data>
                      <v-btn color="primary" @click="initialize">
                        Actualizar
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card-text>   
    </v-card>

    <!-- dialog del prospecto -->
    <v-dialog
      v-if="prospecto"
      v-model="dialogProspecto"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card
        :loading="loading"
      >
        <template slot="progress">
          <v-progress-linear
            color="secondary"
            height="10"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-toolbar
          dark
          color="primary"
          class="elevation-1"
        >
          PROSPECTOS NUEVOS | {{ getNuevosProspectos }} |
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="anterior()"
              v-if="estatusLlamada == 0"
            >
              <v-icon>mdi-arrow-left-bold</v-icon>
            </v-btn>

            <v-btn
              icon
              dark
              @click="siguiente()"
              v-if="estatusLlamada == 0"
            >
              <v-icon>mdi-arrow-right-bold</v-icon>
            </v-btn>
            
            <v-btn
              icon
              dark
              @click="dialogProspecto = false, limpiar(), initialize()"
              v-if="estatusLlamada == 0 && [18,73].includes( this.getdatosUsuario.idpuesto)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn
              icon
              dark
              @click="dialogProspecto = false"
              v-if="estatusLlamada == 0  && ![18,73].includes( this.getdatosUsuario.idpuesto)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <!-- Datos principales del usuario -->
        <v-card-text>
          <v-row>
            <!-- Datos del usuario -->
            <v-col cols="12" lg="6">

              <!-- Eventos en timeline -->
              <v-card-text class="pt-4 pb-0">
                <v-subheader class="pl-0">Eventos</v-subheader>
                <div v-if="!eventosTimeline.length">Sin eventos</div>
                <Eventos v-else :eventosTimeline="eventosTimeline"/>
              </v-card-text>

              <v-card-text class="pt-12">
                <v-row>
                  <!-- Datos personales -->
                  <v-col cols="12" sm="6" class="pb-0">
                    <DatosPersonales :prospecto="prospecto"/>
                    <Etapas          :idetapa="idetapa"/>
                    <DetalleEvento   :prospecto="prospecto"/>
                  </v-col>

                  <!-- Comentarios -->
                  <v-col cols="12" sm="6" class="pb-0 pt-0">
                    <Comentario      :comentarios="comentarios" @click="addComentario"/>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <!-- Botones de accion -->
              <v-card class="elevation-0">
                <v-card-title primary-title>
                  BOTONES DE ACCIÓN
                </v-card-title>
                <v-card-text>
                  <v-row>

                    <!-- Boton para reasignar prospecto -->
                    <v-col>
                      <BotonesAccion @click="abrirDialogReasignacion"/>
                    </v-col>

                    <!-- Boton para finalizar prospecto -->
                    <v-col>
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on, attrs }">
                          <v-card
                            color="red"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="dialogFinalizacion = true, getGrupos()"
                            class="text-center mr-2"
                            style="border-radius: 200px;"
                            max-width="160"
                          >
                            <v-card-text class="py-1">
                              <div class="white--text text-h5">
                                <v-icon
                                  size="22"
                                  color="white"
                                  v-text="'mdi-account-check'"
                                >
                                </v-icon>
                              </div>
                              <sub class="text-caption white--text ">Finalizar</sub>
                            </v-card-text>
                          </v-card>
                        </template>
                        <span>Finalizar el prospecto</span>
                      </v-tooltip>
                    </v-col>

                    <!-- Boton para guardar los datos -->
                    <v-col>
                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-card
                            color="grey"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="actualizarProspecto()"
                            class="text-center mr-2"
                            style="border-radius: 200px;"
                            max-width="160"
                          >
                            <v-card-text class="py-1">
                              <div class="white--text text-h5">
                                <v-icon
                                  size="22"
                                  color="white"
                                  v-text="'mdi-content-save'"
                                >
                                </v-icon>
                              </div>
                              <sub class="text-caption white--text ">Guardar datos</sub>
                            </v-card-text>
                          </v-card>
                        </template>
                        <span>Guardar datos</span>
                      </v-tooltip>
                    </v-col>

                    <!-- Boton para llego a sucursal -->
                    <v-col>
                      <v-tooltip bottom color="orange">
                        <template v-slot:activator="{ on, attrs }">
                          <v-card
                            color="orange"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="inciarSeguimientoEnSucursal()"
                            class="text-center mr-2"
                            style="border-radius: 200px;"
                            max-width="160"
                          >
                            <v-card-text class="py-1">
                              <div class="white--text text-h5">
                                <v-icon
                                  size="22"
                                  color="white"
                                  v-text="'mdi-store'"
                                >
                                </v-icon>
                              </div>
                              <sub class="text-caption white--text ">Llego a sucursal</sub>
                            </v-card-text>
                          </v-card>
                        </template>
                        <span>Llego a sucursal y llenar perfil</span>
                      </v-tooltip>
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>

              <v-divider></v-divider>
            </v-col>

            <!-- TABS DE SEGUIMIENTO -->
            <v-col cols="12" md="12" lg="6">
              <v-tabs
                color="secondary"
                right
                dense
                class="elevation-0"
              >
                <v-tab class="text-capitalize" v-if="idetapa ==  3 || idetapa == 4">Información</v-tab>
                <v-tab class="text-capitalize">Tareas | {{ tareas_programadas.length }} |</v-tab>
                <v-tab class="text-capitalize">Llamadas | {{ llamadasList.length }} |</v-tab>
                <v-tab class="text-capitalize">Clasificación </v-tab>
                <v-tab class="text-capitalize">Examen </v-tab>

                <!-- Primer TAB INFROMACIÓN -->
                <!-- Este tab solo se habilitará si no es primer contacto -->
                <v-tab-item v-if="idetapa ==  3 || idetapa == 4" class="pt-0">
                  <v-row class="mt-4">

                    <!-- Pregunta #1 -->
                    <v-col cols="12">
                      <div>¿El curso es para ti, o para alguien más?</div>
                      <v-radio-group 
                        v-model="paraquien_el_curso" 
                        row
                        hide-details
                        class="mt-0"
                      >
                        <v-radio :value="1" row>
                          <template v-slot:label>
                            <div>Para mi</div>
                          </template>
                        </v-radio>
                        <v-radio :value="0">
                          <template v-slot:label>
                            <div>Alguién más</div> 
                          </template>
                        </v-radio>
                        <v-radio :value="2">
                          <template v-slot:label>
                            <div>Hermanos</div> 
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>

                    <!-- Añadir familiares -->
                    <v-col cols="12" v-if="showFamiliares">
                      <div><v-btn color="primary" class="text-capitalize" @click="addPersonas = true">Agregar personas</v-btn></div>
                      <div v-if="addPersonas" class="pt-6">
                        <v-card>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" md="6" lg="7">
                                <v-text-field
                                  label="Nombre completo"
                                  dense
                                  v-model="familiar"
                                  outlined
                                  hide-details
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" md="5" lg="4">
                                <v-text-field
                                  label="Edad"
                                  dense
                                  v-model="edadFamiliar"
                                  outlined
                                  hide-details
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" md="6">
                                <v-text-field
                                  label="Correo"
                                  dense
                                  v-model="correoFamiliar"
                                  outlined
                                  hide-details
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" md="6">
                                <v-text-field
                                  label="Teléfono"
                                  dense
                                  v-model="telefonoFamiliar"
                                  outlined
                                  hide-details
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="success" fab small @click="addFamiliar()"><v-icon>mdi-check</v-icon></v-btn>
                          </v-card-actions>
                        </v-card>
                      </div>

                      <div class="pt-2"><small>Persoas añadidas</small></div>
                      <div v-if="familiares.length">
                        <v-data-table
                          :headers="headersFamiliares"
                          :items="familiares"
                          class="elevation-1"
                          fixed-header
                          hide-default-footer
                          :items-per-page="familiares.length"
                          dense
                        >
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                              small
                              @click="deletFamiliar(item)"
                              color="grey"
                              class="mr-2"
                              v-if="item.finalizo == 0"
                            >
                              mdi-delete
                            </v-icon>

                            <v-icon
                              small
                              @click="dialogFinalizarFamiliar = true, familiarAFinalizar = item, getGrupos()"
                              color="red"
                              v-if="item.finalizo == 0"
                            >
                              mdi-account-check
                            </v-icon>

                            <v-chip v-if="item.finalizo == 1" color="green" dark small>Finalizado</v-chip>
                          </template>
                        </v-data-table>
                      </div>
                    </v-col>

                    <!-- Pregunta #2 -->
                    <v-col cols="12">
                      <div>¿Anteriormente ha estudiado inglés en alguna otra escuela o colegio?</div>
                      <v-radio-group 
                        v-model="estudioIngles" 
                        row
                        hide-details
                        class="mt-0"
                      >
                        <v-radio :value="1" row>
                          <template v-slot:label>
                            <div>Si</div>
                          </template>
                        </v-radio>
                        <v-radio :value="0">
                          <template v-slot:label>
                            <div>No</div> 
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>

                    <!-- Pregunta #3 -->
                    <v-col cols="12" sm="6" lg="4">
                      <v-select
                        :items="['Acádemico','Trabajo','Ocio']"
                        v-model="porqueEstudiarIngles"
                        label="¿Por qué estudiar inglés?"
                        hide-details
                        dense
                      ></v-select>
                    </v-col>

                    <!-- pregunta #4 -->
                    <v-col cols="12" sm="6" lg="4">
                      <v-select
                        :items="['Mañana','Tarde','Noche','Fin de semana']"
                        v-model="dispoHorario"
                        label="Disponibilidad horario"
                        hide-details
                        dense
                      ></v-select>
                    </v-col>

                    <!-- pregunta #5 -->
                    <v-col cols="12" sm="6" lg="4">
                      <v-text-field
                        v-model="edad"
                        dense
                        hide-details
                        type="number"
                        label="Edad"
                        clearable
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" lg="4">
                      <v-select
                        :items="filterSucursales"
                        v-model="sucursal"
                        return-object
                        item-text="plantel"
                        item-value="idplantel"
                        label="Sucursal"
                        hide-details
                        dense
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" lg="4">
                      <v-select
                        :items="anuncios"
                        v-model="anuncio"
                        return-object
                        item-text="anuncio"
                        item-value="idanuncios"
                        label="¿Cómo nos conocio?"
                        hide-details
                        dense
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" lg="4">
                      <v-select
                        :items="cursos"
                        v-model="curso"
                        return-object
                        item-text="curso"
                        item-value="idcursos_escuela"
                        label="Curso"
                        hide-details
                        dense
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" lg="4">
                      <v-select
                        :items="modalidadesEscuela"
                        v-model="modalidad"
                        return-object
                        item-text="modalidad"
                        item-value="idmodalidad_cursos"
                        label="Modalidad"
                        hide-details
                        dense
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" lg="4">
                      <v-select
                        :items="frecuenciasEscuela"
                        v-model="frecuencia"
                        return-object
                        item-text="frecuencia"
                        item-value="idfrecuencias"
                        label="Frecuencia"
                        hide-details
                        dense
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" lg="4">
                      <v-select
                        :items="ciclos_plantel"
                        v-model="ciclo"
                        return-object
                        item-text="ciclo"
                        item-value="id_ciclo"
                        label="Ciclo interes"
                        hide-details
                        dense
                      ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" lg="4">
                      <v-select
                        disabled
                        :items="vendedoras_plantel"
                        v-model="vendedora"
                        return-object
                        item-text="nombre"
                        item-value="iderp"
                        label="Vendedora"
                        hide-details
                        dense
                        readonly
                      ></v-select>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea
                        v-model="notas"
                        dense
                        rows="3"
                        label="comentarios"
                        outlined
                        hide-details
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12">
                      <v-btn 
                        color="primary" 
                        block 
                        class="text-capitalize"
                        @click="updatePerfil()"
                      >
                        Actualizar Perfil
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- Tab de tareas programados -->
                <v-tab-item class="pt-0">
                  <v-row>
                    <v-col cols="12" md="8">

                      <!-- Agregar TAREAS -->
                      <v-card class="elevation-0">
                        <v-card-title primary-title>
                          <v-spacer></v-spacer>

                          <!-- Boton para agregar tareas -->
                          <v-btn color="secondary" fab small @click="opcionAddTarea = !opcionAddTarea, banderaTarea = 0, disableBtnTarea = false" v-if="tareas_programadas.length == 0">
                            <!-- Agregar o eliminar -->
                            <v-icon v-text="opcionAddTarea ? 'mdi-window-minimize' : 'mdi-plus' ">mdi-plus</v-icon>
                          </v-btn>

                          <v-btn class="ml-2" dark color="orange" fab small @click="dialogCalendario.estatus = true">
                            <v-icon >mdi-calendar-check</v-icon>
                          </v-btn>

                        </v-card-title>
                        <v-card-text v-if="opcionAddTarea">

                          <v-text-field
                            v-model="dia"
                            label="Día"
                            dense
                            type="date"
                            filled
                          ></v-text-field>

                          <v-text-field
                            v-model="hora"
                            label="Hora"
                            dense
                            type="time"
                            filled
                            class="mt-2"
                          ></v-text-field>

                          <v-textarea
                            filled
                            auto-grow
                            rows="3"
                            v-model="nota"
                            label="Nota"
                            dense
                            class="mt-2"
                          ></v-textarea>

                          <v-row>
                            <!-- <v-col cols="12"  md="6" lg="4">
                              <v-text-field
                                v-model="dia"
                                label="Día"
                                dense
                                type="date"
                                hide-details
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12"  md="6" lg="4" v-if="tipo_tarea && tipo_tarea.id == 2">
                              <v-select
                                :items="horaPrecargada"
                                v-model="horaCargada"
                                label="Hora"
                                dense
                                hide-details
                                item-value="id"
                                item-text="hora"
                                return-object
                                clearable
                              ></v-select>
                            </v-col>

                            <v-col cols="12"  md="6" lg="4" v-if="horaCargada && horaCargada.id == 5 || tipo_tarea && tipo_tarea.id == 1">
                              <v-text-field
                                v-model="hora"
                                label="Hora"
                                dense
                                type="time"
                                hide-details
                              ></v-text-field>
                            </v-col> -->

                            <v-col cols="12">
                              <!-- :disabled="disableBtnTarea" -->
                              <v-btn color="primary" block @click="programarTarea()"   v-if="banderaTarea == 0 && motivo && tipo_tarea">Promagar tarea</v-btn>
                              <v-btn color="primary" block @click="reprogramarTarea()" v-if="banderaTarea == 1">Reprogramar tarea</v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <!-- Tareas programadas -->
                        <v-card-text>
                          <div v-for="(tarea, i) in tareas_programadas" :key="i">
                            <div class="text-body-1">
                              <v-icon :color="tarea.cumplida == 1 ? 'red' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
                              {{ tarea.motivo }}<br/>
                              <span v-if="tarea.idmotivos == 7">{{ tarea.descrip_motivo }}</span>
                            </div>
                            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>

                            <v-chip-group
                              column
                            >
                              <!-- Copiar el telefono -->
                              <v-tooltip
                                v-if="tarea.tarea_tipo == 1"
                                top
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dialogTareasProgramadas = false, intentoLlamada = 1, dialogSeguimiento = true"
                                    rounded
                                    small><v-icon>mdi-phone</v-icon>
                                  </v-chip>
                                </template>
                                <span>Click para iniciar</span>
                              </v-tooltip>

                              <!-- Mensaje de whatsapp -->
                              <v-tooltip
                                v-if="tarea.tarea_tipo == 2"
                                top
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dialogTareasProgramadas = false, intentoLlamada = 3, dialogSeguimiento = true"  
                                    rounded
                                    small><v-icon>mdi-whatsapp</v-icon>
                                  </v-chip>
                                </template>
                                <span>Click para iniciar</span>
                              </v-tooltip>

                              <!-- Eliminar -->
                              <v-tooltip
                                top
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip color="error"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="eliminarTarea(tarea.idtareas_prospectos)" 
                                    rounded
                                    small><v-icon>mdi-delete</v-icon>
                                  </v-chip>
                                </template>
                                <span>Eliminar tarea</span>
                              </v-tooltip>

                              <!-- Reprogramar -->
                              <v-tooltip
                                top
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip color="warning"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="actualizarTarea(tarea),disableBtnTarea = false" 
                                    rounded
                                    small><v-icon>mdi-refresh</v-icon>
                                  </v-chip>
                                </template>
                                <span>Reprogramar</span>
                              </v-tooltip>

                            </v-chip-group>
                            <v-divider></v-divider>
                          </div>

                          <v-subheader>Tareas realizadas</v-subheader>
                          <div v-for="tarea in tareas_realizadas">
                            <div class="text-body-1">
                              <v-icon :color="tarea.cumplida == 1 ? 'green' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
                              {{ tarea.motivo }}<br/>
                              <span v-if="tarea.idmotivos == 7">{{ tarea.descrip_motivo }}</span>
                            </div>
                            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>
                            <v-divider></v-divider>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- Tab de llamadas -->
                <v-tab-item class="pt-0">
                  <v-row>
                    <v-col v-if="llamadasList.length > 0">
                      <div class="head"
                        v-for="(llamada, i) in llamadasList"
                        :key="i"
                      >
                        <small>
                          <strong class="user">
                            {{ llamada.nombre_completo }}
                          </strong>
                            <v-icon small left>mdi-clock</v-icon>{{ llamada.duracion }}
                        </small>
                        <div 
                          class="head"
                          v-for="(notaLlamada, j) in llamada.notas"
                          :key="j"
                        >
                          <p >
                            {{ notaLlamada.nota }}
                          </p>
                        </div>
                        <hr />
                      </div>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item class="pt-0">
                  <v-card class="elevation-0">
                    <v-card-title primary-title>
                      Clasificar al prospecto
                      <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                      <v-radio-group
                        v-model="clasificacion"
                        row
                      >
                        <v-radio
                          label="Bueno"
                          :value="1"
                        ></v-radio>
                        <v-radio
                          label="Malo"
                          :value="2"
                        ></v-radio>
                      </v-radio-group>

                      <v-textarea
                        v-model="justificacion"
                        hide-details
                        label="Motivo"
                        dense
                        filled
                      >
                      </v-textarea>

                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" block dense rounded @click="actualizarClasificacion( )">Guardar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item class="pt-0">
                  <v-row class="mt-4">
                    <!-- Pregunta #1 -->
                    <v-col cols="12">

                      <embed :src="prospecto.diploma" type="application/pdf" width="100%" height="600px" v-if="prospecto.diploma"/>

                      <v-alert
                        v-else
                        dense
                        outlined
                        type="error"
                      >
                        El prospecto no cuenta con examen o bien, no lo ha realizado
                      </v-alert>

                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Boton para agregar mensajes al seguimiento -->
        <v-btn
          @click="abrirDialogNotaLlamada()"
          fixed
          bottom
          right
          fab
          dark
          color="primary"
          class="mb-12"
          v-if="estatusLlamada"
        >
          <v-icon >mdi-message</v-icon>
        </v-btn>

        <!-- Boton de seguimiento -->
        <v-btn
          @click="iniciarSeguimiento(), dialoguNevaTarea = false"
          fixed
          bottom
          right
          dark
          :color="getEstatusLlamada == 1 ? 'red' : 'green' "
          v-if="!estatusLlamada"
        >
          <span class="text-capitalize" >Iniciar</span>
        </v-btn>

        <!-- Finalizar seguimiento -->
        <v-btn
          v-else
          @click="finalizarLlamada()"
          fixed
          bottom
          right
          dark
          :color="getEstatusLlamada == 1 ? 'red' : 'green' "
        >
          <span class="text-capitalize" >Finalizar</span>
        </v-btn>

        <!-- Boton para poder ver el tiempo transcurrido -->
        <v-btn
          @click="abrirDialogNotaLlamada()"
          fixed
          top
          right
          fab
          dark
          :color="alertaTiempo ? 'red' : 'primary' "
          style="margin-top: 100px;"
          v-if="showTimer"
        >
          {{ tiempo }}
        </v-btn>

        <!-- Dialog de carga -->
        <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
        <carga                v-if="cargar"/>

      </v-card>
    </v-dialog>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogSuccess"
      hide-overlay
      max-width="400"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="" style="display: table-cell; vertical-align: middle;">
                  <strong>¡Bien!</strong> Datos grabados correctamente
                  <v-card-actions class="pt-6">
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-btn color="green" dark class="elevation-6" large @click="dialogSuccess = false">Ok</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-img src="@/assets/correcto.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de seguimiento dialogSeguimiento -->
    <v-dialog
      v-model="dialogSeguimiento"
      persistent
      max-width="800"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between">

            <v-col cols="12" sm="4" lg="5">
              <v-card class="elevation-0">
                <v-img src="@/assets/primerContacto.png">
                </v-img>
              </v-card>
            </v-col>

            <v-col cols="12" sm="8" lg="6">
              <v-card class="elevation-0 transparent" height="100%" style="display: table;" align="center">
                <div style="display: table-cell; vertical-align: middle; width: 100%;">
                  <div class="text-subtitle-1">
                    <strong class="text-h6" v-if="intentoLlamada == 0">Empecemos....</strong><br/> 
                    <br/>
                    <!-- Aquí validamos si la segunda llamada y el whatsapp están des habilidatos -->
                    <strong class="text-h6" v-if="intentoLlamada == 1">Realizar llamada por teléfono</strong>
                    <!-- Aquí validamos si la primer llamada  y el whatsapp están des habilidatos -->
                    <strong class="text-h6" v-if="intentoLlamada == 2">Realizar llamada por whatsApp</strong>
                    <!-- Aquí validamos si la primer llamada  y el whatsapp están des habilidatos -->
                    <strong class="text-h6" v-if="intentoLlamada == 3">Por favor, envía un mensaje de WhatsApp</strong>
                  </div>

                  <!-- Botones iniciales de primer llamada -->
                  <v-card-actions class="pt-6" v-if="intentoLlamada == 0">
                    <v-spacer></v-spacer>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" dar large class="mr-2 text-capitalize" @click="intentoLlamada++">Iniciar Llamada</v-btn>
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-tooltip
                      v-model="showTooltipTelefono"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="copiarTelefono(prospecto.telefono), showTooltipTelefono = !showTooltipTelefono">Copiar teléfono</v-btn>
                      </template>
                      <span>Teléfono copiado</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                  </v-card-actions>

                  <!-- si el intento de llamada es 1 o 2, se puede visualizar esta sección -->
                  <span v-if="intentoLlamada == 1 || intentoLlamada == 2" class="pt-6">
                    <br/>
                    <!-- Este titulo solo debe aparecer en las primeras dos llamadas -->
                    <div class="text-title" v-if="intentoLlamada < 3"><strong>¿Contestó y esta disponible para atenderte?</strong></div>
                    <!-- Este también solo debe aparecer en las primeras dos llamadas -->
                    <v-card-actions  class="pt-6" v-if="intentoLlamada < 3">
                      <v-spacer></v-spacer>
                      <!-- Si es si, cerramos este dialogo y abrimos el dialogo de info del usuario -->
                      <v-btn
                        color="error"
                        @click="dialogSeguimiento = false, updateEstatusLlamada(), addIntentoLlamada(11)"
                        class="text-capitalize"
                        v-if="intentoLlamada < 3 && intentoLlamada == 1"
                      >
                        Si
                      </v-btn>

                      <v-btn
                        color="error"
                        @click="dialogSeguimiento = false, updateEstatusLlamada(), addIntentoLlamada(12)"
                        class="text-capitalize"
                        v-if="intentoLlamada < 3 && intentoLlamada == 2"
                      >
                        Si
                      </v-btn>


                      <v-spacer></v-spacer>

                      <!-- No contesto llamada, llamar por whatsApp -->
                      <v-btn
                        color="#5C5C5C"
                        dark
                        @click="intentoLlamada++" 
                        v-if="intentoLlamada == 1"
                        class="text-capitalize"
                      >
                        No
                      </v-btn>

                      <!-- No contesto por Llamada de WhatsApp, mandar mensaje de WhatsApp -->
                      <v-btn
                        color="#5C5C5C"
                        dark
                        @click="intentoLlamada++"
                        v-if="intentoLlamada == 2"
                        class="text-capitalize"
                      >
                        No
                      </v-btn>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                    
                    <!-- Boton para copiar el teléfono, solo estará activo si es primerLlamada o segunda -->
                    <v-card-actions v-if="intentoLlamada > 0">
                      <!-- Guardar la información  -->
                      <v-tooltip
                        v-model="showTooltipTelefono"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn block color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="copiarTelefono(prospecto.telefono), showTooltipTelefono = !showTooltipTelefono">Copiar teléfono</v-btn>
                        </template>
                        <span>Teléfono copiado</span>
                      </v-tooltip>
                    </v-card-actions>
                  </span>

                  <!-- Mensaje de whatsApp -->
                  <span v-if="intentoLlamada == 3">
                    <!-- Abrir campo para whatsapp -->
                    <v-card-text>
                      <v-textarea
                        v-model="mensajeWhatsapp"
                        outlined
                        dense
                        rows="3"
                        hide-details
                        label="Escribir mensaje"
                      >
                      </v-textarea>
                    </v-card-text>

                    <!-- Botondes de WhatsApp -->
                    <v-card-actions v-if="mensajeWhatsapp != ''">
                      <v-spacer></v-spacer>
                      <!-- Enviar mensaje -->
                      <v-btn color="primary" 
                        @click="enviarWhatsApp()" 
                      >Enviar</v-btn>
                    </v-card-actions>
                  </span>

                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn 
          absolute
          fab
          text
          small
          top
          right
          class="mt-6"
          v-if="intentoLlamada < 3"
          @click="dialogSeguimiento = false, intentoLlamada = 0"
        ><v-icon>mdi-close</v-icon></v-btn>

        <v-btn 
          absolute
          fab
          text
          small
          top
          left
          class="mt-6"
          v-if="intentoLlamada > 0"
          @click="intentoLlamada--"
        ><v-icon>mdi-arrow-left-bold</v-icon></v-btn>

        <!-- Dialog de carga -->
        <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
        <carga                v-if="cargar"/>

      </v-card>
    </v-dialog>

    <!-- Dialog para tareas programadas -->
    <v-dialog
      v-model="dialogTareasProgramadas"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-h5">
          <span>Tareas pendientes</span>
        </v-card-title>
        <!-- Prospecto con pendientes -->
        <v-card-text>
          <div v-for="(tarea, i) in tareas_programadas" :key="i">
            <div class="text-body-1">
              <v-icon :color="tarea.cumplida == 1 ? 'red' : 'green'">mdi mdi-circle-medium theme--light</v-icon>
              {{ tarea.motivo }}<br/>
              <span v-if="tarea.idmotivos == 7">{{ tarea.descrip_motivo }}</span>
            </div>
            <div class="text-caption">{{ tarea.fecha + ' ' + tarea.tiempo }}</div>

            <!-- Tareas programadas -->
            <v-chip-group column>

              <!-- Iniciar llamada -->
              <v-tooltip v-if="tarea.tarea_tipo == 1" top>
                <!-- Este es llamada por teléfono, entonces, lo que haremos es abrir el dialogo de seguimiento, cerrar este dialogo yyyy abrirlo directamente en llamada -->
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogTareasProgramadas = false, intentoLlamada = 1, dialogSeguimiento = true"  
                    rounded
                    small><v-icon>mdi-phone</v-icon>
                  </v-chip>
                </template>
                <span>Click para inciar</span>
              </v-tooltip>

              <!-- Mensaje de whatsapp -->
              <v-tooltip v-if="tarea.tarea_tipo == 2" top>
                <!-- Este es llamada por teléfono, entonces, lo que haremos es abrir el dialogo de seguimiento, cerrar este dialogo yyyy abrirlo directamente en whatsApp -->
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="green"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogTareasProgramadas = false, intentoLlamada = 3, dialogSeguimiento = true"  
                    rounded
                    small><v-icon>mdi-whatsapp</v-icon>
                  </v-chip>
                </template>
                <span>Enviar mensaje</span>
              </v-tooltip>

              <!-- Eliminar -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="error"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="eliminarTarea(tarea.idtareas_prospectos)" 
                    rounded
                    small><v-icon>mdi-delete</v-icon>
                  </v-chip>
                </template>
                <span>Eliminar tarea</span>
              </v-tooltip>

              <!-- Reprogramar -->
              <v-tooltip top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip color="warning"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="actualizarTarea(tarea),disableBtnTarea = false" 
                    rounded
                    small><v-icon>mdi-refresh</v-icon>
                  </v-chip>
                </template>
                <span>Reprogramar</span>
              </v-tooltip>
            </v-chip-group>
          </div>
        </v-card-text>

        <v-btn 
          absolute
          fab
          text
          small
          top
          right
          class="mt-6"
          @click="dialogTareasProgramadas = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </v-card>
    </v-dialog>

    <!-- Dialog para agregar notas a la llamada -->
    <v-dialog
      v-model="dialogNotaLlamada"
      hide-overlay
      persistent
      max-width="800"
      max-height="450"
    >
      <v-card>
        <v-card-title primary-title >
          Notas de la llamada
        </v-card-title>
        <v-card-text style="overflow-y: scroll;position: relative; max-height: 350px;" id="notas">
          <span v-for="(mensaje, i) in notasLlamada">
            <v-row v-if="mensaje.idusuarioerp != getdatosUsuario.iderp">
              <v-col cols="12">
                <v-card class="ml-6 pa-3 mt-2" max-width="400" color="#f2f2f2" style="border-radius: 15px; border-top-left-radius: 0">
                  <div class="text-subtitle-1">{{ mensaje.nota }} </div>
                  <v-card-actions class="pa-0">
                    <div class="text-caption blue--text">{{ mensaje.nombre_completo }}</div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="mensaje.idusuarioerp == getdatosUsuario.iderp">
              <v-col cols="12" align="right">
                <v-card class="mr-6 mt-2 pa-3" max-width="400" dark color="#0465ac" shaped style="border-radius: 15px; border-top-right-radius: 0;">
                  <div class="text-subtitle-1">{{ mensaje.nota }}</div>
                </v-card>
              </v-col>
            </v-row>
          </span>
        </v-card-text>

        <v-card-text class="pb-0 pt-6">
          <v-text-field
            v-model="notaLlamada"
            :append-outer-icon="notaLlamada ? 'mdi-send' : ''"
            outlined
            clearable
            label="Message"
            type="text"
            @click:append-outer="grabarNotaLlamada()"
            hide-details
            dense
            class="pb-4"
          ></v-text-field>
        </v-card-text>
        
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="notaLlamada='', dialogNotaLlamada = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog de Finalización -->
    <v-dialog
      v-model="dialogFinalizacion"
      hide-overlay
      persistent
      max-width="800"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between" v-if="!banderaFinalizo && !banderaRechazo">
            <v-col cols="12" lg="6">
              <v-img src="@/assets/finalizo.png">
              </v-img>
            </v-col>
            <v-col cols="12" lg="6">
              <v-card class="elevation-0" height="100%" style="display: table;" align="center">
                <div style="display: table-cell; vertical-align: middle;">
                  <div class="text-subtitle-1"><strong class="text-h6">Que no cunda el pánico</strong><br/>Solo se finalizaran los prospectos que no son alumnos!</div>
                  <v-card-actions class="pt-6">
                    <v-spacer></v-spacer>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="black" tile dark large class="mr-2 text-capitalize" @click="banderaRechazo = true">Finalizar/Rechazar</v-btn>
                    <!-- Guardar la información  -->
                    <!-- <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="banderaFinalizo = true">Sí</v-btn> -->
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!-- Finalizar, todo Ok -->
          <v-card-text v-if="banderaFinalizo">
            <div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="Matricula"
                  dense
                  outlined
                  v-model="matricula"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6">
                <v-autocomplete
                  :items="grupos"
                  v-model="grupo"
                  label="Grupo"
                  dense
                  outlined
                  hide-details
                  item-value="idgrupo"
                  item-text="grupo"
                  return-object
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field
                  label="Código de la clase"
                  dense
                  outlined
                  v-model="codigo_clase"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field
                  label="Contraseña"
                  dense
                  outlined
                  v-model="contra"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions v-if="banderaFinalizo">
            <v-spacer></v-spacer>
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaFinalizo = false">Cancelar</v-btn>
            <!-- Guardar la información  -->
            <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="finalizarProspecto()">Guardar</v-btn>
          </v-card-actions>

          <!-- Rechazar con motivo -->
          <v-card-text v-if="banderaRechazo">
            <div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
            <v-textarea
              v-model="notaRechazo"
              label="Motivo"
              dense
              outlined
              hide-details
            ></v-textarea>
          </v-card-text>
          <v-card-actions v-if="banderaRechazo">
            <v-spacer></v-spacer>
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaRechazo = false">Cancelar</v-btn>
            <!-- Guardar la información  -->
            <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="rechazoProspecto()">Guardar</v-btn>
          </v-card-actions>
        </v-card-text>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogFinalizacion = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog de Finalización del Familiar -->
    <v-dialog
      v-model="dialogFinalizarFamiliar"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between" v-if="!banderaFinalizo && !banderaRechazo">
            <v-col cols="12" lg="5">
              <v-img src="@/assets/finalizo.png">
              </v-img>
            </v-col>
            <v-col cols="12" lg="7">
              <v-card class="elevation-0" height="100%" style="display: table;" align="center">
                <div style="display: table-cell; vertical-align: middle;">
                  <div class="text-subtitle-1"><strong class="text-h6">Que no cunda el pánico</strong><br/> ¿El prospecto se registró en algún grupo?</div>
                  <v-card-actions class="pt-6">
                    <v-spacer></v-spacer>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaRechazo = true">No, finalizar</v-btn>
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="banderaFinalizo = true">Sí</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <!-- Finalizar, todo Ok -->
          <v-card-text v-if="banderaFinalizo">
            <div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field
                  label="Matricula"
                  dense
                  outlined
                  v-model="matricula"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6">
                <v-autocomplete
                  :items="grupos"
                  v-model="grupo"
                  label="Grupo"
                  dense
                  outlined
                  hide-details
                  item-value="idgrupo"
                  item-text="grupo"
                  return-object
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field
                  label="Código de la clase"
                  dense
                  outlined
                  v-model="codigo_clase"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field
                  label="Contraseña"
                  dense
                  outlined
                  v-model="contra"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions v-if="banderaFinalizo">
            <v-spacer></v-spacer>
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaFinalizo = false">Cancelar</v-btn>
            <!-- Guardar la información  -->
            <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="finalizarProspectoFamiliar()">Guardar</v-btn>
          </v-card-actions>

          <!-- Rechazar con motivo -->
          <v-card-text v-if="banderaRechazo">
            <div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
            <v-textarea
              v-model="notaRechazo"
              label="Motivo"
              dense
              outlined
              hide-details
            ></v-textarea>
          </v-card-text>
          <v-card-actions v-if="banderaRechazo">
            <v-spacer></v-spacer>
            <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
            <v-btn color="error" text large class="mr-2 text-capitalize" @click="banderaRechazo = false">Cancelar</v-btn>
            <!-- Guardar la información  -->
            <v-btn color="#5C5C5C" dark class="elevation-6 text-capitalize" large @click="rechazoProspectoFamiliar()">Guardar</v-btn>
          </v-card-actions>
        </v-card-text>
        <v-btn 
          color="transparent elevation-0"
          x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogFinalizarFamiliar = false, familiarAFinalizar = null, banderaRechazo = false,banderaFinalizo = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialgo whatsapp dialogWhatsapp -->
    <v-dialog
      v-model="dialogWhatsapp"
      hide-overlay
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title primary-title>
          Mandar mensaje de whatsApp
        </v-card-title>
        <v-card-text>
        <v-textarea
          v-model="mensajeWhatsapp"
          outlined
          dense
          rows="3"
          hide-details
          label="Escribir mensaje"
        >
        </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogWhatsapp = false">Cancelar</v-btn>
          <v-btn color="primary"   @click="dialogWhatsapp = false, dialogSeguimiento = false, enviarWhatsApp(), dialoguNevaTarea = true" v-if="mensajeWhatsapp != ''">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog info del usuario dialogInfoUsuario -->
    <v-dialog
      v-model="dialogInfoUsuario"
      persistent
      max-width="800"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between">
            <v-col cols="12">
              <v-stepper
                v-model="pasosInfoUsuario"
                vertical
                class="elevation-0"
              >
                <v-stepper-step
                  :complete="pasosInfoUsuario > 1"
                  step="1"
                >
                  Perfil
                  <small>Realiza las siguientes preguntas</small>
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-card class="mb-12 elevation-0">
                    <v-row class="mt-4">
                      <!-- Pregunta #1 -->
                      <v-col cols="12">
                        <div>¿El curso es para ti, o para alguien más?</div>
                        <v-radio-group 
                          v-model="paraquien_el_curso" 
                          row
                          hide-details
                          class="mt-0"
                        >
                          <v-radio :value="1" row>
                            <template v-slot:label>
                              <div>Para mi</div>
                            </template>
                          </v-radio>

                          <v-radio :value="0">
                            <template v-slot:label>
                              <div>Alguién más</div> 
                            </template>
                          </v-radio>

                          <v-radio :value="2">
                            <template v-slot:label>
                              <div>Hermanos</div> 
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>

                      <!-- Agregar familiares -->
                      <v-col cols="12" v-if="showFamiliares">
                        <div><v-btn color="primary" class="text-capitalize" @click="addPersonas = true">Agregar personas</v-btn></div>
                        <div v-if="addPersonas" class="pt-6">
                          <v-card>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" md="6" lg="7">
                                  <v-text-field
                                    label="Nombre completo"
                                    dense
                                    v-model="familiar"
                                    outlined
                                    hide-details
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="5" lg="4">
                                  <v-text-field
                                    label="Edad"
                                    dense
                                    v-model="edadFamiliar"
                                    outlined
                                    hide-details
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Correo"
                                    dense
                                    v-model="correoFamiliar"
                                    outlined
                                    hide-details
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Teléfono"
                                    dense
                                    v-model="telefonoFamiliar"
                                    outlined
                                    hide-details
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="success" fab small @click="addFamiliar()"><v-icon>mdi-check</v-icon></v-btn>
                            </v-card-actions>
                          </v-card>
                        </div>
                        <div class="pt-2"><small>Persoas añadidas</small></div>
                        <div v-if="familiares.length">
                          <v-data-table
                            :headers="headersFamiliares"
                            :items="familiares"
                            class="elevation-1"
                            fixed-header
                            hide-default-footer
                            :items-per-page="familiares.length"
                          >
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                small
                                @click="deletFamiliar(item)"
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </div>
                      </v-col>

                      <!-- Pregunta #2 -->
                      <v-col cols="12">
                        <div>¿Anteriormente ha estudiado inglés en alguna otra escuela o colegio?</div>
                        <v-radio-group 
                          v-model="estudioIngles" 
                          row
                          hide-details
                          class="mt-0"
                        >
                          <v-radio :value="1" row>
                            <template v-slot:label>
                              <div>Si</div>
                            </template>
                          </v-radio>
                          <v-radio :value="0">
                            <template v-slot:label>
                              <div>No</div> 
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>

                      <!-- Pregunta #3 -->
                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="['Acádemico','Trabajo','Ocio']"
                          v-model="porqueEstudiarIngles"
                          label="¿Por qué estudiar inglés?"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <!-- pregunta #4 -->
                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="['Mañana','Tarde','Noche']"
                          v-model="dispoHorario"
                          label="Disponibilidad horario"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <!-- pregunta #5 -->
                      <v-col cols="12" sm="6" lg="4">
                        <v-text-field
                          v-model="edad"
                          dense
                          hide-details
                          type="number"
                          label="Edad"
                          clearable
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="filterSucursales"
                          v-model="sucursal"
                          return-object
                          item-text="plantel"
                          item-value="idplantel"
                          label="Sucursal"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="anuncios"
                          v-model="anuncio"
                          return-object
                          item-text="anuncio"
                          item-value="idanuncios"
                          label="¿Cómo nos conocio?"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="cursos"
                          v-model="curso"
                          return-object
                          item-text="curso"
                          item-value="idcursos_escuela"
                          label="Curso"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="modalidadesEscuela"
                          v-model="modalidad"
                          return-object
                          item-text="modalidad"
                          item-value="idmodalidad_cursos"
                          label="Modalidad"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="frecuenciasEscuela"
                          v-model="frecuencia"
                          return-object
                          item-text="frecuencia"
                          item-value="idfrecuencias"
                          label="Frecuencia"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          :items="ciclos_plantel"
                          v-model="ciclo"
                          return-object
                          item-text="ciclo"
                          item-value="id_ciclo"
                          label="Ciclo interes"
                          hide-details
                          dense
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" lg="4">
                        <v-select
                          disabled
                          :items="vendedoras_plantel"
                          v-model="vendedora"
                          return-object
                          item-text="nombre"
                          item-value="iderp"
                          label="Vendedora"
                          hide-details
                          dense
                          readonly
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          v-model="notas"
                          dense
                          rows="3"
                          label="comentarios"
                          outlined
                          hide-details
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12" class="ml-2">
                        <v-checkbox label="El prospecto no brindo información" v-model="prospectoNoDioInfo"></v-checkbox>
                      </v-col>

                      <!-- <v-col cols="12">
                        <v-btn 
                          color="primary" 
                          block 
                          class="text-capitalize"
                          @click="updatePerfil()"
                        >
                          Actualizar Perfil
                        </v-btn>
                      </v-col> -->
                    </v-row>

                    <v-card-actions class="pl-0 pt-4">
                      <v-btn
                        color="grey"
                        dark
                        class="text-capitalize"
                        @click="dialogInfoUsuario = false, dialogSeguimiento = false, intentoLlamada = 0"
                      >
                        Cancelar
                      </v-btn>

                      <v-btn
                        color="red"
                        dark
                        class="text-capitalize"
                        @click="pasosInfoUsuario = 2, updatePerfil()"
                      >
                        Siguiente
                      </v-btn>
                    </v-card-actions> 
                  </v-card>
                </v-stepper-content>

                <v-stepper-step
                  :complete="pasosInfoUsuario > 2"
                  step="2"
                >
                  Información del curso
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-card class="mb-12 elevation-0" v-if="getEscuela == 2">
                    <div v-for="(puntos, i ) in puntosLlamada" :key="i" class="mt-4">
                      <!-- TITULOS --> 
                      <span ><strong>{{ puntos.titulo }}</strong></span>
                      <!-- Ahora ponemos los subtemas -->
                      <div 
                        v-for="(subtema, j) in puntos.subtemas"
                        :key="j"
                      >
                        <v-checkbox
                          v-model="subtema.check"
                          :label="subtema.titulo"
                          hide-details
                          class="ml-4"
                        >
                        </v-checkbox>
                        <div v-if="subtema.subsubtemas" v-for="(subsub, l ) in subtema.subsubtemas" :key="l" class="mt-2 ml-12">
                          <!-- TITULOS --> 
                          <span >{{ subsub.titulo }}</span>
                        </div>
                        
                      </div>
                    </div>
                    <v-card-actions class="mt-6">
                      <v-btn
                        color="red"
                        dark
                        @click="grabarInfoEscuela()"
                      >

                        Grabar información
                      </v-btn>
                      <v-btn text @click="pasosInfoUsuario = 1" class="ml-4">
                        Regresar
                      </v-btn>
                    </v-card-actions>
                  </v-card>

                  <v-card class="mb-12 elevation-0" v-else>
                    <v-radio-group v-model="tipoInfoCurso" row>
                      <v-radio label="Intensivo" :value="1"></v-radio>
                      <v-radio label="Online" :value="2"></v-radio>
                      <v-radio label="Teens" :value="3"></v-radio>

                      <v-radio label="Clases apoyo" :value="4"></v-radio>

                      <v-radio label="KIDS" :value="5"></v-radio>
                    </v-radio-group>

                    <div v-for="(puntos, i ) in puntosLlamada" :key="i" class="mt-4">
                      <!-- Titulos -->
                      <v-checkbox
                        v-model="puntos.check"
                        :label="puntos.titulo"
                        hide-details
                        class="text-body-1"
                      >
                      </v-checkbox>

                      <!-- Ahora ponemos los subtemas -->
                      <div 
                        v-for="(subtema, j) in puntos.subtemas"
                        :key="j"
                        class="ml-6"
                      >
                        <v-icon color="primary"> mdi mdi-circle-medium theme--light </v-icon>{{ subtema.titulo }}

                        <div v-if="subtema.subsubtemas" v-for="(subsub, l ) in subtema.subsubtemas" :key="l" class="mt-2 ml-8">
                          <span ><v-icon color="error"> mdi mdi-circle-medium theme--light </v-icon>{{ subsub.titulo }}</span>
                        </div>
                      </div>
                    </div>
                    <v-card-actions class="mt-6">
                      <v-btn color="red" dark @click="grabarInfoEscuela()">
                        Grabar información
                      </v-btn>
                      <v-btn text @click="pasosInfoUsuario = 1" class="ml-4">
                        Regresar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

              </v-stepper>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de cierre -->
    <v-dialog
      v-model="dialogCierre"
      persistent
      max-width="800"
    >
      <v-card class="pt-4">
        <v-card-text>
          <v-row justify="space-between">
            <!-- Imagen -->
             <v-col cols="12" sm="4" md="4" lg="5">
              <v-card class="elevation-0">
                <v-img src="@/assets/notevayas.png">
                </v-img>
              </v-card>
            </v-col>

            <!-- Detalles -->
            <v-col cols="12" sm="8" md="7" lg="6">
              <v-card class="elevation-0 transparent" height="100%" style="display: table;" align="center">
                <div style="display: table-cell; vertical-align: middle; width: 100%;">
                  <div class="text-subtitle-1" v-if="opCierre == 0">
                    <strong class="text-h6">NO TE VAYASSSS</strong><br/> 
                    a olvidar de eligir una opción 🤪
                  </div>

                  <!-- opciones -->
                  <v-card-actions class="pt-6" v-if="opCierre == 0">
                    <v-spacer></v-spacer>
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" dar large class="mr-2 text-capitalize" @click="opCierre = 1, disableBtnTarea = false" >Nueva Tarea</v-btn>
                    <v-spacer></v-spacer>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C" dark class="text-capitalize" large @click="opCierre = 2, getGrupos()">Finalizar prospecto</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>

                  <!-- Se agendará un atarea -->
                  <div v-if="opCierre == 1">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" lg="6">
                          <v-select
                            :items="tareas"
                            v-model="tarea"
                            label="Tarea"
                            dense
                            hide-details
                            item-value="idtareas"
                            item-text="tarea"
                            return-object
                          ></v-select>
                        </v-col>

                        <v-col cols="12"  md="6">
                          <v-text-field
                            v-model="dia"
                            label="Día"
                            dense
                            type="date"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select
                            :items="[{id: 1, tipo:'Agendada'},{id: 2, tipo:'Nota'}]"
                            v-model="tipo_tarea"
                            label="Tipo de tarea"
                            dense
                            hide-details
                            item-value="id"
                            item-text="tipo"
                            return-object
                          ></v-select>
                        </v-col>

                        <v-col cols="12"  md="6" v-if="tipo_tarea && tipo_tarea.id == 2">
                          <v-select
                            :items="horaPrecargada"
                            v-model="horaCargada"
                            label="Hora"
                            dense
                            hide-details
                            item-value="id"
                            item-text="hora"
                            return-object
                            clearable
                          ></v-select>
                        </v-col>

                        <v-col cols="12"  md="6" v-if="horaCargada && horaCargada.id == 5 || tipo_tarea && tipo_tarea.id == 1">
                          <v-text-field
                            v-model="hora"
                            label="Hora"
                            dense
                            type="time"
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-select
                            :items="motivos"
                            v-model="motivo"
                            label="Motivo"
                            dense
                            hide-details
                            item-value="idmotivos"
                            item-text="motivo"
                            return-object
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="6" v-if="motivo && motivo.motivo == 'Otro'">
                          <v-text-field
                            v-model="descrip_motivo"
                            label="Descripción"
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12">
                          <!-- :disabled="disableBtnTarea" -->
                          <v-btn color="primary" block @click="programarTarea()"   v-if="banderaTarea == 0 && motivo && tipo_tarea">Promagar tarea</v-btn>
                          <v-btn color="primary" block @click="reprogramarTarea()" v-if="banderaTarea == 1">Reprogramar tarea</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </div>

                  <!-- hay que ver si se registro o no a un grupo -->
                  <div v-if="opCierre == 2">
                    <!-- Validar que el prospecto haya finalizado correctamente -->
                    <div class="text-subtitle-1 mt-6" v-if="!banderaFinalizo && !banderaRechazo">¿El prospecto se registró en algún grupo?</div>
                    <!-- Botones de si, no, cancelar -->
                    <v-card-actions class="pt-6" v-if="!banderaFinalizo && !banderaRechazo">
                      <!-- Guardar la información  -->
                      <v-btn color="error" dark class="text-capitalize" @click="banderaFinalizo = true, getGrupos()">Sí</v-btn>
                      <v-spacer></v-spacer>
                      <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                      <v-btn color="error"   dark class="text-capitalize" @click="banderaRechazo = true" text>No, finalizar</v-btn>
                      <v-spacer></v-spacer>
                      <!-- Cancelar -->
                      <v-btn color="#5C5C5C"   dark class="text-capitalize" @click="opCierre = 0">Cancelar</v-btn>
                    </v-card-actions>

                    <!-- Finalizó y se inscribió -->
                    <div v-if="banderaFinalizo">
                      <v-card-text >
                        <div class="text-body-1 mb-2" align="center">Ingresa los datos del alumno </div>
                        <v-text-field
                          label="Matricula"
                          dense
                          outlined
                          v-model="matricula"
                        ></v-text-field>

                        <v-autocomplete
                          :items="grupos"
                          v-model="grupo"
                          label="Grupo"
                          dense
                          outlined
                          item-value="idgrupo"
                          item-text="grupo"
                          return-object
                        ></v-autocomplete>

                        <v-text-field
                          label="Código de la clase"
                          dense
                          outlined
                          v-model="codigo_clase"
                        ></v-text-field>

                        <v-text-field
                          label="Contraseña"
                          dense
                          outlined
                          v-model="contra"
                        ></v-text-field>
                      </v-card-text>

                      <v-card-actions >
                        <v-spacer></v-spacer>
                        <!-- Guardar la información  -->
                        <v-btn color="#5C5C5C" dark class="text-capitalize"  @click="finalizarProspecto()">Guardar</v-btn>
                        <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                        <v-btn color="error" class="text-capitalize" @click="banderaFinalizo = false, grupo = null, matricula = ''">Cancelar</v-btn>
                      </v-card-actions>
                    </div>

                    <!-- Rechazar con motivo -->
                    <div v-if="banderaRechazo">
                      <v-textarea
                        v-model="notaRechazo"
                        label="¿Por qué no se inscribió?"
                        dense
                        outlined
                        hide-details
                      ></v-textarea>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- Guardar la información  -->
                        <v-btn color="#5C5C5C" dark class="text-capitalize"  @click="rechazoProspecto()">Guardar</v-btn>
                        <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                        <v-btn color="error" class="text-capitalize" @click="banderaRechazo = false, notaRechazo = ''">Cancelar</v-btn>
                      </v-card-actions>
                    </div>
                  </div>

                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn 
          absolute
          fab
          text
          small
          top
          right
          class="mt-6"
          @click="cerrarDialogCierre()"
        ><v-icon>mdi-close</v-icon></v-btn>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <!-- Filtro de fechas -->
    <DialogFecha          v-if="fechas.estatus"              :fechas="fechas"/>

    <!-- Eliminar una tarea -->
    <DialogDeleteTarea    
      v-if="paramDeleteTarea.estatus"    
      :parametros="paramDeleteTarea" 
      @click="deleteItemConfirm"
    />
    
    <!-- Reasginar prospecto -->
    <DialogReasignar      
      v-if="paramReasignar.estatus"    
      :parametros="paramReasignar"
      :vendedoras="filterVendedoras" 
      @click="reasignar"
    />

    <!-- Dialog cerrados y/o finalizados -->
    <DialogCerrados
      v-if="paramCerrados.estatus"    
      :paramCerrados="paramCerrados"
      @initialize="initialize"
    /> 

    <!-- Dialog buenos -->
    <DialogBuenos      
      v-if="paramBuenos.estatus"    
      :parametros="paramBuenos"
      :buenos="buenos"
    />

    <!-- Dialog Malos -->
    <DialogMalos      
      v-if="paramMalos.estatus"    
      :parametros="paramMalos"
      :malos="malos"
    />

    <!-- Dialog resagados -->
    <DialogResagados
      v-if="paramResagados.estatus"    
      :paramResagados="paramResagados"
      :prospectosBasura="prospectosBasura"
      @initialize="initialize"
    />


    <!-- Dialog resagados -->
    <CalidadProspecto
      v-if="dialogCalidadProspectos.estatus"
      :dialogCalidadProspectos="dialogCalidadProspectos"
    />

    <!-- Dialog estatus -->
    <ProspectosEstatus
      v-if="dialogProspectosProspectos.estatus"
      :dialogProspectosProspectos="dialogProspectosProspectos"
      :prospectosSeguimiento="prospectosSeguimiento"
      :vendedoras_plantel="vendedoras_plantel"
      :prospectosAgendadaInduccion="prospectosAgendadaInduccion"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>


    <Calendario v-if="dialogCalendario.estatus" :dialogCalendario="dialogCalendario" :prospecto="prospecto"/>


  </v-container>
</template>

<script>
  import axios from 'axios';
  import { infoFast }                from '@/informativos/infoFastOnline'
  import { headerAdmin, headerVend } from '@/views/crm-prospectos/prospectos/headerTablas'
  import { intensivoInbi }           from '@/informativos/intensivoInbi'
  import { onlineInbi }              from '@/informativos/onlineInbi'
  import { teensInbi }               from '@/informativos/teensInbi'
  import { clasesApoyo }             from '@/informativos/clasesApoyo'
  import { infoKIDS }                from '@/informativos/infoKIDS'
  import { mapGetters, mapActions }  from 'vuex';

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import Eventos               from '@/components/prospectos/Eventos.vue';
  import DatosPersonales       from '@/components/prospectos/DatosPersonales.vue';
  import Etapas                from '@/components/prospectos/Etapas.vue';
  import DetalleEvento         from '@/components/prospectos/DetalleEvento.vue';
  import Comentario            from '@/components/prospectos/Comentarios.vue';
  import BotonesAccion         from '@/components/prospectos/BotonesAccion.vue';
  import DialogFecha           from '@/components/prospectos/DialogFecha.vue';
  import DialogDeleteTarea     from '@/components/prospectos/DialogDeleteTarea.vue';
  import DialogReasignar       from '@/components/prospectos/DialogReasignar.vue';
  import DialogMalos           from '@/components/prospectos/DialogMalos.vue';
  import DialogBuenos          from '@/components/prospectos/DialogBuenos.vue';
  import DialogCerrados        from '@/components/prospectos/DialogCerrados.vue';
  import DialogResagados       from '@/components/prospectos/DialogResagados.vue';
  import CalidadProspecto      from '@/components/prospectos/CalidadProspecto.vue';
  import ProspectosEstatus     from '@/components/prospectos/ProspectosEstatus.vue';
  import HeaderTotales         from '@/components/prospectos/HeaderTotales.vue';

  import Calendario            from '@/components/Calendario.vue';

  // Mixins
  import funciones       from '@/mixins/funcionesProspectos'
  import catalagos       from '@/mixins/catalagos'
  import funcionesExcel  from '@/mixins/funcionesExcel'
  import cronometro      from '@/mixins/cronometro'
  import familias        from '@/mixins/familias'
  import reasignar       from '@/mixins/reasignar'
  import validarErrores  from '@/mixins/validarErrores'

  var moment  = require('moment');
  moment.locale(); 

  export default {
    components:{
      Alerta,
      carga,
      DialogFecha,
      DialogDeleteTarea,
      Eventos,
      DatosPersonales,
      Etapas,
      DetalleEvento,
      Comentario,
      BotonesAccion,
      DialogReasignar,
      DialogBuenos,
      DialogMalos,
      DialogCerrados,
      DialogResagados,
      CalidadProspecto,
      HeaderTotales,
      ProspectosEstatus,
      Calendario
    },

    mixins: [funciones,catalagos,funcionesExcel,cronometro,familias,reasignar,validarErrores],

    data: () => ({

      alertaUrlCopy: 'CLICK PARA COPIAR',
      // Componente para filtro de fechas
      fechas: {
        fechaini: null,
        fechafin: null,
        estatus: false
      },

      // Tareas del prospecto
      paramDeleteTarea:{
        estatus: false
      },

      // Reasignar
      paramReasignar:{
        estatus: false
      },

      tab:null,
      loading:false,
      mostrarEventos:false,

      // herramientas visuales
      estatusFiltro:6,

      // Cuadros de dialogo
      dialogTareasProgramadas: false,
      dialogFinalizacion:      false,
      dialogCierre:            false,
      dialogInfoUsuario:       false,
      dialogSeguimiento:       false,  // dialogo para iniciar seguimiento 
      dialogSuccess:           false,
      dialogProspecto:         false,
      dialogError:             false,
      dialogDelete:            false,
      dialoguNevaTarea:        false,
      dialogRezagados:         false,
      dialogCerrados:          false,  //   Angel Rodriguez   --  dialogCerrados
      dialogFinalizarFamiliar: false,
      dialogFiltroFecha      : false,
      dialogBuenos:            false,
      dialogMalos:             false,

      addPersonas:             false,
      familiar:                '',
      edadFamiliar:            0,
      correoFamiliar:          '',
      telefonoFamiliar:        '',
      headersFamiliares:[
        { text: 'Nombre',      value: 'familiar', },
        { text: 'Edad',        value: 'edad', },
        { text: 'Correo',      value: 'correo', },
        { text: 'Teléfono',    value: 'telefono', },
        { text: 'Actions',     value: 'actions', sortable: false },
      ],
      familiares:              [],
      familiarAFinalizar:     null,
      search2:"",                //Angel Rodriguez

      // Tabla
      headers: [],

      // Prospectos
      prospectos:     [], //arreglo
      prospectosAgendadaInduccion:[],
      prospectosSeguimiento:[],
      prospectosConSeguimiento:[],
      prospecto:      null,
      intentoLlamada: 0,
      contactExport:  [],
      medio_informacion: 0,
      prospectoNoDioInfo:0,
      /*
        1-. Atrasado
        2-. Nuevo
        3-. Inducción
        4-. Seg. con Info
        5-. Seg. sin Info
      */
      idetapa:    null, 
      opCierre: 0,
      eventosTimeline:[],
      
      // 
      sucursales:[],
      sucursal:null,
      anuncios:[],
      anuncio:null,
      cursos:[],
      curso:null,
      ciclos:[],
      ciclos_plantel:[],
      ciclo:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      tareas:[],
      tarea:null,
      campanias:[],
      campania:null,
      motivos:[],
      motivo:{ "idmotivos": 7, "motivo": "Otro", "estatus": 1, "deleted": 0, "fecha_creacion": "2022-01-10 19:32:02" },
      descrip_motivo:'',

      // Alertas 
      cargar: false,
      error:        '',
      successMensaje:'',
      errorBack:false,
      textoCopiado:'COPIAR',
      search:'',

      // Estatus de la llamada
      estatusLlamada: false,

      // Programar tarea
      opcionAddTarea: false,
      dia:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      hora:null,
      horaPrecargada:[
        {id: 1, hora:'1 hora'},
        {id: 2, hora:'2 horas'},
        {id: 3, hora:'4 horas'},
        {id: 4, hora:'24 horas'},
        {id: 5, hora:'Otra'},
      ],
      horaCargada:null,
      nota:null,
      tipo_tarea:{id: 1, tipo:'Agendada'},

      tareas_programadas:[],

      tipoDelete: null,
      notaAnterior:'',
      idtareas_prospectos: null,
      eventos:[],
      fechaini:null,
      fechafin:null,
      banderaTarea: 0, // sirve para saber si se esta actualizando la tarea, o agregando una nueva

      // Preguntas
      paraquien_el_curso:null, // Para quien es el curso
      estudioIngles:null, // Estudió inglés
      porqueEstudiarIngles:'Trabajo', // Para que quieres estudiar inglés
      dispoHorario:null, // disponibilidad de horario
      edad:null, //  edad


      dialogNotaLlamada: false,
      notaLlamada:'',
      notasLlamada:[],
      llamadasList:[],
      idestatus_llamada: null,
      
      primerContacto:false,
      nuevaSucursal: null, 

      nombreVendedora:'',

      banderaFinalizo:false,
      matricula:'',
      grupo:null,
      grupos:[],

      banderaRechazo: false,
      notaRechazo:'',
      dialogWhatsapp:false,
      mensajeWhatsapp:'',

      cantProspectosPrimeraEtapa:0,

      prospectoFinalizo: false,
      tareaLlamada:      false,
      
      showTooltipTelefono:   false,
      pasosInfoUsuario: 1,
      puntosLlamada: [],

      modalidades:[],
      modalidad:null,
      frecuencias:[],
      frecuencia: null,
      modalidadesEscuela:[],
      frecuenciasEscuela:[],

      vendedoraFiltro:null,

      tipoInfoCurso: 0,
      
      notas:'',

      datosLlamada: null,
      newComentario:'',
      comentarios:[],

      prospectosBasura:[],
      countWhatsApp:0,

      prospectosCerrados:[],                                 //Angel Rodriguez - prospectosCerrados 
     
      disableBtnTarea: false,
      codigo_clase:'',
      contra:'',

      showFamiliares: false,

      tareas_realizadas:[],
      tabs:[
        {name:'Sin seguimiento',icon:'mdi-account'},
        {name:'Con seguimiento',icon:'mdi-account'},
        {name:'Tarea agendada, inducción',icon:'mdi-account'}
      ],

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      clasificacion: null,
      justificacion:'',

      paramBuenos:{
        estatus: false,
      },
      paramMalos:{
        estatus: false
      },
      paramCerrados:{
        estatus: false
      },
      paramResagados:{
        estatus: false
      },

      dialogCalidadProspectos:{
        estatus: false
      },

      dialogProspectosProspectos:{
        estatus: false
      },

      dialogCalendario:{
        estatus: false 
      },

      planteles:[]
    }),

    watch:{
      nuevaSucursal(){
        this.nombreVendedora = this.nuevaSucursal.nombre
      },

      curso(val){
        this.modalidadesEscuela = this.modalidades.filter( el => {
          if(val){
            return el.idcursos_escuela == val.idcursos_escuela
          }
        })
      },

      modalidad(val){
        this.frecuenciasEscuela = this.frecuencias.filter( el => {
          return el.idmodalidad_cursos == val.idmodalidad_cursos
        })
      },

      tipoInfoCurso(val){
        switch(val){
          case 1: 
            this.puntosLlamada = intensivoInbi
          break;

          case 2: 
            this.puntosLlamada = onlineInbi
          break;

          case 3: 
            this.puntosLlamada = teensInbi
          break;

          case 4: 
            this.puntosLlamada = clasesApoyo
          break;

          case 5: 
            this.puntosLlamada = infoKIDS
          break;

        }
      },
    },

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),

      formTitle() { 
        return this.editedIndex === -1 ? 'Agregar un prospecto' : 'Editar prospecto'
      },

      titulo (){
        return this.getEscuela == 1 ? 'Prospectos INBI' : 'Prospectos FAST'
      },

      filterProspectosSeguimiento(){
        if(this.prospectosConSeguimiento){
          // return this.prospectosConSeguimiento
          return this.prospectosConSeguimiento.filter((prospecto)=>{
            let est = prospecto.idetapa;

            if(this.vendedoraFiltro){
              if(this.vendedoraFiltro.iderp == prospecto.usuario_asignado){
                // Sin fecha seleccionada
                if(!this.fechas.fechaini && !this.fechas.fechafin){
                  if(this.estatusFiltro == 6){
                    return true
                  }else{
                    if(this.estatusFiltro == 3){
                      return est == 3 || est == 4 || est == 5
                    }else{
                      if(est == this.estatusFiltro){return true}
                    }
                  }
                }

                // Solo fecha de inicio seleccionada
                if(this.fechas.fechaini && !this.fechas.fechafin){
                  if( prospecto.fecha == this.fechas.fechaini){
                    if(this.estatusFiltro == 6){
                      return true
                    }else{
                      if(this.estatusFiltro == 3){
                        return est == 3 || est == 4 || est == 5
                      }else{
                        if(est == this.estatusFiltro){return true}
                      }
                    }
                  }
                }

                // Solo fecha de inicio seleccionada
                if(this.fechas.fechaini && this.fechas.fechafin){
                  if(prospecto.fecha >= this.fechas.fechaini && prospecto.fecha <= this.fechas.fechafin ){
                    if(this.estatusFiltro == 6){
                      return true
                    }else{
                      if(this.estatusFiltro == 3){
                        return est == 3 || est == 4 || est == 5
                      }else{
                        if(est == this.estatusFiltro){return true}
                      }
                    }
                  }
                }
              }else{
                return false
              }
            }else{
              // Sin fecha seleccionada
              if(!this.fechas.fechaini && !this.fechas.fechafin){
                if(this.estatusFiltro == 6){
                  return true
                }else{
                  if(this.estatusFiltro == 3){
                    return est == 3 || est == 4 || est == 5
                  }else{
                    if(est == this.estatusFiltro){return true}
                  }
                }
              }

              // Solo fecha de inicio seleccionada
              if(this.fechas.fechaini && !this.fechas.fechafin){
                if( prospecto.fecha == this.fechas.fechaini){
                  if(this.estatusFiltro == 6){
                    return true
                  }else{
                    if(this.estatusFiltro == 3){
                      return est == 3 || est == 4 || est == 5
                    }else{
                      if(est == this.estatusFiltro){return true}
                    }
                  }
                }
              }

              // Solo fecha de inicio seleccionada
              if(this.fechas.fechaini && this.fechas.fechafin){
                if(prospecto.fecha >= this.fechas.fechaini && prospecto.fecha <= this.fechas.fechafin ){
                  if(this.estatusFiltro == 6){
                    return true
                  }else{
                    if(this.estatusFiltro == 3){
                      return est == 3 || est == 4 || est == 5
                    }else{
                      if(est == this.estatusFiltro){return true}
                    }
                  }
                }
              }
            }

          })
        }
      },

      filterProspectosTareas(){
        if(this.prospectosAgendadaInduccion){
          // return this.prospectosAgendadaInduccion
          return this.prospectosAgendadaInduccion.filter((prospecto)=>{
            let est = prospecto.idetapa;

            if(this.vendedoraFiltro){
              if(this.vendedoraFiltro.iderp == prospecto.usuario_asignado){
                // Sin fecha seleccionada
                if(!this.fechas.fechaini && !this.fechas.fechafin){
                  if(this.estatusFiltro == 6){
                    return true
                  }else{
                    if(this.estatusFiltro == 3){
                      return est == 3 || est == 4 || est == 5
                    }else{
                      if(est == this.estatusFiltro){return true}
                    }
                  }
                }

                // Solo fecha de inicio seleccionada
                if(this.fechas.fechaini && !this.fechas.fechafin){
                  if( prospecto.fecha == this.fechas.fechaini){
                    if(this.estatusFiltro == 6){
                      return true
                    }else{
                      if(this.estatusFiltro == 3){
                        return est == 3 || est == 4 || est == 5
                      }else{
                        if(est == this.estatusFiltro){return true}
                      }
                    }
                  }
                }

                // Solo fecha de inicio seleccionada
                if(this.fechas.fechaini && this.fechas.fechafin){
                  if(prospecto.fecha >= this.fechas.fechaini && prospecto.fecha <= this.fechas.fechafin ){
                    if(this.estatusFiltro == 6){
                      return true
                    }else{
                      if(this.estatusFiltro == 3){
                        return est == 3 || est == 4 || est == 5
                      }else{
                        if(est == this.estatusFiltro){return true}
                      }
                    }
                  }
                }
              }else{
                return false
              }
            }else{
              // Sin fecha seleccionada
              if(!this.fechas.fechaini && !this.fechas.fechafin){
                if(this.estatusFiltro == 6){
                  return true
                }else{
                  if(this.estatusFiltro == 3){
                    return est == 3 || est == 4 || est == 5
                  }else{
                    if(est == this.estatusFiltro){return true}
                  }
                }
              }

              // Solo fecha de inicio seleccionada
              if(this.fechas.fechaini && !this.fechas.fechafin){
                if( prospecto.fecha == this.fechas.fechaini){
                  if(this.estatusFiltro == 6){
                    return true
                  }else{
                    if(this.estatusFiltro == 3){
                      return est == 3 || est == 4 || est == 5
                    }else{
                      if(est == this.estatusFiltro){return true}
                    }
                  }
                }
              }

              // Solo fecha de inicio seleccionada
              if(this.fechas.fechaini && this.fechas.fechafin){
                if(prospecto.fecha >= this.fechas.fechaini && prospecto.fecha <= this.fechas.fechafin ){
                  if(this.estatusFiltro == 6){
                    return true
                  }else{
                    if(this.estatusFiltro == 3){
                      return est == 3 || est == 4 || est == 5
                    }else{
                      if(est == this.estatusFiltro){return true}
                    }
                  }
                }
              }
            }

          })
        }
      },

      filterProspectos(){
        if(this.prospectosSeguimiento){
          // return this.prospectosSeguimiento
          return this.prospectosSeguimiento.filter((prospecto)=>{
            let est = prospecto.idetapa;

            if(this.vendedoraFiltro){
              if(this.vendedoraFiltro.iderp == prospecto.usuario_asignado){
                // Sin fecha seleccionada
                if(!this.fechas.fechaini && !this.fechas.fechafin){
                  if(this.estatusFiltro == 6){
                    return true
                  }else{
                    if(this.estatusFiltro == 3){
                      return est == 3 || est == 4 || est == 5
                    }else{
                      if(est == this.estatusFiltro){return true}
                    }
                  }
                }

                // Solo fecha de inicio seleccionada
                if(this.fechas.fechaini && !this.fechas.fechafin){
                  if( prospecto.fecha == this.fechas.fechaini){
                    if(this.estatusFiltro == 6){
                      return true
                    }else{
                      if(this.estatusFiltro == 3){
                        return est == 3 || est == 4 || est == 5
                      }else{
                        if(est == this.estatusFiltro){return true}
                      }
                    }
                  }
                }

                // Solo fecha de inicio seleccionada
                if(this.fechas.fechaini && this.fechas.fechafin){
                  if(prospecto.fecha >= this.fechas.fechaini && prospecto.fecha <= this.fechas.fechafin ){
                    if(this.estatusFiltro == 6){
                      return true
                    }else{
                      if(this.estatusFiltro == 3){
                        return est == 3 || est == 4 || est == 5
                      }else{
                        if(est == this.estatusFiltro){return true}
                      }
                    }
                  }
                }
              }else{
                return false
              }
            }else{
              // Sin fecha seleccionada
              if(!this.fechas.fechaini && !this.fechas.fechafin){
                if(this.estatusFiltro == 6){
                  return true
                }else{
                  if(this.estatusFiltro == 3){
                    return est == 3 || est == 4 || est == 5
                  }else{
                    if(est == this.estatusFiltro){return true}
                  }
                }
              }

              // Solo fecha de inicio seleccionada
              if(this.fechas.fechaini && !this.fechas.fechafin){
                if( prospecto.fecha == this.fechas.fechaini){
                  if(this.estatusFiltro == 6){
                    return true
                  }else{
                    if(this.estatusFiltro == 3){
                      return est == 3 || est == 4 || est == 5
                    }else{
                      if(est == this.estatusFiltro){return true}
                    }
                  }
                }
              }

              // Solo fecha de inicio seleccionada
              if(this.fechas.fechaini && this.fechas.fechafin){
                if(prospecto.fecha >= this.fechas.fechaini && prospecto.fecha <= this.fechas.fechafin ){
                  if(this.estatusFiltro == 6){
                    return true
                  }else{
                    if(this.estatusFiltro == 3){
                      return est == 3 || est == 4 || est == 5
                    }else{
                      if(est == this.estatusFiltro){return true}
                    }
                  }
                }
              }
            }

          })
        }
      },

      filterSucursales(){

        if([18,73].includes( this.getdatosUsuario.idpuesto)){
          if(this.sucursales){
            return this.sucursales.filter( el=> { return el.escuela == this.getEscuela})
          }
        }else{
          return this.sucursales
        }
      },

      filterCursos(){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto)){

          return [
            { id: 1  , curso: 'TEENS'         , escuela: 1 },
            { id: 2  , curso: 'KIDS'          , escuela: 1 },
            { id: 3  , curso: 'ADULTOS'       , escuela: 1 },
            { id: 4  , curso: 'ADULTOS'       , escuela: 2 },
            { id: 7  , curso: 'EXCI'          , escuela: 2 },
          ]

        }else if( this.getEscuela == 2 ){

          return [
            { id: 4  , curso: 'ADULTOS', escuela: 2 },
            { id: 7  , curso: 'EXCI'   , escuela: 2 },
          ]

        }else{
          return [
            { id: 1  , curso: 'TEENS'         , escuela: 1 },
            { id: 2  , curso: 'KIDS'          , escuela: 1 },
            { id: 3  , curso: 'ADULTOS'       , escuela: 1 },
          ]
        }
      },

      filterVendedoras(){
        if([18,73].includes( this.getdatosUsuario.idpuesto) ){
          return this.vendedoras.filter( el=> { return el.escuela == this.getEscuela})
        }else{
          return this.vendedoras
        }
      },

      canAtrasados(){
        return this.filterProspectos.filter((el)=>{ return el.idetapa == 1}).length
      },

      cantNuevos(){
        return this.filterProspectos.filter((el)=>{ return el.idetapa == 2}).length
      },

      cantInduccion(){
        return this.filterProspectos.filter((el)=>{ return el.idetapa == 3}).length
      },

      cantSinInfo(){
        return this.filterProspectos.filter((el)=>{ return el.idetapa == 5}).length
      },

      cantConInfo(){
        return this.filterProspectos.filter((el)=>{ return el.idetapa == 4}).length
      },

      cantSeguimiento(){
        return this.filterProspectos.filter((el)=>{ return el.idetapa > 2 }).length + this.filterProspectosSeguimiento.filter((el)=>{ return el.idetapa == 4}).length
      },

      cantTodas(){
        return this.filterProspectosSeguimiento.length + this.filterProspectosTareas.length + this.filterProspectos.length
      },

      progreso () {
        console.log( this.filterProspectosSeguimiento.filter((el)=>{ return el.cantEventosHoy > 0 }).length )
        console.log( this.filterProspectosTareas.filter((el)=>{ return el.cantEventosHoy > 0 }).length )
        console.log( this.prospectos.length )
        console.log( this.filterProspectosTareas.filter((el)=>{ return el.cantEventosHoy == 0 }).length )

        let prospectosCount = this.prospectos.length 

        if( this.vendedoraFiltro ){
          console.log('entre')
          prospectosCount = this.prospectos.filter( el => el.usuario_asignado == this.vendedoraFiltro.iderp ).length
        }


        return  (( ( this.filterProspectosSeguimiento.filter((el)=>{ return el.cantEventosHoy > 0 }).length + this.filterProspectosTareas.filter((el)=>{ return el.cantEventosHoy > 0 }).length ) / ( prospectosCount - this.filterProspectosTareas.filter((el)=>{ return el.cantEventosHoy == 0 }).length) )  * 100).toFixed(2)
      },

      buenos(){
        return this.filterProspectos.filter((el)=>{ return el.clasificacion == 1})
      },

      malos(){
        return this.filterProspectos.filter((el)=>{ return el.clasificacion == 2})
      },
    },

    async created() {
      // Agregamos los header de la tabla
      this.headers = ([18,73].includes( this.getdatosUsuario.idpuesto)) ? headerVend : headerAdmin

      await this.getSucursales()
      await this.getVendedoras()
      await this.getMotivos()
      await this.getProspectosCerrados()
      await this.getAnuncios()
      await this.getCursos()
      await this.getCiclos()
      await this.getTareas()
      await this.getModalidades()
      await this.getFrecuencias()

      await this.initialize();
      await this.obtenerEstatusLlamada()
      // Validar si hay una llamada activa
      if(this.getEstatusLlamada){
        await this.getInfoLlamada()
      }
      this.puntosLlamada = this.getEscuela == 1 ? [] : infoFast
    },

    methods: {
      ...mapActions('login', ['cambiarDrawer','cambiarEstatusLlamada','addTareas','traerProspectosNuevos']),

      getInfoLlamada(){
        return this.$http.get('prospecto.llamada.activa/' + this.getdatosUsuario.iderp).then(async(response) => {
          const prospecto = this.prospectos.find(el=> el.idprospectos == response.data.idprospectos)
          await this.verProspecto( prospecto, 0, 0)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Inicio de todo
      initialize() {
        this.traerProspectosNuevos(this.getdatosUsuario.iderp)
        // Abrimos modal de carga
        this.cargar = true
        // Limpiamos los prospectos
        this.prospectos  = []

        // Ver el tipo de usuario que entrara
        let tipo = 0 // null
        if( ([18,73].includes( this.getdatosUsuario.idpuesto)) && ![161,7,626,526,568,382].includes( this.getdatosUsuario.iderp ) ){
          tipo = 1 // vendedora
        }else{
          tipo = 2 // Director
        }

        return this.$http.get('prospectos.vendedora/' + tipo + '/' + this.getdatosUsuario.iderp).then((response) => {
          // Filtramos los que necesitamos, depende si es vendedora o administrador
          this.prospectos = response.data.respuesta

          if([18,73].includes( this.getdatosUsuario.idpuesto)){
            const nuevos = this.prospectos.filter(el=>{ return el.idetapa == 2 })
            if(nuevos.length > 0 ){
              // this.prospectos = nuevos
            }
          }

          // Vamos a poner un identificador a los prospectos
          for(const i in this.prospectos){
            this.prospectos[i]['idcontador'] = i
          }

          // Prospectos que se encuentran el inducción o tienen tarea programada que no es para hoy
          this.prospectosAgendadaInduccion  = response.data.prospectosAgendadaInduccion

          // Prospectos sin seguimiento hoy y sin tareas programadas
          this.prospectosSeguimiento        = response.data.prospectosSeguimiento

          // Prospectos con seguimiento hoy y sin tareas programadas
          this.prospectosConSeguimiento     = response.data.prospectosConSeguimiento

          // Prospectos basura
          this.prospectosBasura             = response.data.prospectosBasura

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      getProspectosCerrados() {
        // Abrimos modal de carga
        this.cargar = true
        // Limpiamos los prospectos
        this.prospectosCerrados  = []

        // Ver el tipo de usuario que entrara
        let tipo = 0 // null
        if( ([18,73].includes( this.getdatosUsuario.idpuesto))  && ![7,161].includes( this.getdatosUsuario.iderp ) ){
          tipo = 1 // vendedora
        }else{
          tipo = 2 // Director
        }

        return this.$http.get('prospectos.cerradostotal/' + tipo + '/' + this.getdatosUsuario.iderp).then((response) => {
          this.prospectosCerrados           = response.data        //Angel Rodriguez
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },
    
      /*
        **************** FUNCIONES PARA LOS PROSPECTOS ********************
      */
      // Datos para grabar el prospecto
      // Abrir modal que ayuda a visualizar al prospecto
      async verProspecto(prospecto, idetapa, iniSeguimiento) {

        this.cargar = true
        this.prospecto = prospecto
        // Limpiamos los datos
        this.comentarios          = []
        this.tareas_programadas   = []
        this.eventos              = []
        this.paraquien_el_curso   = ''
        this.estudioIngles        = ''
        this.porqueEstudiarIngles = ''
        this.dispoHorario         = ''
        this.edad                 = ''
        this.notas                = ''
        this.clasificacion        = prospecto.clasificacion
        this.justificacion        = prospecto.justificacion

        // Mandamos a llamar la información del usuario
        this.$http.get('prospectos.datos/' + this.prospecto.idprospectos).then((response) => {
          // Vemos la etapa en la que esta en ese momento
          if(response.data.datosGenerales){
            this.idetapa = response.data.datosGenerales.idetapa
          }

          // Agregamos los puntos inciales
          this.comentarios        = response.data.comentarios
          this.tareas_realizadas  = response.data.tareas_realizadas
          this.eventos            = response.data.eventos
          this.llamadasList       = response.data.llamadas
          this.datosLlamada       = response.data.datosLlamada
          this.eventosTimeline    = response.data.timeLine
          this.familiares         = response.data.getFamiliares
          // this.tareas_programadas = prospecto.tareasP
          this.tareas_programadas = response.data.tareas

          // Contador de whatsApp's
          this.countWhatsApp = 0
          const contadorWhats = this.eventos.filter(el=> { return el.idtipo_evento == 5})
          this.countWhatsApp = contadorWhats.length

          // Guardamos la vendedora
          this.vendedora = {iderp:      parseInt(this.prospecto.usuario_asignado), nombre:  this.prospecto.vendedora}

          // Verificamos que haya datos en la llamada
          if(this.datosLlamada){
            this.paraquien_el_curso   = this.datosLlamada.pregunta1
            this.estudioIngles        = this.datosLlamada.pregunta2
            this.porqueEstudiarIngles = this.datosLlamada.pregunta3
            this.dispoHorario         = this.datosLlamada.pregunta4
            this.edad                 = this.datosLlamada.edad
            this.notas                = this.datosLlamada.notas
            // Llenamos los selectores

            this.anuncio              = this.datosLlamada.idanuncios         ? { idanuncios:         this.datosLlamada.idanuncios,     anuncio:    this.datosLlamada.anuncio      } : null
            this.curso                = this.datosLlamada.idcurso            ? { idcursos_escuela:   this.datosLlamada.curso.id_curso, curso:      this.datosLlamada.curso.curso  } : null
            this.modalidad            = this.datosLlamada.idmodalidad        ? { idmodalidad_cursos: this.datosLlamada.idmodalidad,    modalidad:  this.datosLlamada.modalidad    } : null
            this.frecuencia           = this.datosLlamada.idfrecuencias      ? { idfrecuencias:      this.datosLlamada.idfrecuencias,  frecuencia: this.datosLlamada.frecuencia   } : null
            if(this.datosLlamada.idplantel){
              const existeSucursal = this.sucursales.find( el => el.idplantel == this.datosLlamada.idplantel)

              if(existeSucursal){
                this.sucursal = existeSucursal
              }
            }
            // Validamos si el ciclo esta en la lista de los ciclos activos
            if(this.datosLlamada.idciclo){
              const existeCiclo = this.ciclos_plantel.find(el=> el.id_ciclo == this.datosLlamada.idciclo)
              // Si no existe, lo agregamos
              if(!existeCiclo){
                // Se lleno el ciclo
                this.ciclos_plantel.push(this.datosLlamada.ciclo)
              }
              // Y ahora si ponemos el ciclo
              this.ciclo = { id_ciclo: this.datosLlamada.idciclo, ciclo: this.datosLlamada.ciclo} 
            }
          }

          // Abrimos la modal para poder visualizar la información del prospecto
          this.dialogProspecto = true

          this.cargar  = false
          this.loading = false

          // Validamos si existe una llamada activa
          console.log(this.getEstatusLlamada)
          if(this.getEstatusLlamada){
            // validar si no tiene datos grabados, si no hay, hay que cerrar el dialogo de seguimiento y abrir el de info usuario
            if(!this.datosLlamada){
              if(!this.dialogInfoUsuario){
                setTimeout(() => { 
                  // Y abrimos la información del usuario y cerrramos el seguimiento
                  this.dialogSeguimiento = false
                  this.dialogInfoUsuario = true
                }, 1000);
              }
            }
          }

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Validar la etapa del prospecto
      validEtapa( idetapa, etapa ){
        const payload = {
          idetapa,
          etapa,
          usuario_creo: this.getdatosUsuario.iderp
        }
        this.$http.put('prospectos.etapa/' + this.prospecto.idprospectos, payload).then((response) => {
          return
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Actualizar los datos del prospecto, datos generales
      actualizarProspecto (){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto)){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        if(this.prospecto.nombre_completo != '' && this.prospecto.telefono != '' && this.prospecto.nota_inicial != '' ){
          //recibe los campos del form
          //guardo en una variable los datos a guardar id = this.editedItem.idcurso;
          var datos = {
            idprospecto:      this.prospecto.idprospectos,
            nombre_completo:  this.prospecto.nombre_completo,
            telefono:         this.prospecto.telefono,
            correo:           this.prospecto.correo,
            usuario_creo:     this.getdatosUsuario.iderp,
            usuario_asignado: this.prospecto.usuario_asignado,
            escuela:          this.getEscuela,
            induccion:        this.prospecto.induccion,
            fecha_induccion:  this.prospecto.fecha_induccion,
          }

          this.$http.put('prospectos.update/' + this.prospecto.idprospectos, datos).then( async (response) => {
            if(this.prospecto.induccion){
              if(this.idetapa != 3){ await this.validEtapa( 3, 'Contacto en inducción' ) }
            }
            this.dialog = false
            this.dialogSuccess = true
            this.verProspecto(this.prospecto, this.idetapa);
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
          
        }else{
          this.validarErrorDirecto('Por favor llena todos los datos')
        }
      },

      grabarInfoEscuela(){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }

        let payload = {
          idprospectos: this.prospecto.idprospectos,
          iderp:        this.getdatosUsuario.iderp,
          informacion:  JSON.stringify(this.puntosLlamada)
        }
        this.cargar = true
        this.$http.post('prospectos.add.info.escuela', payload).then((response) => {
          this.dialogSuccess     = true
          this.cargar           = false
          this.dialogInfoUsuario = false
          this.verProspecto(this.prospecto, this.idetapa)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      obtenerEstatusLlamada () {
        this.$http.get('prospectos.llamadas/' + this.getdatosUsuario.iderp).then((response) => {
          if(!response.data.message){
            this.estatusLlamada = response.data.estatus
            this.idestatus_llamada = response.data.idestatus_llamada
            this.cambiarEstatusLlamada(response.data.estatus)
          }
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Actualizar el perfil del prospecto
      updatePerfil () {
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        // Validar si el prospecto brindo información
        if(!this.prospectoNoDioInfo){
          // Validar que los campos esten llenos
          if(!this.sucursal || !this.porqueEstudiarIngles || !this.curso || !this.dispoHorario){
            this.pasosInfoUsuario = 1
            return this.validarErrorDirecto('Favor de llenar todos los datos')
          }
        }

        let payload = {
          idprospectos:  this.prospecto.idprospectos,
          pregunta1:     this.paraquien_el_curso   ? this.paraquien_el_curso   : 0,
          pregunta2:     this.estudioIngles        ? this.estudioIngles        : 0,
          pregunta3:     this.porqueEstudiarIngles ? this.porqueEstudiarIngles : 0,
          pregunta4:     this.dispoHorario         ? this.dispoHorario         : 0,
          idusuarioerp:  this.getdatosUsuario.iderp,
          edad:          this.edad       ? this.edad   : 0,
          notas:         this.notas      ? this.notas  : 0,
          idplantel:     this.sucursal   ? this.sucursal.idplantel           : 0,
          idanuncios:    this.anuncio    ? this.anuncio.idanuncios           : 0,
          idcurso:       this.curso      ? this.curso.idcursos_escuela       : 0,
          idciclo:       this.ciclo      ? this.ciclo.id_ciclo               : 0,
          idmodalidad:   this.modalidad  ? this.modalidad.idmodalidad_cursos : 0,
          idfrecuencias: this.frecuencia ? this.frecuencia.idfrecuencias     : 0,
          medio_informacion: this.medio_informacion
        }

        this.$http.post('prospectos.puntos_llamada',payload).then(async (response) => {
          if(this.idetapa == 1 || this.idetapa == 2 || this.idetapa == 5 ){ await this.validEtapa( 4, 'Contacto con información' ) }
          this.dialogSuccess = true
          this.verProspecto(this.prospecto, this.idetapa)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      /*
        **************** FUNCIONES PARA LAS TAREAS ********************
      */

      abrirTareaIndividual(item){
        // mandaré un 1, para saber que viene de esta función y así iniciar el seguimiento
        this.verProspecto(item, item.idetapa, 1)
      },

      // Programar una nueva tarea
      programarTarea () {
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        this.disableBtnTarea = true
        let payload = {
          idtareas:     this.tarea.idtareas,
          tarea:        this.tarea.tarea,
          idmotivos:    7, // Manera directa
          dia:          this.dia,
          hora:         this.hora,
          idprospectos: this.prospecto.idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp,
          estatus:      0,
          medio:        this.prospecto.telefono,
          tipo_tarea:   1,
          descrip_motivo:this.descrip_motivo
        }

        const fehaHoy = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

        if(moment(fehaHoy).isAfter(moment(this.dia))){
          this.errorBack = false
          this.error     = 'No puedes agendar llamadas antes de hoy'
          this.dialogError = true
          return
        }

        if(!this.horaCargada){
          this.horaCargada = { id: 5 }
        }
        // Revisemo la horaCargada
        switch(this.horaCargada.id){
          case 1:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 1,':',MINUTE(now()),':00'))` 
            payload.dia  = `'${this.dia}'`
          break;
          case 2:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 2,':',MINUTE(now()),':00'))` 
            payload.dia  = `'${this.dia}'`
          break;
          case 3:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 4,':',MINUTE(now()),':00'))` 
            payload.dia  = `'${this.dia}'`
          break;
          case 4:
            payload.hora = `(SELECT TIME(NOW()))` 
            payload.dia  = `(SELECT DATE_ADD(DATE(NOW()), INTERVAL 1 DAY))` 
          break;
          case 5:
            payload.hora = `'${this.hora}'`
            payload.dia  = `'${this.dia}'`
          break;
        }


        this.$http.post('prospectos.tarea.add', payload).then( async (response) => {
          this.opcionAddTarea     = false
          this.horaCargada        = null
          this.dialogCierre       = false
          this.dialoguNevaTarea   = false
          if(this.idetapa == 1 || this.idetapa == 2){ await this.validEtapa( 5, 'Contacto sin información' ) } // sin información
          await this.getTareasProgramadas()
          await this.verProspecto(this.prospecto, this.idetapa)
          this.disableBtnTarea = false
        }).catch( error =>{
          this.dialogError = true
          this.disableBtnTarea = false
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTareasProgramadas ( ) {
        this.addTareas([])
        this.$http.get('prospectos.tarea.usuario/' + this.getdatosUsuario.iderp).then((response) => {
          // Vamos a agregar el campo de tarea programada
          for(const i in response.data){
            response.data[i].notificada = false
          }
          this.addTareas(response.data)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      }, 

      // actualizar una tarea
      actualizarTarea ( tarea ){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        this.banderaTarea        = 1
        this.idtareas_prospectos = tarea.idtareas_prospectos 
        this.motivo              = {idmotivos: 7, motivo: "OTRO"}
        this.notaAnterior        = {idmotivos: 7, motivo: "OTRO"}
        this.dia                 = tarea.dia
        this.hora                = tarea.hora
        this.tarea               = this.tareas.find(el => el.idtareas == tarea.idtareas)
        this.horaCargada         = {id: 5, hora: 'Otra'}
        this.tipo_tarea          = {id: 1, tipo:'Agendada'}
        this.descrip_motivo      = ""
        this.opcionAddTarea      = true
        this.disableBtnTarea     = false
      },

      // Reprogramar la tarea
      reprogramarTarea () {
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        this.disableBtnTarea = true
        let payload = {
          idtareas_prospectos: this.idtareas_prospectos,
          idtareas:     this.tarea.idtareas,
          tarea:        this.tarea.tarea,
          idmotivos:    this.motivo.idmotivos,
          dia:          this.dia,
          hora:         this.hora,
          idprospectos: this.prospecto.idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp,
          notaAnterior: this.notaAnterior,
          estatus:      0,
          medio:        this.tarea.tipo_tarea == 1 ? this.prospecto.telefono : this.tarea.tipo_tarea == 2 ? this.prospecto.telefono : this.prospecto.email,
          tipo_tarea:   this.tipo_tarea.id,
          descrip_motivo:this.descrip_motivo
        }

        const fehaHoy = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

        if(moment(fehaHoy).isAfter(moment(this.dia))){
          this.errorBack = false
          this.error     = ' No puedes agendar llamadas antes de hoy'
          this.dialogError = true
          return
        }

        // Revisemo la horaCargada
        switch(this.horaCargada.id){
          case 1:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 1,':',MINUTE(now()),':00'))`
            payload.dia  = `'${this.dia}'`
          break;
          case 2:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 2,':',MINUTE(now()),':00'))`
            payload.dia  = `'${this.dia}'`
          break;
          case 3:
            payload.hora = `(SELECT CONCAT(HOUR(NOW()) + 4,':',MINUTE(now()),':00'))`
            payload.dia  = `'${this.dia}'`
          break;
          case 4:
            payload.hora = `(SELECT TIME(NOW()))` 
            payload.dia  = `(SELECT DATE_ADD(DATE(NOW()), INTERVAL 1 DAY))` 
          break;
          case 5:
            payload.hora = `'${this.hora}'`
            payload.dia  = `'${this.dia}'`
          break;
          default:
            payload.hora   = `'${this.tarea.hora}'`
            payload.dia    = `'${this.tarea.dia}'`
          break;
        }

        this.$http.put('prospectos.tarea.update', payload).then((response) => {
          this.opcionAddTarea = false
          this.nota = ''
          this.dia  = null
          this.hora = null
          this.getTareasProgramadas()
          this.verProspecto(this.prospecto, this.idetapa)
          this.disableBtnTarea = false
        }).catch( error =>{
          this.disableBtnTarea = false
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      // Eliminar la tarea
      eliminarTarea(id){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        this.idtareas_prospectos      = id
        this.paramDeleteTarea.estatus = true
      },

      // confirmación de que se eliminiará la tarea
      deleteItemConfirm() {
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        // Mixin funciones
        this.mEliminarTarea( this.idtareas_prospectos )
      },

      // Verificar que la tarea se realizo
      tareaRealizada( id ) {
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        this.$http.put(`prospectos.tarea.check/${id}`).then((response) => {
          this.dialogDelete  = false
          this.dialogSuccess = true
          this.tareaLlamada  = false
          this.verProspecto(this.prospecto, this.idetapa)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      /*
        *********************************************
      */

      abrirDialogNotaLlamada(){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        this.notasLlamada = []
        this.$http.get(`prospectos.notallamada/${this.prospecto.idprospectos}`).then((response) => {
          this.notasLlamada = response.data
          this.dialogNotaLlamada = true
          // INdicamos que hay un error en el back
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      grabarNotaLlamada(){
        if(![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        let payload = {
          idprospectos: this.prospecto.idprospectos,
          idusuarioerp: this.getdatosUsuario.iderp,
          nota:         this.notaLlamada,
          idestatus_llamada: this.idestatus_llamada
        }
        this.$http.post(`prospectos.notallamada.add`,payload).then((response) => {
          this.notaLlamada = ''
          this.abrirDialogNotaLlamada()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      limpiar ( ) {
        this.sucursal                = null
        this.anuncio                 = null
        this.curso                   = null
        this.ciclo                   = null
        this.vendedora               = null
        this.datosLlamada            = null
        this.banderaFinalizo         = false
        this.prospectoFinalizo       = false
        this.tareaLlamada            = false
        this.pasosInfoUsuario        = 1
        this.intentoLlamada          = 0
        this.dialogDelete            = false
        this.dialogTareasProgramadas = false
        this.dialogFinalizacion      = false
        this.dialogCierre            = false
        this.dialogInfoUsuario       = false
        this.dialogSeguimiento       = false  // dialogo para iniciar seguimiento 
        this.dialogEtapa             = false
        this.dialogSuccess           = false
        this.dialogProspecto         = false
        this.dialogError             = false
        this.dialoguNevaTarea        = false
        this.opCierre                = 0
        this.puntosLlamada           = this.getEscuela == 1 ? [] : infoFast
        this.descrip_motivo          = ''
        this.disableBtnTarea         = false
        this.codigo_clase            = ''
        this.contra                  = ''
        this.opcionAddTarea          = false
        this.familiarAFinalizar     = null
        this.dialogFinalizarFamiliar = false
        this.matricula               = ''
        this.grupo                   = null
        this.notaRechazo             = ''
        this.medio_informacion       = 0
        this.prospectoNoDioInfo      = 0
        this.banderaTarea            = 0
      },

      iniciarSeguimiento () {
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        // Va, empecemos con este desmadre
        /* 
          PASO #1: Validar cuantos prospectos tenemos nuevosss en sistema (idetapa = 2)
          this.cantProspectosPrimeraEtapa
        */
        const prospectoPrimerEtapa = this.prospectos.filter(el => { return el.idetapa == 2 })

        /*
          PASO #2: Si hay más de 3 prospectos, va directo a whatsApp
        */

        if(prospectoPrimerEtapa.length > 3){
          this.intentoLlamada = 3
          this.dialogSeguimiento = true
        }else{
          // El prospecto tiene una tarea programada?
          if(this.tareas_programadas.length == 0){
            // Si no tiene, abrimos el dialogSeguimiento
            this.dialogSeguimiento = true
          }else{
            // Si tiene abrimos el dialog de tareas programadas
            this.dialogTareasProgramadas = true
          }
        }
      },

      inciarSeguimientoEnSucursal(){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        // Indicamos que es por sucursal
        this.medio_informacion = 1
        // Abrimos seguimiento
        this.dialogSeguimiento = true
        // Actualizamos estatus llamada, osea, abrimos la llamada
        this.updateEstatusLlamada()
      },

      updateEstatusLlamada(){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        // verificamos si tiene datos osea un perfil, si no lo tiene hay que abrir primero el dialog de primer contacto
        if(!this.datosLlamada){
          // Y abrimos la información del usuario y cerrramos el seguimiento
          this.dialogSeguimiento = false
          this.dialogInfoUsuario = true
        }

        // validamos si tiene tareas
        if(this.tareas_programadas.length > 0){
          // Si tiene se eliminia y punto
          this.tareaRealizada(this.tareas_programadas[0].idtareas_prospectos)
        }

        // Abrimos la llamada
        this.showTimer = true
        this.iniciarCronometro()
        this.abrirCerrarLlamada(true) 
      },

      abrirCerrarLlamada(estatusLlamada){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        let payload = {
          idusuarioerp: this.getdatosUsuario.iderp,
          estatus:      estatusLlamada,
          idprospectos: this.prospecto.idprospectos
        }

        this.$http.post('prospectos.llamadas.add', payload).then((response) => {
          this.estatusLlamada = estatusLlamada
          this.idestatus_llamada = response.data.id
          this.cambiarEstatusLlamada(estatusLlamada)
          // Al cambiar el estatus de la llamada, es necesario indicar si desea agregar una tarea
          this.verProspecto(this.prospecto, this.idetapa)
          // Abrimos lo de la tarea
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      finalizarLlamada(){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        // Validamos si estaba en una tarea de llamada
        if(this.tareaLlamada){
          this.tipoDelete = 1
          if(this.tareas_programadas.length > 0){
            this.tareaRealizada(this.tareas_programadas[0].idtareas_prospectos)
          }
        }

        this.getTareasProgramadas()

        // Abrimos para agendar una nueva tarea
        this.dialogCierre = true
        // Cerramos la llamada
        this.resetCronometro()
        this.abrirCerrarLlamada(false)
      },

      cerrarDialogCierre(){
        this.dialogCierre       = false
        this.opCierre           = 0
        this.banderaRechazo     = false
        this.banderaFinalizo    = false
        this.notaRechazo        = ''
        this.matricula          = null
        this.grupo              = null
      },

      copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          // console.log('Something went wrong', err);
        }).finally(()=> { this.cargar = false })
      },

      copiarTelefono ( item ) {
        navigator.clipboard.writeText(item).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          // console.log('Something went wrong', err);
        })
      },

      pegarPortapapeles ( escuela, folio ) {

        const link = escuela == 2 ? 'https://www.fastenglish.com.mx/examen-interno/?folio=' : 'https://www.inbi.mx/examen-interno/?folio='
        const url = folio

        navigator.clipboard.writeText(url).then(() => {
          this.alertaUrlCopy = 'COPIADO CORRECTAMENTE'
          setTimeout(() => {
            this.alertaUrlCopy = 'CLICK PARA COPIAR'
          }, 1500);

        }).catch(err => {
          // console.log('Something went wrong', err);
        })
      },

      /*
        *************************************
      */

      finalizarProspecto (){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto)){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        if(this.matricula != '' && this.grupo && this.codigo_clase != '' && this.codigo_clase != ''){
          const payload = {
            idprospectos: this.prospecto.idprospectos,
            matricula:    this.matricula,
            idusuarioerp: this.getdatosUsuario.iderp,
            idgrupo:      this.grupo.id_grupo,
            codigo_clase: this.codigo_clase
          }

          // Hacer la peticion de los datos
          this.$http.post('prospectos.finalizar.ok', payload).then( async (response) => {
            this.dialogSuccess = true
            this.dialogProspecto = false
            this.dialogFinalizacion = false
            this.dialogTarea        = false
            await this.enviarCodigo()
            this.limpiar()
            this.initialize()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })


        }else{  
          this.validarErrorDirecto('Por favor, ingresa los datos correctamente')
        }
      },

      enviarCodigo () {
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        let payload = {
          idusuarioerp: this.getdatosUsuario.iderp,
          idprospectos: this.prospecto.idprospectos,
          nota:         `Hola, te comparto tu código para poder acceder a tu clase
          %0ACODIGO:          ${ this.codigo_clase } 
          %0ACONTRASEÑA: ${ this.contra       }
          `,
        }

        this.$http.post('prospectos.whatsapp.add', payload).then((response) => {
          // Abrimo la modal para grabar una tarea
          let url = 'https://api.whatsapp.com/send?phone=+52' + this.prospecto.telefono + '&text=' + payload.nota
          window.open(url, '_blank');
          return
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGrupos(){
        this.grupos = []
        this.$http.get('prospectos.grupos/' + this.getEscuela).then((response) => {
          this.grupos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      rechazoProspecto(){
        if(![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        if(this.notaRechazo != ''){
          const payload = {
            idprospectos: this.prospecto.idprospectos,
            idusuarioerp: this.getdatosUsuario.iderp,
            notaRechazo:  this.notaRechazo
          }

          // Hacer la peticion de los datos
          this.$http.post('prospectos.finalizar.nok', payload).then((response) => {
            this.dialogSuccess      = true
            this.dialogProspecto    = false
            this.notaRechazo        = ''
            this.banderaFinalizo    = false
            this.banderaRechazo     = false
            this.cerrarDialogCierre()
            this.limpiar()
            this.initialize()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }else{ 
          this.validarErrorDirecto('Por favor, ingresa los datos correctamente') 
        }
      },

      /*
        ************* FINALIZAR PROSPECTO FAMILIAR
      */

      finalizarProspectoFamiliar (){
        if(![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        if(this.matricula != '' && this.grupo && this.codigo_clase != '' && this.codigo_clase != ''){
          const payload = {
            idfamiliares: this.familiarAFinalizar.idfamiliares,
            matricula:    this.matricula,
            idusuarioerp: this.getdatosUsuario.iderp,
            idgrupo:      this.grupo.id_grupo,
            codigo_clase: this.codigo_clase,
            idprospectos: this.prospecto.idprospectos
          }

          // Hacer la peticion de los datos
          this.$http.post('familiar.finalizar.ok', payload).then( async (response) => {
            await this.enviarCodigoFamiliar()
            this.dialogSuccess           = true
            this.notaRechazo             = ''
            this.banderaFinalizo         = false
            this.banderaRechazo          = false
            this.familiar                = null
            this.dialogFinalizarFamiliar = false
            this.codigo_clase            = ''
            this.grupo                   = null
            this.verProspecto(this.prospecto, 1)
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })


        }else{
          this.validarErrorDirecto('Por favor, ingresa los datos correctamente')   
        }
      },

      enviarCodigoFamiliar () {
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        let payload = {
          idusuarioerp: this.getdatosUsuario.iderp,
          idprospectos: this.prospecto.idprospectos,
          nota:         `Hola, te comparto tu código para poder acceder a tu clase
          %0ACODIGO:          ${ this.codigo_clase } 
          %0ACONTRASEÑA: ${ this.contra       }
          `,
        }

        this.$http.post('prospectos.whatsapp.add', payload).then((response) => {
          // Abrimo la modal para grabar una tarea
          if(this.familiar && this.familiar.telefono){
            let url = 'https://api.whatsapp.com/send?phone=+52' + this.familiar.telefono + '&text=' + payload.nota
            window.open(url, '_blank');
            return
          }else{
            let url = 'https://api.whatsapp.com/send?phone=+52' + this.prospecto.telefono + '&text=' + payload.nota
            window.open(url, '_blank');
            return
          }
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      rechazoProspectoFamiliar(){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        if(this.notaRechazo != ''){
          const payload = {
            idfamiliares: this.familiarAFinalizar.idfamiliares,
            idusuarioerp: this.getdatosUsuario.iderp,
            notaRechazo:  this.notaRechazo,
            idprospectos: this.prospecto.idprospectos
          }

          // Hacer la peticion de los datos
          this.$http.post('familiar.finalizar.nok', payload).then((response) => {
            this.dialogSuccess           = true
            this.notaRechazo             = ''
            this.banderaFinalizo         = false
            this.banderaRechazo          = false
            this.familiar                = null
            this.dialogFinalizarFamiliar = false
            this.verProspecto(this.prospecto, 1)
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })
        }else{  
          this.validarErrorDirecto('Por favor, ingresa los datos correctamente')
        }
      },

      /*
        *************************************
      */

      enviarWhatsApp () {
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        if(this.intentoLlamada == 3){
          this.addIntentoLlamada(9)
          this.addIntentoLlamada(10)
        }
        // validamos si tiene tareas
        if(this.tareas_programadas.length > 0){
          // Si tiene se eliminia y punto
          this.tareaRealizada(this.tareas_programadas[0].idtareas_prospectos)
        }
        const payload = {
          idusuarioerp: this.getdatosUsuario.iderp,
          idprospectos: this.prospecto.idprospectos,
          nota:         this.mensajeWhatsapp,
        }
        this.$http.post('prospectos.whatsapp.add', payload).then(async (response) => {
          if(this.idetapa == 1 || this.idetapa == 2){ await this.validEtapa( 5, 'Contacto sin información' ) } // sin información
          this.dialogSeguimiento     = false // Cerramos el dialogo de primer contacto
          /* Deshabilitamos todo lo que se utilzo para esa ventana */
          this.dialogInfoUsuario     = false
          this.dialogSeguimiento     = false
          this.showTooltipTelefono   = false
          this.dialogCierre          = true
          // Abrimo la modal para grabar una tarea
          let url = 'https://api.whatsapp.com/send?phone=+52' + this.prospecto.telefono + '&text=' + this.mensajeWhatsapp
          window.open(url, '_blank');
          this.mensajeWhatsapp = ''
          this.verProspecto( this.prospecto, this.idetapa );
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirNotaMental(position){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        // nota mental
        this.eventos[position].abrirMensaje = true 
        const eventos = this.eventos
        this.eventos = []
        this.eventos = eventos
      },

      cerrarNotaMental(position){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        // nota mental
        this.eventos[position].abrirMensaje = false 
        const eventos = this.eventos
        this.eventos = []
        this.eventos = eventos
      },

      // Siguiente y atrás
      siguiente(){

        // Validar si hay nuevos:
        const position = this.prospectos.map(prospecto => prospecto.idprospectos).indexOf(this.prospecto.idprospectos)
        if(position <= this.prospectos.length - 1){
          this.loading = true
          this.cargar = true
          this.verProspecto(this.prospectos[position + 1], 1,0)
          this.limpiar2()
        }
      },

      anterior(){
        const position = this.prospectos.map(prospecto => prospecto.idprospectos).indexOf(this.prospecto.idprospectos)
        if(position > 0){
          this.loading = true
          this.cargar = true
          this.verProspecto(this.prospectos[position - 1], 1,0)
          this.limpiar2()
        }
      },

      limpiar2 ( ) {
        this.sucursal                = null
        this.anuncio                 = null
        this.curso                   = null
        this.ciclo                   = null
        this.vendedora               = null
        this.datosLlamada            = null
        this.banderaFinalizo         = false
        this.prospectoFinalizo       = false
        this.tareaLlamada            = false
        this.pasosInfoUsuario        = 1
        this.intentoLlamada          = 0
        this.dialogDelete            = false
        this.dialogTareasProgramadas = false
        this.dialogFinalizacion      = false
        this.dialogCierre            = false
        this.dialogInfoUsuario       = false
        this.dialogSeguimiento       = false  // dialogo para iniciar seguimiento 
        this.dialogEtapa             = false
        this.dialogSuccess           = false
        this.dialogError             = false
        this.dialoguNevaTarea        = false
        this.opCierre                = 0
        this.puntosLlamada           = this.getEscuela == 1 ? [] : infoFast
        this.descrip_motivo          = ''
        this.disableBtnTarea         = false
        this.codigo_clase            = ''
        this.contra                  = ''
        this.opcionAddTarea          = false
        this.medio_informacion       = 0
        this.prospectoNoDioInfo      = 0
        this.banderaTarea            = 0
      },

      /* add intento de la llamada */
      addIntentoLlamada(tipo_evento){
        if( ![18,73].includes( this.getdatosUsuario.idpuesto) ){ this.validarErrorDirecto('Errorrr, no tienes permiso para actualizar esta información'); return }
        const payload = {
          idusuarioerp: this.getdatosUsuario.iderp,
          idprospectos: this.prospecto.idprospectos,
          tipo_evento:  tipo_evento,
        }
        this.$http.post('prospectos.add.evento.llamada', payload).then(async (response) => {
          if(this.idetapa == 1 || this.idetapa == 2){ await this.validEtapa( 5, 'Contacto sin información' ) } // sin información
          await this.verProspecto(this.prospecto, this.idetapa)
          // Abrimo la modal para grabar una tarea
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      /* CLASIFICACION DEL ALUMNO */
      actualizarClasificacion(){
        if( !this.clasificacion ){
          this.validarErrorDirecto('Selecciona primero una opción')
        }

        if( this.clasificacion == 2 && !this.justificacion){
          this.validarErrorDirecto('Justifica tu respuesta, por favor')
        }

        const payload = {
          prospecto: this.prospecto.idprospectos,
          clasificacion: this.clasificacion,
          justificacion: this.justificacion
        }

        this.$http.post('prospectos.add.clasificacion', payload).then(async (response) => {
          this.dialogSuccess = true
          // Abrimo la modal para grabar una tarea
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      saveIneteres( props ){
        console.log( props )

        this.$http.post('prospectos.add.clasificar', props).then(async (response) => {
          this.dialogSuccess = true
          // Abrimo la modal para grabar una tarea
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      verNivel( item ){
        if( item.nivel != null && item.nivel != 0 )
        window.open ( item.diploma )
      }

    },
  };
</script>
<style scoped>
  #notas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #notas::-webkit-scrollbar:vertical {
    width: 6px !important;
  }

  #notas::-webkit-scrollbar:vertical{
    width: 6px !important;
  }

  #notas::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  #notas::-webkit-scrollbar:horizontal{
    height: 6px !important;
  }

  #notas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  #slider-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    overflow: hidden;
  }

  #slider-container::before {
    position: absolute;
    top: calc(50% - 20px);
    right: 0;
    left: 0;
    Content: "";
    Background-color: grey;
    height: 1px;
    z-index: -1;
  }
</style>
<!-- Línea 5276 -->