<template>
	<v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Trabajadores ERP</span>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small
              dark
              color="pink"
              @click="dialogSueldos.estatus = true"
              tile
            >
              Sueldos
            </v-btn>

            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportarDataExcel()"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog_agregar = true, getUsuarios()"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>
          <v-card-text>          
            <v-row >
              <!-- Seleccionar el plantel -->  

              <v-col cols="12" md="4" lg="3">
                <v-select
                  :items="['Activos','Inactivos','Todos']"
                  v-model="activos"
                  label="Activos"
                  filled
                  dense
                  single-line
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  :items="puestos"
                  v-model="puesto_seleccionado"
                  label="Puesto"
                  clearable
                  filled
                  dense
                  item-text="puesto"
                  item-value="idpuesto"
                  single-line
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  label="Plantel"
                  id="id"
                  filled
                  dense
                  hide-details
                  v-model="plantel"
                  :items="planteles"
                  item-text="plantel"
                  item-value="id_plantel"
                  clearable
                  single-line
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="5">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>

              <v-col cols="12">
                TOTAL TEACHERS: {{ total }} <br/>
                TEACHERS COMPLETOS: {{ porcentaje }}    <br/>
                % AVANCE: {{ porcentaje_avance.toFixed(2) }}   
              </v-col>


              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filterTrabajadores"
                  class="elevation-0"
                  :search="search"
                  :mobile-breakpoint="100"
                  dense
                >

                 <template v-slot:item.idsexo="{ item }">
                    <v-chip 
                      v-if="item.idsexo == 1"
                      small
                      color="green"
                      dark
                    >M</v-chip>

                    <v-chip 
                      v-if="item.idsexo == 2"
                      small
                      color="green"
                      dark
                    >F</v-chip>

                    <v-chip 
                      v-if="item.idsexo == 3"
                      small
                      color="green"
                      dark
                    >Otro</v-chip>
                  </template>

                  <!-- Valores que ocupan botons, chips, iconos, etc -->
                  <template v-slot:item.activo_sn="{ item }">
                    <v-chip 
                      v-if="item.activo_sn == 1"
                      small
                      color="green"
                      dark
                    >Si</v-chip>

                    <v-chip 
                      v-else
                      small
                      color="error"
                      dark
                    >No</v-chip>
                  </template>

                  <!-- Valores que ocupan botons, chips, iconos, etc -->
                  <template v-slot:item.disponibilidad_completa="{ item }">
                    <v-chip 
                      v-if="item.disponibilidad_completa == 1 && item.id_puesto == 10 && item.activo_sn"
                      small
                      color="green"
                      dark
                    >Si</v-chip>

                    <v-chip 
                      v-else-if="item.id_puesto == 10 && item.activo_sn"
                      small
                      color="error"
                      dark
                    >No</v-chip>
                  </template>


                  <template v-slot:item.rolado_ciclo="{ item }">
                    <v-checkbox 
                      dense
                      hide-details
                      v-if="item.idpuesto == 10"
                      label="" 
                      v-model="item.rolado_ciclo" 
                      @click="cambiarRolado(item)"
                    ></v-checkbox>
                  </template>

                  <template v-slot:item.progreso="{ item }">
                    {{ item.avance + ' de ' + item.campos }}
                    <v-progress-linear
                      v-model="item.progreso"
                      height="5"
                      readonly
                      :color="item.progreso >= 100 ? 'green' : 'red'"
                    >
                    </v-progress-linear>

                  </template>

                  <!-- Valores que ocupan botons, chips, iconos, etc -->
                  <template v-slot:item.apoyando="{ item }">
                    <v-chip 
                      v-if="item.apoyando == 1"
                      small
                      color="green"
                      dark
                    >Si</v-chip>

                    <v-chip 
                      v-else
                      small
                      color="error"
                      dark
                    >No</v-chip>
                  </template>

                  

                  <template v-slot:item.antiguedad_2="{ item }">
                    {{ item.antiguedad_2 }}
                  </template>

                  <!-- Valores que ocupan botons, chips, iconos, etc -->
                  <template v-slot:item.aceptado="{ item }">
                    <v-chip 
                      v-if="item.aceptado == 1"
                      small
                      color="green"
                      dark
                    >Aceptados</v-chip>

                    <v-chip 
                      v-if="item.aceptado == 2"
                      small
                      color="red"
                      dark
                    >Rechazados</v-chip>

                    <v-chip 
                      v-if="item.aceptado == 0"
                      small
                      color="grey"
                      dark
                    >Pendiente</v-chip>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      tile
                      @click="editItem(item)"
                      class="mr-2"
                    >
                      <v-icon small>mdi-magnify</v-icon>
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de agregar-->
    <v-dialog
      v-model="dialog_agregar"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.nombres"
                label="Nombre(s)"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.apellido_paterno"
                label="Apellido Paterno"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.apellido_materno"
                label="Apellido Materno"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.domicilio"
                label="Domicilio"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="defaultItem.horario_ingreso" 
                label="Hora Ingreso:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="defaultItem.horario_salida" 
                label="Hora salida:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

             <v-col cols="12" md="6">   
              <v-text-field 
                v-model="defaultItem.horario_ingreso_fin" 
                label="Hora Ingreso Fin:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="defaultItem.horario_salida_fin" 
                label="Hora salida Fin:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
              v-model="defaultItem.telefono1"
              label="Telefono"
              filled
              hide-details
              dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
              v-model="defaultItem.telefono2"
              label="Celular"
              filled
              hide-details
              dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.fecha_ingreso"
                label="Fecha Ingreso"
                filled
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.fecha_termino_labores"
                label="Fecha Termino Labores"
                filled
                type="date"
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
              :items="planteles"
              v-model="defaultItem.id_plantel"
              label="Plantel"
              filled
              hide-details
              dense
              item-text="plantel"
              item-value="id_plantel"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.fecha_nacimiento"
                label="Fecha Nacimiento"
                filled
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.cuenta_hsbc"
                label="Cuenta HSBC"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.numero_tarjeta"
                label="Número Tarjeta"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.tarjeta_secundaria"
                label="Número Tarjeta Secundaria"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.banco"
                label="Banco"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="defaultItem.idpuesto"
                :items="puestos"
                label="Selecciona un puesto"
                persistent-hint
                item-text="puesto"
                item-value="idpuesto"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="defaultItem.dia_descanso"
                :items="[
                  { dia_descanso: 1, nombre: 'Sábado' },
                  { dia_descanso: 2, nombre: 'Domingo'},
                  { dia_descanso: 3, nombre: 'Lunes' },  
                  { dia_descanso: 4, nombre: 'Martes' },  
                  { dia_descanso: 5, nombre: 'Miercoles' },  
                  { dia_descanso: 6, nombre: 'Jueves' },  
                  { dia_descanso: 7, nombre: 'Viernes' }       
                ]"
                label="Selecciona el día de descanso"
                persistent-hint
                item-text="nombre"
                item-value="dia_descanso"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="defaultItem.idsexo"
                :items="[
                  { idsexo: 1, sexo: 'Masculino'},
                  { idsexo: 2, sexo: 'Femenino'},
                  { idsexo: 3, sexo: 'Otro'},   
                ]"
                label="Selecciona el sexo"
                persistent-hint
                item-text="sexo"
                item-value="idsexo"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43, 31,68,].includes( getdatosUsuario.idpuesto )">
              <v-select
                :items="categorias"
                v-model="defaultItem.idcategorias_maestro"
                label="Categoría"
                hide-details
                dense
                filled
                item-text="categoria"
                item-value="idcategorias_maestro"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" v-if="defaultItem.idpuesto == 10">
              <v-select
                :items="disponibilidades"
                v-model="defaultItem.iddisponibilidad_maestros"
                label="Disponibilidad"
                hide-details
                dense
                filled
                item-text="disponibilidad"
                item-value="iddisponibilidad_maestros"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="defaultItem.folio_banco"
                label="Folio Banco"
                filled
                hide-details
                dense
                type="integer"
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto )">
              <v-select
                :items="[{id: 1, text: 'Día'},{id: 2, text:'Hora'}]"
                v-model="defaultItem.tipo_pago"
                label="Tipo de pago"
                hide-details
                dense
                filled
                item-text="text"
                item-value="id"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && defaultItem.tipo_pago == 1">
              <v-autocomplete
                v-if="defaultItem.idpuesto == 19 "
                :items="sueldos"
                v-model="defaultItem.idsueldo_recepcion"
                label="Sueldo Mensual"
                dense
                filled
                item-value="idsueldo_recepcion"
                item-text="sueldo_mensual_texto"
                hide-details
              ></v-autocomplete>
              <v-text-field
                v-else
                v-model="defaultItem.sueldo_mensual"
                label="Sueldo Mensual"
                filled
                hide-details
                dense
                auto-grow
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && defaultItem.tipo_pago == 1">
              <v-autocomplete
                :items="sueldos"
                v-model="defaultItem.sueldo_recepcion"
                label="Categoría"
                dense
                filled
                item-value="idsueldo_recepcion"
                item-text="Sueldo"
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && defaultItem.tipo_pago == 1">
              <v-text-field
                v-model="defaultItem.factor"
                label="Factor"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto )">
              <v-text-field
                v-model="defaultItem.sueldo_hora"
                label="Sueldo Hora"
                filled
                hide-details
                dense
                :readonly="defaultItem.tipo_pago == 1 ? true : false "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && defaultItem.idpuesto == 10">
              <v-text-field
                v-model="defaultItem.sueldo_hora_2"
                readonly
                label="Sueldo Hora 2"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && defaultItem.idpuesto == 10">
              <v-text-field
                v-model="defaultItem.sueldo_hora_3"
                readonly
                label="Sueldo Hora 3"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && defaultItem.tipo_pago == 1">
              <v-text-field
                v-model="defaultItem.sueldo_dia"
                label="Sueldo Día"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[19,18,73].includes( editedItem.idpuesto )">
              <v-select
                :items="categoriasVendedoras"
                v-model="defaultItem.idcategorias_encargadas"
                label="Categría vendedora"
                hide-details
                dense
                filled
                item-text="categoria"
                item-value="idcategorias_encargadas"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :items="vacantes"
                v-model="defaultItem.idseguimiento_campanias"
                label="Vacante"
                clearable
                filled
                dense
                item-text="vacante"
                item-value="idseguimiento_campanias"
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="defaultItem.motivo_salida"
                label="Motivo Salida"
                outlined
                hide-details
                dense
                auto-grow
              ></v-textarea>
            </v-col>

            <v-col cols="12" v-if="defaultItem.idpuesto == 10">
              <label>Disponibilidad L, M, M</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="defaultItem.lm_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="defaultItem.lm_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>

              <label>Disponibilidad J, V</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="defaultItem.jv_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="defaultItem.jv_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>

              <label>Disponibilidad Sábado</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="defaultItem.s_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="defaultItem.s_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>

              <label>Disponibilidad Domingo</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="defaultItem.d_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="defaultItem.d_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6" class="pt-0" v-if="defaultItem.idpuesto == 10">
              <v-switch 
                label="Apoyando Si/No?"
                hide-details
                v-model="defaultItem.apoyando">
              </v-switch>
            </v-col>

            <v-col cols="12" sm="6" class="pt-0">
              <v-switch 
                label="¿Activo Si/No?"
                hide-details
                v-model="defaultItem.activo_sn">
              </v-switch>
            </v-col>

            <v-col cols="12" sm="6" class="pt-5">
              <div class="text-subtitle-1"><b>Fecha alta:</b> {{ defaultItem.fecha_alta }}</div>
              <div class="text-subtitle-1"><b>Fecha baja:</b> {{ defaultItem.fecha_baja }}</div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo de editar-->
    <v-dialog
      v-model="dialog_editar"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle2 }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
           <strong>ID: {{ editedItem.id_trabajador }}</strong>
         </span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.nombres"
                label="Nombre(s)"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.apellido_paterno"
                label="Apellido Paterno"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.apellido_materno"
                label="Apellido Materno"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.domicilio"
                label="Domicilio"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="editedItem.horario_ingreso" 
                label="Hora ingreso:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="editedItem.horario_salida" 
                label="Hora salida:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="editedItem.horario_ingreso_fin" 
                label="Hora ingreso Fin:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">   
              <v-text-field 
                v-model="editedItem.horario_salida_fin" 
                label="Hora salida Fin:" 
                dense 
                type="time"
                filled
                hide-details
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
              v-model="editedItem.telefono1"
              label="Telefono"
              filled
              hide-details
              dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
              v-model="editedItem.telefono2"
              label="Celular"
              filled
              hide-details
              dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha_ingreso"
                label="Fecha Ingreso"
                filled
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha_termino_labores"
                label="Fecha Termino Labores"
                filled
                type="date"
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
              :items="planteles"
              v-model="editedItem.id_plantel"
              label="Plantel"
              filled
              hide-details
              dense
              item-text="plantel"
              item-value="id_plantel"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha_nacimiento"
                label="Fecha Nacimiento"
                filled
                hide-details
                dense
                type="date"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idpuesto"
                :items="puestos"
                label="Selecciona un puesto"
                persistent-hint
                item-text="puesto"
                item-value="idpuesto"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.dia_descanso"
                :items="[
                  { dia_descanso: 1, nombre: 'Sábado' },
                  { dia_descanso: 2, nombre: 'Domingo'},
                  { dia_descanso: 3, nombre: 'Lunes' },  
                  { dia_descanso: 4, nombre: 'Martes' },  
                  { dia_descanso: 5, nombre: 'Miercoles' },  
                  { dia_descanso: 6, nombre: 'Jueves' },  
                  { dia_descanso: 7, nombre: 'Viernes' }       
                ]"
                label="Selecciona el día de descanso"
                persistent-hint
                item-text="nombre"
                item-value="dia_descanso"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idsexo"
                :items="[
                  { idsexo: 1, sexo: 'Masculino'},
                  { idsexo: 2, sexo: 'Femenino'},
                  { idsexo: 3, sexo: 'Otro'},   
                ]"
                label="Selecciona el sexo"
                persistent-hint
                item-text="sexo"
                item-value="idsexo"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                v-if="editedItem.idpuesto == 18 || editedItem.idpuesto == 19"
                filled
                dense
                clearable
                v-model="editedItem.idencargada_experiencia"
                :items="[{idencargada_experiencia: 1, encargada_experiencia: 'Principiante'},
                         {idencargada_experiencia: 2, encargada_experiencia:'Avanzada'}]"
                label="Selecciona la experiencia"
                persistent-hint
                item-text="encargada_experiencia"
                item-value="idencargada_experiencia"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.horas_totales"
                label="Horas Trabajadas Total"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

           <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.nuevo_id_usuario"
                :items="usuarios"
                label="Selecciona un usuario"
                persistent-hint
                prepend-inner-icon="mdi-account"
                hide-details
                item-text="nombre_completo"
                item-value="id_usuario"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43, 31,68].includes( getdatosUsuario.idpuesto ) && editedItem.idpuesto == 10">
              <v-select
                :items="categorias"
                v-model="editedItem.idcategorias_maestro"
                label="Categoría"
                hide-details
                dense
                filled
                item-text="categoria"
                item-value="idcategorias_maestro"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" v-if="editedItem.idpuesto == 10">
              <v-select
                :items="disponibilidades"
                v-model="editedItem.iddisponibilidad_maestros"
                label="Disponibilidad"
                hide-details
                dense
                filled
                item-text="disponibilidad"
                item-value="iddisponibilidad_maestros"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.cuenta_hsbc"
                label="Cuenta HSBC"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.numero_tarjeta"
                label="Número Tarjeta"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.tarjeta_secundaria"
                label="Número Tarjeta secundaria"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.banco"
                label="Banco"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.folio_banco"
                label="Folio Banco"
                filled
                hide-details
                dense
                type="integer"
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto )">
              <v-select
                :items="[{id: 1, text: 'Día'},{id: 2, text:'Hora'}]"
                v-model="editedItem.tipo_pago"
                label="Tipo de pago"
                hide-details
                dense
                filled
                item-text="text"
                item-value="id"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && editedItem.tipo_pago == 1">
              <v-autocomplete
                v-if="editedItem.idpuesto == 19 "
                :items="sueldos"
                v-model="editedItem.idsueldo_recepcion"
                label="Sueldo Mensual"
                dense
                filled
                item-value="idsueldo_recepcion"
                item-text="sueldo_mensual_texto"
                hide-details
              ></v-autocomplete>
              <v-text-field
                v-else
                v-model="editedItem.sueldo_mensual"
                label="Sueldo Mensual"
                filled
                hide-details
                dense
                auto-grow
              ></v-text-field>
            </v-col>
            
            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && editedItem.tipo_pago == 1">
              <v-text-field
                v-model="editedItem.factor"
                label="Factor"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && editedItem.tipo_pago == 1">
              <v-text-field
                v-model="editedItem.sueldo_dia"
                label="Sueldo Día"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto )">
              <v-text-field
                v-model="editedItem.sueldo_hora"
                label="Sueldo Hora"
                filled
                hide-details
                dense
                :readonly="editedItem.tipo_pago == 1 ? true : false "
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && editedItem.idpuesto == 10">
              <v-text-field
                v-model="editedItem.sueldo_hora_2"
                label="Sueldo Hora 2"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[50,51,39,56,11,43,68,43].includes( getdatosUsuario.idpuesto ) && editedItem.idpuesto == 10">
              <v-text-field
                v-model="editedItem.sueldo_hora_3"
                label="Sueldo Hora 3"
                filled
                hide-details
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" v-if="[19,18,73].includes( editedItem.idpuesto )">
              <v-select
                :items="categoriasVendedoras"
                v-model="editedItem.idcategorias_encargadas"
                label="Tipo de pago"
                hide-details
                dense
                filled
                item-text="categoria"
                item-value="idcategorias_encargadas"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                :items="vacantes"
                v-model="editedItem.idseguimiento_campanias"
                label="Vacante"
                clearable
                filled
                dense
                item-text="vacante"
                item-value="idseguimiento_campanias"
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="editedItem.motivo_salida"
                label="Motivo Salida"
                outlined
                hide-details
                dense
                auto-grow
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <label>Disponibilidad L, M, M</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="editedItem.lm_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="editedItem.lm_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>

              <label>Disponibilidad J, V</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="editedItem.jv_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="editedItem.jv_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>

              <label>Disponibilidad Sábado</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="editedItem.s_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="editedItem.s_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>

              <label>Disponibilidad Domingo</label>
              <v-row>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Día" v-model="editedItem.d_d" :value="1"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4" lg="2">
                  <v-checkbox label="Tarde" v-model="editedItem.d_t" :value="1"></v-checkbox>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6" class="pt-0" v-if="editedItem.idpuesto == 10">
              <v-switch 
                label="Apoyando Si/No?"
                hide-details
                v-model="editedItem.apoyando">
              </v-switch>
            </v-col>

            <v-col cols="12" sm="6" class="pt-0">
              <v-switch 
                label="¿Activo Si/No?"
                hide-details
                v-model="editedItem.activo_sn">
              </v-switch>
            </v-col>

            <v-col cols="12" sm="6" class="pt-5">
              <div class="text-subtitle-1"><b>Fecha alta:</b> {{ editedItem.fecha_alta }}</div>
              <div class="text-subtitle-1"><b>Fecha baja:</b> {{ editedItem.fecha_baja }}</div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="update"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo contraseña incorrecta -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Contraseña Incorrecta</strong> <br>Favor de revisar que ambas contraseñas sean iguales.</br>
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text  class="mr-2" @click="closeDialog()">Cerrar</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/eliminar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-text>
          <v-row justify="space-around">
            <v-col cols="6">
              <v-card class="elevation-0" height="100%" style="display: table;">
                <div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
                  <strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
                  <v-card-actions class="pt-6">
                    <!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
                    <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
                    <!-- Guardar la información  -->
                    <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
                  </v-card-actions>
                </div>
              </v-card>
            </v-col>
            <v-col cols="5">
              <v-img src="@/assets/borrar.png"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Sueldos
      v-if="dialogSueldos.estatus"
      :dialogSueldos="dialogSueldos"
      @getSueldos="getSueldos"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import Sueldos               from '@/components/nomina/Sueldos.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
      Sueldos
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,


      activos: null,
      puestos:[],
      puesto_seleccionado: null,
      plantel: null,
      planteles:[],
      perfiles:[],
      puestos:[],
      jornada:[],
      tipo_trabajador:[],
      nivel:[],
      curso:[],
      trabajadores:[],
      trabajadorERP:[],
      usua:[],
      departamento:[],
      valor:0,

      fecha_nacimiento:0,
      fecha_termino_labores:null,
      fecha_ingreso:0,

      dialog_agregar: false,
      dialog_editar: false,
      dialogDelete: false,
      dialog: false,
      
      editedIndex: -1,

      dialogSueldos: {
        estatus: false
      },

      editedItem: {
        nombres:'',                 
        apellido_paterno:'',           
        apellido_materno:'',           
        domicilio:'',                  
        horario_inicio: null,             
        horario_salida: null,             
        horario_inicio_fin: null,             
        horario_salida_fin: null,      
        telefono1:'',                  
        telefono2:'',                  
        email:'',                      
        rfc:'',                        
        numero_poliza_sgm_mayores:'',  
        numero_poliza_sgm_menores:'',  
        numero_imss:'',                
        fecha_ingreso: null,              
        fecha_termino_labores: null,      
        antiguedad:'',                 
        id_plantel:null,               
        idpuesto:null,   
        dia_descanso:0, 
        idsexo: null,  
        idencargada_experiencia:null,  
        horas_totales:null,             
        id_jornada_laboral:null,        
        id_departamento:null,           
        id_tipos_trabajadores:null,      
        id_nivel:null,                   
        id_curso:null,                   
        fecha_nacimiento:null,           
        motivo_salida:'',             
        activo_sn:'',                  
        id:null,
        numero_tarjeta: 0,
        banco: 0,
        folio_banco: 0,
        factor: 0,
        sueldo_hora_2:0,
        sueldo_hora_3:0,
        sueldo_hora:  0,
        apoyando: 0,
        nuevo_id_usuario: 0,
        idcategorias_maestro: 0,
        iddisponibilidad_maestros: 0,
        rolado_ciclo: false,
        lm_d: 0,
        lm_t:0,
        jv_d:0,
        jv_t:0,
        s_d:0,
        s_t: 0,
        d_d:0,
        d_t:0,
        numero_tarjeta_anterior:0,
        idcategorias_maestro_anterior:0,
        tarjeta_secundaria:'',
        idcategorias_encargadas:0,
        cuenta_hsbc:0,
        idsueldo_recepcion:0,
        idseguimiento_campanias:0,
      },

      defaultItem: {
        nombres:'',                 
        apellido_paterno:'',           
        apellido_materno:'',           
        domicilio:'',                  
        horario_inicio: null,             
        horario_salida: null,  
        horario_inicio_fin: null,             
        horario_salida_fin: null,                
        telefono1:'',                  
        telefono2:'',                  
        email:'',                      
        rfc:'',                        
        numero_poliza_sgm_mayores:'',  
        numero_poliza_sgm_menores:'',  
        numero_imss:'',                
        fecha_ingreso: null,              
        fecha_termino_labores: null,      
        antiguedad:'',                 
        id_plantel:null,               
        idpuesto:0,     
        dia_descanso:0,
        idsexo: null,
        idencargada_experiencia:null,     
        horas_totales:null,            
        id_jornada_laboral:0,        
        id_departamento:0 ,          
        id_tipos_trabajadore: 0,      
        id_nivel:0,                   
        id_curso:0,                   
        fecha_nacimiento:null,           
        motivo_salida:'',             
        activo_sn:'',                  
        id:null,
        numero_tarjeta: 0,
        banco: 0,
        folio_banco: 0,
        factor: 0,
        sueldo_hora_2:0,
        sueldo_hora_3:0,
        sueldo_hora:  0,
        apoyando: 0,
        nuevo_id_usuario: 0,
        idcategorias_maestro: 0,
        iddisponibilidad_maestros: 0,
        rolado_ciclo: false,
        lm_d: 0,
        lm_t:0,
        jv_d:0,
        jv_t:0,
        s_d:0,
        s_t: 0,
        d_d:0,
        d_t:0,
        numero_tarjeta_anterior:0,
        idcategorias_maestro_anterior:0,
        tarjeta_secundaria:'',
        idcategorias_encargadas:0,
        cuenta_hsbc:0,
        idsueldo_recepcion:0,
        idseguimiento_campanias:0,
      },

      baseItem: {
        nombres:'',                 
        apellido_paterno:'',           
        apellido_materno:'',           
        domicilio:'',                  
        horario_inicio: null,             
        horario_salida: null,     
        horario_inicio_fin: null,             
        horario_salida_fin: null,             
        telefono1:'',                  
        telefono2:'',                  
        email:'',                      
        rfc:'',                        
        numero_poliza_sgm_mayores:'',  
        numero_poliza_sgm_menores:'',  
        numero_imss:'',                
        fecha_ingreso: null,              
        fecha_termino_labores: null,      
        antiguedad:'',                 
        id_plantel:0,               
        idpuesto:0,   
        dia_descanso:0,  
        idsexo: null, 
        idencargada_experiencia:null,      
        horas_totales:null,             
        id_jornada_laboral:0,        
        id_departamento:0 ,          
        id_tipos_trabajadore: 0,      
        id_nivel:0,                   
        id_curso:0,                   
        fecha_nacimiento:null,           
        motivo_salida:'',             
        activo_sn:'',                  
        id:null,
        numero_tarjeta: 0,
        banco: 0,
        folio_banco: 0,
        factor: 0,
        sueldo_hora_2:0,
        sueldo_hora_3:0,
        sueldo_hora:  0,
        apoyando: 0,
        nuevo_id_usuario: 0,
        idcategorias_maestro: 0,
        iddisponibilidad_maestros: 0,
        rolado_ciclo: false,
        lm_d: 0,
        lm_t:0,
        jv_d:0,
        jv_t:0,
        s_d:0,
        s_t: 0,
        d_d:0,
        d_t:0,
        numero_tarjeta_anterior:0,
        idcategorias_maestro_anterior:0,
        tarjeta_secundaria:'',
        idcategorias_encargadas:0,
        cuenta_hsbc:0,
        idsueldo_recepcion:0,
        idseguimiento_campanias:0,
     },

     categoriasVendedoras:[],

      // Filtros: 
     incio:null,
     fin:null,

      // tabla
     search:'',
     page: 1,
     pageCount: 0,
     itemsPerPage: 10,
     horarios: [],
     headers: [
      { text: 'ID'                    , value: 'id_trabajador'   },
      { text: 'Trabajador'            , value: 'nombre_completo' },
      { text: 'Sexo'                  , value: 'idsexo'          },
      { text: 'Puesto'                , value: 'puesto'          },
      { text: 'Depto'                 , value: 'departamento'    },
      { text: 'Sucursal'              , value: 'plantel'         },
      { text: 'Antigüedad'            , value: 'antiguedad_2'    },
      { text: '¿Activo?'              , value: 'activo_sn'                  },
      { text: 'Disponibilidad'        , value: 'disponibilidad_completa'    },
      { text: 'Apoyando?'             , value: 'apoyando'        },
      { text: 'Progreso'              , value: 'progreso'        },
      { text: 'Aceptados'             , value: 'aceptado'        },
      { text: 'Acciones'              , value: 'actions', sortable: false },
      ],

      usuarios: [],
      disponibilidades:[],
      categorias:[],
      sueldos: [],
      vacantes:[]

    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return 'Agregar Usuario'
      },

      formTitle2 () {
        return 'Editar Usuario'
      },

      filterTrabajadores( ){
        let data = this.trabajadorERP

        if( this.plantel ){
          data = data.filter( el=> { return el.id_plantel == this.plantel })
        }

        if( this.activos == 'Activos'){
          data = data.filter( el=> { return el.activo_sn == 1 })
        }

        if( this.activos == 'Inactivos'){
          data = data.filter( el=> { return el.activo_sn == 0 })
        }

        if( this.puesto_seleccionado ){
          data = data.filter( el=> { return el.idpuesto == this.puesto_seleccionado })
        }

        return data 
      },

      total( ){
        let total = this.trabajadorERP.filter( el => el.activo_sn && el.id_puesto == 10 ).length
        return total
      },

      porcentaje( ){
        let total = this.trabajadorERP.filter( el => el.activo_sn && el.id_puesto == 10 && el.disponibilidad_completa ).length
        return total
      },

      porcentaje_avance( ){

        let total =  ( this.trabajadorERP.filter( el => el.activo_sn && el.id_puesto == 10 && el.disponibilidad_completa ).length / this.total ) * 100


        return total
      },


    },

    watch: {
      dialog_agregar (val) {
        val || this.close()
      },
      dialog_editar (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      "editedItem.factor"( val ){
        if( this.editedItem.sueldo_mensual || this.editedItem.idsueldo_recepcion){
          if( this.editedItem.idpuesto == 19 ){
            // Si el puesto es 19, se hace algo diferente, ya que tiene una categoría
            if( this.editedItem.idsueldo_recepcion ){

              const sueldo = this.sueldos.find( el => el.idsueldo_recepcion == this.editedItem.idsueldo_recepcion )

              if( sueldo ){

                this.editedItem.sueldo_mensual = sueldo.sueldo_mensual
                this.editedItem.sueldo_dia     = ( sueldo.sueldo_mensual / val ).toFixed( 2 )
                this.editedItem.sueldo_hora    = ( ( sueldo.sueldo_mensual / val ) / 8 ).toFixed( 2 )

              }

            }

          }else{
            this.editedItem.sueldo_dia = ( this.editedItem.sueldo_mensual / val ).toFixed( 2 )
            this.editedItem.sueldo_hora = ( ( this.editedItem.sueldo_mensual / val ) / 8 ).toFixed( 2 )
          }
        }
      },

      "defaultItem.factor"( val ){
        if( this.defaultItem.sueldo_mensual || this.defaultItem.idsueldo_recepcion){
          if( this.defaultItem.idpuesto == 19 ){
            // Si el puesto es 19, se hace algo diferente, ya que tiene una categoría
            if( this.defaultItem.idsueldo_recepcion ){

              const sueldo = this.sueldos.find( el => el.idsueldo_recepcion == this.defaultItem.idsueldo_recepcion )

              if( sueldo ){

                this.defaultItem.sueldo_mensual = sueldo.sueldo_mensual
                this.defaultItem.sueldo_dia     = ( sueldo.sueldo_mensual / val ).toFixed( 2 )
                this.defaultItem.sueldo_hora    = ( ( sueldo.sueldo_mensual / val ) / 8 ).toFixed( 2 )

              }

            }

          }else{
            this.defaultItem.sueldo_dia = ( this.defaultItem.sueldo_mensual / val ).toFixed( 2 )
            this.defaultItem.sueldo_hora = ( ( this.defaultItem.sueldo_mensual / val ) / 8 ).toFixed( 2 )
          }
        }
      },

      "defaultItem.idcategorias_maestro"( val ){
        if( val ){ 
          const existeCatego = this.categorias.find( el => el.idcategorias_maestro == val )
          this.defaultItem.sueldo_hora = existeCatego.hora_1
          this.defaultItem.sueldo_hora_2 = existeCatego.hora_2
          this.defaultItem.sueldo_hora_3 = existeCatego.hora_3
        }
      },

      "editedItem.idcategorias_maestro"( val ){
        if( val ){ 
          const existeCatego = this.categorias.find( el => el.idcategorias_maestro == val )
          this.editedItem.sueldo_hora = existeCatego.hora_1
          this.editedItem.sueldo_hora_2 = existeCatego.hora_2
          this.editedItem.sueldo_hora_3 = existeCatego.hora_3
        }
      },


      "defaultItem.nombres"( value ){
        this.defaultItem.nombres = value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      },

      "defaultItem.apellido_paterno"( value ){
        this.defaultItem.apellido_paterno = value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      },

      "defaultItem.apellido_materno"( value ){
        this.defaultItem.apellido_materno = value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      },

      "editedItem.nombres"( value ){
        this.editedItem.nombres = value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      },

      "editedItem.apellido_paterno"( value ){
        this.editedItem.apellido_paterno = value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      },

      "editedItem.apellido_materno"( value ){
        this.editedItem.apellido_materno = value.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      },



    },

    async created () {
      await this.getUsuarios()
      await this.initialize()
      await this.getPlanteles()
      await this.getPuestos()
      await this.getCategorias()
      await this.getDisponibilidad()
      await this.getCategoriaVendedoras()
      await this.getSueldos()
      await this.getVacantes()
    },

    methods: {
      initialize () {  
        this.cargar = true
        this.trabajadorERP = []
        return this.$http.get('trabajadores.erp.get.trabajadoreserp').then(response=>{
          /*
            nombres
            apellido_paterno
            domicilio
            horario_ingreso
            horario_salida
            telefono1
            fecha_ingreso
            id_plantel
            fecha_nacimiento
            numero_tarjeta
            banco
            idpuesto
            tipo_pago
            sueldo_mensual
            factor

            solo si el idpuesto es 10
            idcategorias_maestro
            iddisponibilidad_maestros
          */
          this.trabajadorERP = response.data

          // Agregarle el progreso a los usuarios
          for( const i in this.trabajadorERP ){

            let cantidad = 0

            const { nombres, apellido_paterno, domicilio, horario_ingreso, horario_salida,  horario_ingreso_fin, horario_salida_fin, telefono1, fecha_ingreso, id_plantel, fecha_nacimiento, numero_tarjeta, banco, idpuesto, dia_descanso, tipo_pago, sueldo_mensual, factor, idcategorias_maestro, iddisponibilidad_maestros, lm_d, lm_t, jv_d, jv_t, s_d, s_t, d_d, d_t } = this.trabajadorERP[i]

            if( nombres                   ){ cantidad += 1 }
            if( apellido_paterno          ){ cantidad += 1 }
            if( domicilio                 ){ cantidad += 1 }
            if( telefono1                 ){ cantidad += 1 }
            if( fecha_ingreso             ){ cantidad += 1 }
            if( id_plantel                ){ cantidad += 1 }
            if( fecha_nacimiento          ){ cantidad += 1 }
            if( numero_tarjeta            ){ cantidad += 1 }
            if( banco                     ){ cantidad += 1 }
            if( idpuesto                  ){ cantidad += 1 }
            if( dia_descanso              ){ cantidad += 1 }
            if( tipo_pago                 ){ cantidad += 1 }

            if( idpuesto == 10 ){
              
              if( idcategorias_maestro      ){ cantidad += 1 }
              if( iddisponibilidad_maestros ){ cantidad += 1 }
              if( lm_d || lm_t || jv_d || jv_t || s_d || s_t || d_d || d_t ){ cantidad += 1 }

              this.trabajadorERP[i]['progreso'] = (( cantidad / 14 ) * 100).toFixed( 2 )
              this.trabajadorERP[i]['campos']   = 14 
              this.trabajadorERP[i]['avance']   = cantidad

            }else{

              if( horario_ingreso           ){ cantidad += 1 }
              if( horario_salida            ){ cantidad += 1 }
              if( horario_ingreso_fin       ){ cantidad += 1 }
              if( horario_salida_fin        ){ cantidad += 1 }
              if( sueldo_mensual            ){ cantidad += 1 }
              if( factor                    ){ cantidad += 1 }

              this.trabajadorERP[i]['progreso'] = (( cantidad / 15 ) * 100).toFixed( 2 )
              this.trabajadorERP[i]['campos']   = 15
              this.trabajadorERP[i]['avance']   = cantidad

            }

          }

          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getSueldos( ){
        this.cargar = true
        this.sueldos = []
        return this.$http.get('usuarios.sueldos').then(response=>{
          this.sueldos = response.data
          this.cargar      = false
          this.dialogSueldos.estatus = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getVacantes () {
        this.cargar = true
        this.vacantes = []
        
        const payload = {
          fechaini: this.filtroFechaIni,
          fechafin: this.filtroFechaFin
        }

        return this.$http.post('seguimiento.vacantes', payload ).then(response=>{
          this.vacantes = response.data.filter( el=> el.estatus == 1 )
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      save () {   
        let id_plantel             = this.planteles.find( el=> el.id_plantel         ==  this.defaultItem.id_plantel )

        if( !id_plantel){ return this.validarErrorDirecto('Favor de seleccionar un plantel') }

        //----------------------------------------------------------------------------------------------------------------------------------------

        this.$http.post('trabajadores.erp.add.trabajadoreserp', this.defaultItem).then(response=>{
          this.dialog_agregar = null,
          this.validarSuccess( response.data.message )
          this.close()
          this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })  
      },

      getUsuarios( ){
        this.cargar = true
        this.usuarios = []
        this.$http.get('usuarios.all.erp').then(response=>{
          this.usuarios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      cambiarRolado( value ){

        value.rolado_ciclo  = value.rolado_ciclo ? 1: 0
        this.cargar = true
        this.$http.post('trabajadores.erp.update.trabajadorerp', value ).then(response => {   
          this.validarSuccess( response.data.message )
          this.dialog_editar = false
          this.initialize()
          this.close()  
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCategorias( ){
        this.cargar     = true
        this.categorias = []

        this.$http.get('trabajadores.categorias').then(response => {   

          this.categorias = response.data

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCategoriaVendedoras( ){
        this.cargar     = true
        this.categoriasVendedoras = []

        this.$http.get('trabajadores.categorias.vendedoras').then(response => {   

          this.categoriasVendedoras = response.data

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getDisponibilidad( ){
        this.cargar     = true
        this.disponibilidades = []

        this.$http.get('trabajadores.disponibilidad').then(response => {   

          this.disponibilidades = response.data

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      update () {
        this.cargar = true
        this.$http.post('trabajadores.erp.update.trabajadorerp', this.editedItem ).then(response => {   
          this.validarSuccess( response.data.message )
          this.dialog_editar = false
          this.initialize()
          this.close()  
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPerfiles () {
      	this.cargar = true
        this.perfiles = []
        return this.$http.get('usuarios.erp.get.perfilesERP').then(response=>{
        	this.perfiles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPuestos () {
        this.puestos = []
        this.$http.get('puestos.all').then(response=>{
          this.cargar = false
          this.puestos = response.data
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        console.log( item )
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog_editar = true
      },

      deleteItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('horarios.eliminar/' + this.editedItem.id_horario, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close() {
        this.dialog_editar = false
        this.dialog_agregar = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.baseItem)
          this.defaultItem = Object.assign({}, this.baseItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.baseItem)
          this.editedIndex = -1
        })
      },

      closeDialog () {
        this.dialog = false
      },

      exportarDataExcel( ){

        // const alumnos = this.filterTrabajadores.map(({ id_trabajador, nombre_completo, puesto, plantel, sueldo_mensual, sueldo_dia, sueldo_hora, factor }) => ({ id_trabajador, nombre_completo, puesto, plantel, sueldo_mensual, sueldo_dia, sueldo_hora, factor }));

        // this.exportExcel( alumnos, 'Trabajadores')
        this.exportExcel( this.filterTrabajadores, 'Trabajadores')

      }
    },
  }
</script>

