<template>
    <v-container>
        <v-dialog persistent v-model="isOpen" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="600px" @keydown.ctrl="pegar">
            <v-card>
                <v-card-title>
                    <span class="headline">Folio: {{ ticket.idticket }}</span>
                    <v-spacer></v-spacer>
                    <v-btn fab small text @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-container v-for="(respuestas, i) in historial" :key="i" >
                        <v-row justify="space-between" v-if="i==0" >
                            <v-col cols="6" class="d-flex justify-start py-2 px-0">
                                <p class="ma-0"><strong>Motivo del ticket</strong></p>
                            </v-col>
                            <v-col cols="6" class="d-flex justify-end py-2 px-0">
                                <p class="ma-0">{{ respuestas.fecha }}</p>
                            </v-col>
                        </v-row>
                        <mensajeIzq :respuestas="respuestas" :ticket="ticket"></mensajeIzq>
                        <mensajeDer :respuestas="respuestas" :ticket="ticket"></mensajeDer>
                    </v-container>
                    <v-container class="px-0 pb-0">
                        <v-divider></v-divider>
                        <v-textarea class="mt-4" rows="3" outlined v-model="respuestaAuxiliar" label="Solución"></v-textarea>
                        
                        <v-file-input small-chips multiple filled dense single-line v-model="files" 
                            accept="image/png, image/jpeg, image/bmp, video/mp4, video/x-m4v, video/*"
                            placeholder="Selecciona tus imagenes" prepend-icon="mdi-camera"
                            label="Imágenes" @change="cargarFotos()"
                        ></v-file-input>
    
                        <v-row class="mb-4">
                        <v-col cols="3" v-for="(img, i) in vistaPrevias" :key="i">
                            <v-card class="py-4 shadowCard">
                            <v-img v-if="!img.video" :src="img.url" contain aspect-ratio="2"></v-img>
                            <video v-else :src="img.url" contain aspect-ratio="2" style="width: 100%; height: 100%;" class="d-flex"></video>
                            <v-btn color="error" x-small @click="eliminarFoto(img.url)" top right absolute fab>
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            </v-card>
                        </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-btn color="blue" dark small @click="cargarNotas()">Ver notas {{ notas.length }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" dark small @click="saveRespuestaAuxiliar()">
                        Enviar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
        <carga                v-if="cargar"/>
    </v-container>
</template>
<script>
    import axios from 'axios';
    import {mapGetters} from 'vuex'
    import validarErrores  from '@/mixins/validarErrores'
    import mensajeIzq            from '@/components/tickets/mensajeIzq.vue'
    import mensajeDer            from '@/components/tickets/mensajeDer.vue'
    import Alerta                from '@/components/alertas/Alerta.vue'
    import carga                 from '@/components/alertas/carga.vue'
    import { Clipboard } from "@capacitor/clipboard";

    export default {
        components:{
            mensajeIzq,
            mensajeDer,
            Alerta,
            carga
        },

        mixins: [ validarErrores ],

        props: [
            'isOpen',
            'ticket',
            'historial',
            'notas'
        ],
        computed: {
        ...mapGetters('login',['getdatosUsuario']),
        },

        watch:{
            isOpen(){
                // si el dialogo historial se abre
                if(this.isOpen){
                    this.lastHistorial = this.historial[this.historial.length-1]
                }
            },
        },

        data: () => ({
            lastHistorial:{},
            vistaPrevias:[],
            files: [],
            respuestaAuxiliar:'',
            cargar:false,
            respuestaAlerta:false,
        }),

        methods:{
            closeDialog(){
                this.cancelarRespuestaAuxi()
                this.$emit('close');        
            },
            cargarNotas(){
                this.$emit('notas')
            },
            cancelarRespuestaAuxi(){
                this.vistaPrevias = []
                this.files = []
                this.respuestaAuxiliar =''
            },
            async saveRespuestaAuxiliar(){
                if(this.respuestaAuxiliar != ''){
                    this.cargar = true
                    let fotos = []
                    if(this.vistaPrevias.length){
                        let respuesta = await this.subirFotosServidor( )
                        fotos = respuesta.data
                    }
                    var payload = {
                        idhistorial_ticket: this.lastHistorial.idhistorial_ticket,
                        respuestaauxi:      this.respuestaAuxiliar,
                        respuesta:          '',
                        estatus:            0,
                        fotos,
                        idauxi_area:        this.getdatosUsuario.id_usuario,
                        idjefe_area:        0,
                    }

                    this.$http.put('tickets.update.estatus/'+this.ticket.idticket,{estatus: 3}).then(response=>{
                    }).catch( error =>{
                        this.validarError( error.response.data.message )
                    }).finally( () => { 
                    })

                    this.$http.put('historial_tickets.update/'+ this.lastHistorial.idhistorial_ticket, payload).then(response=>{
                        
                    }).catch(error=>{
                    this.error = error.body.message
                    this.dialogError = true
                    this.dialogRechazar = false
                    }).finally( () => { 
                        this.cargar = false
                        this.$emit('recargar');
                        this.closeDialog()
                    })
                } else {
                    this.validarErrorDirecto( 'Mensaje vacio' )
                }
            },

            /*FUNCIONES DE CARGA DE IMAGENES*/
            cargarFotos(){
                if(this.files.length < 1){
                    return
                }

                this.files.forEach((element, index) => {
                // creamos una variable tipo FormData
                let formData = new FormData();
                //se crea el objeto y se le agrega como un apendice el archivo 
                formData.append('file', element);
                //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
                if(element.size > 26214400){ //26214400 = 25MB
                    this.validarErrorDirecto('El archivo sobrepasa el limite del peso, max 25 MB')
                } else {
                    this.getBase64(element, formData)
                }
                })
                
            },
            //eliminar la foto de las vistas previas
            eliminarFoto(value){
                this.vistaPrevias.forEach((element, index)=>{
                    if(element.url == value){
                        this.vistaPrevias.splice(index,1);
                    }
                })
            },
            getBase64(file, formData) {
                var me = this
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                var video = false
                const nombreSplit = file.name.split('.')
                const extension = nombreSplit[nombreSplit.length-1]
                if(['mp4', 'mov', 'wmv', 'avi'].includes(extension)){
                    video = true
                }
                me.vistaPrevias.push({
                        url: reader.result, 
                        formData: formData, 
                        image_name: file.name,
                        video : video,
                        file
                    })
                };
            },
            subirFotosServidor( ){
                let formData = new FormData();

                for( const i in this.vistaPrevias ){
                formData.append('file', this.vistaPrevias[i].file )
                }

                return new Promise((resolve,reject)=>{
                // obtener la extensión del archivo
                // Hacemos la petición
                this.$http.post(`tickets.subir.evidencia`, formData ).then(response=>{
                    resolve( response )
                }).catch( error =>{
                    this.validarError( error.response.data.message )
                }).finally( () => { this.cargar = false })
                })
            },
            convertFile(dataurl, filename) {
                var arr = dataurl.split(","),
                    mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]),
                    n = bstr.length,
                    u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }

                return new File([u8arr], filename, { type: mime });
            },
            createFile(file) {
                if (file.size > 10000000) {
                    alert("please check file size no over 5 MB.");
                    this.dragging = false;
                    return;
                }

                this.file = file;
                this.archivos = file;
                this.getBase64(this.archivos);
                this.dragging = false;
            },
            async pegar(event) {
                if (event.code == "KeyV") {
                    const { type, value } = await Clipboard.read();

                    // Validamos que sea de tipo imagen
                    if (type == "image/png") {
                    // Convertimos el base64 en una imagen
                    const file = this.convertFile(value, "imagen.png");
                    this.createFile(file);
                    }
                }
            },
        },
        
    }
</script>