export default {

	methods: {

		initialize () {
      this.leds = []
      this.cargar = true
      return this.$http.get('leds.list/' + this.escuela).then(response=>{
      	this.leds = response.data
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    getContactosAsignados ( ){
      const payload = {
        fecha_inicio: this.fecha_inicio,
        fecha_final:  this.fecha_final,
        escuela:      this.escuela
      }
      this.$http.post('data.dashboard.marketing', payload).then((response) => {
        console.log( response.data )
        this.contactosVendedora  = response.data.planteles_list
        this.vendedorasDesglose  = response.data.vendedoras
        console.log( response.data )
        return
      }).catch(error=>{
        this.cargar = false
      })
    },

    /****************************/
    /****    	 CATALGOS      ****/
    /****************************/
    getComoNosConocio () {
      this.medios_contacto = []
      this.cargar = true
      return this.$http.get('mediocontacto.activas').then(response=>{
      	this.medios_contacto = response.data
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    getFuntes () {
      this.fuentes = []
      this.cargar = true
      return this.$http.get('fuentes.activos').then(response=>{
      	this.fuentes = response.data.filter( el => { return el.vendedora == 0 })
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },
    
    getDetallesFuente () {
      this.detalles = []
      this.cargar = true
      return this.$http.get('fuentes.detalle.activos').then(response=>{
      	this.detalles = response.data
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    // Obtener las campañas
    getCampanias(){
      this.cargar = true
      this.campanias  = []
      return this.$http.get('campanias.activos').then((response) => {
        // Llenamos la etapa incial
        this.campanias = response.data
        this.campania  = this.campanias[0]
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    // obtener las vendedoras
    getVendedoras() {
      this.cargar = true
      this.vendedoras  = []
      return this.$http.get('vendedoras.list').then((response) => {
        // Llenamos la etapa incial
        this.vendedoras = response.data
        this.vendedoras_plantel = this.vendedoras.filter(el=> { return el.escuela == this.escuela})

        console.log( this.vendedoras_plantel )
        this.vendedoras_plantel.push({idplantel: 0, plantel:'SIN PLANTEL', nombre:'NA', nombre_plantel: 'SIN PLANTEL'})
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    /****************************/
    /****   	FUNCIONES      ****/
    /****************************/

    // Validamos los datos esten correctos
    grabarProspecto() {
      this.cargar = true

      if(this.prospecto.telefono < 1000000000){
        this.cargar = false
        this.validarErrorDirecto('El teléfono debe contar con 10 caracteres')
        return
      }

      this.cargar = true 

      if(this.prospecto.telefono == ''){
        return this.validarErrorDirecto('Favor de llenar el teléfono')
      }

      if(this.prospecto.nota_inicial == ''){
        return this.validarErrorDirecto('Favor de llenar la nota inicial')
      }

      // Sacamos a la vendedora
      let vendedora = 0

      if(this.prospecto.sucursal.idplantel != 0){
        vendedora = this.vendedoras_plantel.find(el=> el.idplantel == this.prospecto.sucursal.idplantel)
      }
      //recibe los campos del form
      //guardo en una variable los datos a guardar id = this.prospecto.idcurso;
      var payload = {
        nombre_completo:  this.prospecto.nombre_completo,
        telefono:         this.prospecto.telefono,
        correo:           this.prospecto.email,
        sucursal_interes: this.prospecto.sucursal.idplantel ? this.prospecto.sucursal.idplantel : 0,
        usuario_creo:     this.getdatosUsuario.iderp,
        usuario_asignado: vendedora ? vendedora.iderp : 0,
        nota_inicial:     this.prospecto.nota_inicial,
        folio:            '',
        acronimo:         this.prospecto.sucursal.idplantel == 0 ? '' : this.prospecto.sucursal.acronimo,
        escuela:          this.escuela,
        idpuesto:         this.getdatosUsuario.idpuesto,
        como_llego:       this.getdatosUsuario.idpuesto == 25 ? this.campania.idcampanias : 0,
        idleds:           this.prospecto.idleds
      }

      this.cargar = false


      this.cargar = true
      this.$http.post('prospectos.add', payload).then(response  => {
        this.cargar = false
        this.dialogValidarEscuela = false
        this.dialogNuevoProspecto = false
        this.dialogValidarEscuela = false
        this.prospecto = {
          idprospectos     : 0,
          nombre_completo  : '',
          telefono         : '',
          correo           : '',
          idetapa          : 0,
          sucursal_interes : 0,
          como_nos_conocio : 0,
          ciclo_interes    : 0,
          curso_interes    : 0,
          usuario_asignado : 0,
          usuario_creo     : 0,
          nota_inicial     : '',
          folio            : ''
        }

        this.sucursal  = null
        this.anuncio   = null
        this.vendedora = null
        this.dialogSuccess = true
        this.getContactosAsignados()
        this.initialize();
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })

    },

    getCursosEscuela() {
      this.cargar = true
      this.cursos_escuela  = []
      return this.$http.get('escuela.cursos.activos').then((response) => {
        // Llenamos la etapa incial
        this.cursos_escuela = response.data.filter( el=> { return el.escuela == this.escuela })
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    preLlenarDatos ( item ) {
    	this.prospecto.nombre_completo = item.nombre_completo
    	this.prospecto.idleds          = item.idleds
    	this.dialogNuevoProspecto      = true
    },
    
    checkRespondio(item){
      this.$http.put('leds.update.respondio', item).then(response=>{
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },
    
    // La escuela y datos fueron validados, hay que grabar al prospecto
		escuelaValidada(){
      // Sacamos a la vendedora
      let vendedora = 0
      if(this.prospecto.sucursal.idplantel != 0){
        vendedora = this.vendedoras_plantel.find(el=> el.idplantel == this.prospecto.sucursal.idplantel)
      }
      //recibe los campos del form
      //guardo en una variable los datos a guardar id = this.prospecto.idcurso;
      var payload = {
        nombre_completo:  this.prospecto.nombre_completo,
        telefono:         this.prospecto.telefono,
        correo:           this.prospecto.email,
        sucursal_interes: this.editedItem.sucursal.idplantel ? this.editedItem.sucursal.idplantel : 0,
        usuario_creo:     this.getdatosUsuario.iderp,
        usuario_asignado: vendedora ? vendedora.iderp : 0,
        nota_inicial:     this.prospecto.nota_inicial,
        folio:            '',
        acronimo:         this.prospecto.sucursal.idplantel == 0 ? '' : this.prospecto.sucursal.acronimo,
        escuela:          this.escuela,
        idpuesto:         this.getdatosUsuario.idpuesto,
        como_llego:       this.getdatosUsuario.idpuesto == 25 ? this.campania.idcampanias : 0,
        idleds:           this.prospecto.idleds
      }

      this.cargar = true
      this.$http.post('prospectos.add', payload).then(response  => {
        this.cargar = false
        this.dialogValidarEscuela = false
        this.dialogNuevoProspecto = false
        this.dialogValidarEscuela = false
        this.prospecto = {
          idprospectos     : 0,
          nombre_completo  : '',
          telefono         : '',
          correo           : '',
          idetapa          : 0,
          sucursal_interes : 0,
          como_nos_conocio : 0,
          ciclo_interes    : 0,
          curso_interes    : 0,
          usuario_asignado : 0,
          usuario_creo     : 0,
          nota_inicial     : '',
          folio            : ''
        }

        this.sucursal  = null
        this.anuncio   = null
        this.vendedora = null
        this.dialogSuccess = true
        this.getContactosAsignados()
        this.initialize();
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    addContacto ( lead , idleds ) {
      // Sacamos a la vendedora
      let vendedora = 0
      if(this.editedItem.sucursal.idplantel != 0){
        vendedora = this.editedItem.sucursal
      }

      //recibe los campos del form
      //guardo en una variable los datos a guardar id = this.editedItem.idcurso;
      var payload = {
        nombre_completo:  lead.nombre_completo,
        telefono:         lead.telefono,
        correo:           lead.email,
        sucursal_interes  : this.editedItem.sucursal.idplantel ? this.editedItem.sucursal.idplantel : 0,
        sucursal_interes_2: this.editedItem.id_sucursal_interes,
        curso_interes     : this.editedItem.curso_interes,
        horario_interes   : this.editedItem.horario_interes,
        usuario_creo:     this.getdatosUsuario.iderp,
        usuario_asignado: vendedora ? vendedora.iderp : 0,
        nota_inicial:     lead.nota_inicial,
        folio:            '',
        acronimo:         this.editedItem.sucursal.idplantel == 0 ? '' : this.editedItem.sucursal.acronimo,
        escuela:          this.escuela,
        idpuesto:         this.getdatosUsuario.idpuesto,
        como_llego:       this.getdatosUsuario.idpuesto == 25 ? this.campania.idcampanias : 0,
        idleds:           idleds
      }

      this.cargar = true

      this.$http.post('prospectos.add', payload).then(response  => {
        this.cargar = false
        this.editedItem           = Object.assign({}, this.defaultItem)
        this.editedItem.sucursal  = null
        this.anuncio              = null
        this.vendedora            = null
        this.validarSuccess( 'Contacto generado correctamente' )
        this.close()
        this.getContactosAsignados()
        this.initialize();
        this.cargar      = false
      }).catch( error =>{
        console.log( error )
        // Hay que eliminar el lead
        this.eliminarLead( idleds )
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    eliminarLead( idleds ){
      this.$http.delete('leds.delete/' + idleds ).then(response  => {
        this.getContactosAsignados()
        this.initialize();
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

		save ( item ) {
      
    	if(!this.editedItem.idmedio_contacto){
    		return this.validarErrorDirecto( 'Favor de llenar el ¿Cómo nos conocio?' )
    	}

      // if(!this.editedItem.id_sucursal_interes){
      //   return this.validarErrorDirecto( 'Favor de llenar sucursal de interés' )
      // }

      // Ponerle pendiente a su nombre completo
      if(this.editedItem.nombre_completo == ''){ this.editedItem.nombre_completo = 'Pendiente' }

    	// Activamos el loader
      this.cargar = true

      // Lo mandapos por el EP
      this.$http.post('leds.add', this.editedItem).then(response=>{
        if( this.editedItem.telefono ){
          this.addContacto( this.editedItem , response.data.id )
        }else{
        	this.validarSuccess( 'Lead agregado correctamente ')
          this.close()
          this.getContactosAsignados()
        	this.initialize()
        }
      	this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    deleteItemConfirm () {
      // Lo mandapos por el EP
      this.editedItem.deleted = 1
      this.cargar = true
      this.$http.put('fuentes.update/' + this.editedItem.idfuentes, this.editedItem).then(response=>{
      	this.dialogSuccess = true
      	this.cargar = false
      	this.closeDelete()
        this.getContactosAsignados()
      	this.initialize()
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },

    buscarTelefono( value ){
      if( value ||  this.searchTelefono){
        this.cargar = true

        const payload = {
          telefono: value ? value : this.searchTelefono,
          escuela: this.escuela
        }

        this.$http.post('leds.telefono', payload).then( response => {
          this.validarSuccess( response.data.message )
        }).catch( error => {
          this.cargar = false
          this.dialog = true
        }).finally( ()=> { this.cargar = false })
      }
    },

    editarLead( value ){
      this.editedItem    = Object.assign({}, value)
      this.dialogEditar  = true
    },

    actualizarLead( ){
      // Ponerle pendiente a su nombre completo
      if(this.editedItem.nombre_completo == ''){ this.editedItem.nombre_completo = 'Pendiente' }

      // Activamos el loader
      this.cargar = true
      // Lo mandapos por el EP
      this.$http.put('leds.update', this.editedItem).then(response=>{
        this.validarSuccess( 'Lead actualizado correctamente ')
        this.cerrarActualizar()
        this.getContactosAsignados()
        this.initialize()
        this.cargar      = false
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })

    },

    cerrarActualizar( ){
      this.editedItem    = Object.assign({}, this.defaultItem)
      this.dialogEditar  = false
    }


  }
}