<template>
  <v-container fluid class="mt-4">
  	<v-row>
  		<v-col cols="12">
	  		<v-card>
	  		  <v-card-title primary-title>
	  		    <v-text-field
	  		      label="Fecha de inicio"
	  		      filled
	  		      hide-details
	  		      dense
	  		      type="date"
	  		      v-model="fecha_inicio"
	  		    ></v-text-field>
	  		    <v-spacer></v-spacer>

	  		    <v-text-field
	  		      label="Fecha final"
	  		      filled
	  		      hide-details
	  		      dense
	  		      type="date"
	  		      v-model="fecha_final"
	  		    ></v-text-field>
	  		    <v-spacer></v-spacer>

	  		    <v-btn color="primary" @click="initialize()">Buscar</v-btn>
	  		    <v-spacer></v-spacer>
	  		  </v-card-title>
	  		</v-card>
  		</v-col>
  	</v-row>
    <v-row>

    	<!-- Cantidad de LEDS -->
      <v-col cols="12">
        <v-card>
			    <v-card-title class="align-start">
			      <span class="font-weight-semibold">Cantidad de Leads | {{ totalLeads }} |, Contactos | {{ totalContactos }} |, Inscritos | {{ inscritosFuente }} |</span>
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <v-card-text>
			      <v-row>
			        <v-col
			          v-for="data in fuentes"
			          :key="data.idfuente"
			          cols="6"
			          md="6"
			          lg="4"
			          class="d-flex align-center"
			        >
			        	<v-tooltip bottom :color="data.color">
						      <template v-slot:activator="{ on, attrs }">
					          <v-avatar
					          	@click="abrirModalDesgloce( data )"
					            size="44"
					            :color="data.color"
					            rounded
					            class="elevation-1"
					            v-bind="attrs"
								      v-on="on"
					          >
					            <v-icon
					              dark
					              color="white"
					              size="30"
					            >
					              {{ data.icono }}
					            </v-icon>
					          </v-avatar>
						      </template>
						      <span>{{ data.fuente }}</span>
						    </v-tooltip>

			          <div class="ms-3">
			            <p class="text-xs mb-0">
			              Leads
			            </p>
			            <h3 class="text-xl font-weight-semibold">
			              {{ data.cantidad }}
			            </h3>
			          </div>

			          <div class="ms-3">
			            <p class="text-xs mb-0">
			              Contactos
			            </p>
			            <h3 class="text-xl font-weight-semibold">
			              {{ data.cantidadContactos }}
			            </h3>
			          </div>

			          <div class="ms-3">
			            <p class="text-xs mb-0">
			              Inscritos
			            </p>
			            <h3 class="text-xl font-weight-semibold">
			              {{ data.cantidadInscritos }}
			            </h3>
			          </div>
			        </v-col>
			      </v-row>
			    </v-card-text>
			  </v-card>
      </v-col>

      <!-- Desglose de LEDS -->
      <v-col cols="12" sm="6" md="4" >
        <v-card>
			    <v-card-title class="align-start">
			      <span>Desglose de LEADS</span>
			      <v-spacer></v-spacer>
			    </v-card-title>

			    <v-card-text>
			      <v-list class="pb-0">
			        <v-list-item
			          v-for="(data, i) in desgloseLeads"
			          :key="data.nombre"
			          :class="`d-flex align-center px-0 ${i > 0 ? 'mt-4':''}`"
			        >
			          <v-avatar
			            :color="data.color"
			            size="20"
			            class="me-3"
			          >
			          </v-avatar>

			          <div class="d-flex align-center flex-grow-1 flex-wrap">
			            <div class="me-2">
			              <div class="font-weight-semibold">
			              	<!-- Titulo -->
			                <span class="text--primary text-base me-1">{{ data.nombre }}</span>
			              </div>
			            </div>

			            <v-spacer></v-spacer>

			            <!-- Total -->
			            <div>
			              <h4 class="font-weight-semibold">
			                {{ data.total }}
			              </h4>
			            </div>

			            <!-- Barra de progreso -->
		              <v-list-item-subtitle class="text-xs">
		                <v-progress-linear :color="data.color" :value="data.porcentaje"></v-progress-linear>
		              </v-list-item-subtitle>
			          </div>

			        </v-list-item>
			      </v-list>
			    </v-card-text>
			  </v-card>
      </v-col>

      <!-- Conversiones -->
      <v-col cols="12" sm="6" md="8" lg="8" >
        <v-row class="match-height">
          <v-col cols="12" md="6">
            <v-card>
					    <v-card-title class="align-start">
					      <p class="text-h5 mb-0">
					        {{ conversionContactos }} %
					      </p>
					      <v-spacer></v-spacer>
					    </v-card-title>

					    <v-card-text class="text-no-wrap text--primary pb-2">
					      <p class="text-h6  grey--text">
					        Conversión contactos
					      </p>
					    </v-card-text>
					  </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card>
					    <v-card-title class="align-start">
					      <p class="text-h5 mb-0">
					        {{ conversionInscritos }} %
					      </p>
					      <v-spacer></v-spacer>
					    </v-card-title>

					    <v-card-text class="text-no-wrap text--primary pb-2">
					      <p class="text-h6  grey--text">
					        Conversión inscritos
					      </p>
					    </v-card-text>
					  </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card>
					    <v-card-title class="align-start">
					      <p class="text-h5 mb-0">
					        {{ conversionConvencimiento }} %
					      </p>
					      <v-spacer></v-spacer>
					    </v-card-title>

					    <v-card-text class="text-no-wrap text--primary pb-2">
					      <p class="text-h6  grey--text">
					        Conversión Convencimiento
					      </p>
					    </v-card-text>
					  </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- Inscritos por vendedora -->
      <v-col cols="12">
        <v-card>
			    <v-data-table
			      :headers="headers"
			      :items="vendedoras"
			      item-key="vendedora"
			      class="table-rounded"
			      hide-default-footer
			    >
			      <!-- name -->
			      <template v-slot:item.vendedora="{item}">
			        <div class="d-flex flex-column">
			          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.vendedora }}</span>
			          <!-- <small>{{ item.post }}</small> -->
			        </div>
			      </template>

			      <template v-slot:item.convencimiento="{item}">
			        {{ item.convencimiento }} %
			      </template>
			    </v-data-table>
			  </v-card>
      </v-col>

      <!-- Inscritos por plantel -->
      <v-col cols="12" lg="8">
        <v-card>
			    <v-data-table
			      :headers="headersPlantel"
			      :items="planteles"
			      item-key="plantel"
			      class="table-rounded"
			      hide-default-footer
			    >
			      <!-- name -->
			      <template v-slot:item.plantel="{item}">
			        <div class="d-flex flex-column">
			          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.plantel }}</span>
			          <!-- <small>{{ item.post }}</small> -->
			        </div>
			      </template>

			      <template v-slot:item.convencimiento="{item}">
			        {{ item.convencimiento }} %
			      </template>
			    </v-data-table>
			  </v-card>
      </v-col>

      <!-- Inscritos por curso -->
      <v-col cols="12" md="6" lg="4">
        <v-card>
			    <v-data-table
			      :headers="headersCurso"
			      :items="cursos"
			      item-key="curso"
			      class="table-rounded"
			      hide-default-footer
			    >
			      <!-- name -->
			      <template v-slot:item.curso="{item}">
			        <div class="d-flex flex-column">
			          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.curso }}</span>
			          <!-- <small>{{ item.post }}</small> -->
			        </div>
			      </template>
			    </v-data-table>
			  </v-card>
      </v-col>

      <!-- CONTACTOS POR CAMPAÑA -->
      <v-col cols="12" md="6">
        <v-card class="shadowCard">
			    <v-data-table
			      :headers="headerCampanias"
			      :items="campanias_list"
			      class="table-rounded"
			      dense
			    >
			    </v-data-table>
			  </v-card>
      </v-col>


      <!-- CONTACTOS POR CURSO DE INTERES -->
      <v-col cols="12" md="6">
        <v-card class="shadowCard">
			    <v-data-table
			      :headers="headersCursoInteres"
			      :items="planteles_list"
			      class="table-rounded"
			      dense
			    >
			    </v-data-table>
			  </v-card>
      </v-col>

    </v-row>

    <!-- Dialog de desglose de contactos -->
    <v-dialog
			v-model="dialogDesglose"
			max-width="600px"
		>
			<v-card>
				<v-card-title primary-title>
					<v-avatar
            size="44"
            :color="fuente.color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ fuente.icono }}
            </v-icon>
          </v-avatar>
				  {{ fuente.fuente }}
				  <v-spacer></v-spacer>
				  <v-btn icon small class="me-n3 mt-n2" @click="dialogDesglose = false">
		        <v-icon>
		          mdi-close
		        </v-icon>
		      </v-btn>
				</v-card-title>
				<v-card-text class="pt-4">
					<v-data-table
			      :headers="headersDesglose"
			      :items="desglose"
			      class="table-rounded"
			      hide-default-footer
			    >
			    </v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>

<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


export default {
  components:{
    Alerta,
    carga,
  },

  mixins: [ validarErrores, funcionesExcel ],

  data(){
  	return{
  		fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fecha_final:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			overlay: false,
  		totalLeads: 0,
  		fuentes:[],
  		desgloseLeads:[],
  		conversionContactos:0,
			conversionInscritos:0,
			conversionConvencimiento:0,
			totalContactos:0,
			contactosFuente:[],
			inscritosFuente:0,
			vendedoras:[],
			planteles:[],
			cursos:[],

			dialogDesglose: false,
			desglose:[],
			fuente:'',
			campanias_list:[],
			planteles_list:[],

  		headers: [
        { text: 'VENDEDORA'        , value: 'vendedora' },
        { text: 'CONTACTOS'        , value: 'contactos' },
        { text: 'FORANEOS'         , value: 'foraneos' },
        { text: 'CON INFORMACION'  , value: 'conInformacion' },
        { text: 'INSCRITOS'        , value: 'cantidadInscritos' },
        { text: 'ADULTOS'          , value: 'ADULTOS' },
        { text: 'EXCI'             , value: 'EXCI' },
        { text: 'TEENS'            , value: 'TEENS' },
        { text: 'SIN CURSO'        , value: 'SIN CURSO' },
        { text: '% CONVENCIMIENTO' , value: 'convencimiento' },
      ],

      headersPlantel: [
        { text: 'PLANTEL'          , value: 'plantel' },
        { text: 'CONTACTOS'        , value: 'contactos' },
        { text: 'INSCRITOS'        , value: 'cantidadInscritos' },
        { text: '% CONVENCIMIENTO' , value: 'convencimiento' },
      ],

      headersCurso: [
        { text: 'CURSO'            , value: 'curso' },
        { text: 'INSCRITOS'        , value: 'cantidadInscritos' },
      ],

      headersDesglose: [
        { text: 'DETALLE FUENTE'   , value: 'detalle_fuente' },
        { text: 'LEADS'            , value: 'cantidad' },
        { text: 'CONTACTOS'        , value: 'cantidadContactos' },
        { text: 'INSCRITOS'        , value: 'cantidadInscritos' },
      ],

      headerCampanias: [
        { text: 'ID'          , value: 'idcampanias' },
        { text: 'Campaña'     , value: 'campania' },
        { text: 'CONTACTOS'   , value: 'contactos' },
      ],

			headersCursoInteres: [
        { text: 'SUCURSAL'     , value: 'plantel' },
        { text: 'CONTACTOS'    , value: 'contactos' },
      ],

  	}
  },

  async created(){
  	await this.initialize()
  },

  methods:{
  	initialize(){
  		this.cargar = true
  		const payload = {
  			fecha_inicio: this.fecha_inicio,
  			fecha_final:  this.fecha_final,
  			escuela:      1
  		}
			this.$http.post('data.dashboard.marketing', payload).then((response) => {
				this.totalLeads                 = response.data.totalLeads
				this.fuentes                    = response.data.fuentes
				this.desgloseLeads              = response.data.desgloseLeads
				this.conversionContactos        = response.data.conversionContactos
				this.conversionInscritos        = response.data.conversionInscritos
				this.totalContactos             = response.data.totalContactos
				this.contactosFuente            = response.data.contactosFuente
				this.inscritosFuente            = response.data.inscritosFuente
				this.conversionConvencimiento   = response.data.conversionConvencimiento
				this.vendedoras                 = response.data.vendedoras
				this.planteles                  = response.data.planteles
				this.cursos                     = response.data.cursos
				this.campanias_list             = response.data.campanias_list
				this.planteles_list             = response.data.planteles_list
				this.cargar = false
        return
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
  	},

  	abrirModalDesgloce( data ){
  		this.fuente         = data
  		this.desglose       = data.detalles
  		this.dialogDesglose = true
  	}
  },

  computed:{
    tamanioPantalla () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        return 'auto';
          // return this.$vuetify.breakpoint.height -110
        break;
        case 'sm': 
          return this.$vuetify.breakpoint.height - 65
        break;
        case 'md':
          return this.$vuetify.breakpoint.height - 65
        break;
        case 'lg':
          return this.$vuetify.breakpoint.height - 65
        break;
        case 'xl':
          return this.$vuetify.breakpoint.height - 65
        break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>