<template>
  <v-container fluid>

    <v-carousel cycle :height="tamanioPantalla" hide-delimiter-background hide-delimiters show-arrows-on-hover :interval="5000">
      <template v-slot:prev="{ on, attrs }">
        <v-btn icon large color="white" v-bind="attrs" v-on="on" >  <v-icon> mdi-chevron-left </v-icon> </v-btn>  
      </template>

      <v-carousel-item  src="@/assets/images/fondo/fondo1.png" :contain="$vuetify.breakpoint.name === 'xs'? true : false" >
      </v-carousel-item>

      <v-carousel-item  src="@/assets/images/fondo/fondo2.png" :contain="$vuetify.breakpoint.name === 'xs'? true : false" >
      </v-carousel-item>

     <!--  <v-carousel-item  src="@/assets/images/fondo/fondo5.png" :contain="$vuetify.breakpoint.name === 'xs'? true : false" >
      </v-carousel-item> -->

    
    </v-carousel>

  </v-container>
</template>

<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'

  export default {
    data(){
      return{
        socket: null,
      }
    },

    computed:{

      ...mapGetters('login', ['getdatosUsuario', 'getEscuela']),
      tamanioPantalla () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return 'auto';
          break;
          default:
            return this.$vuetify.breakpoint.height - 90
          break;
        }
      },
    },
  }

</script>

<style scoped>
</style>