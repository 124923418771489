<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">

	  			<!-- Titulo -->
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Inscripciones alumnos</span>
	  		    <v-spacer></v-spacer>

	  		    <v-btn 
              class="mr-2 mb-2" 
              small dark color="green"
              @click="exportarInscripciones()"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            
            <v-btn
              color="grey"
              dark
              class="mb-2 mr-2 text-capitalize"
              @click="dialogGrupos.estatus = true "
              small
              tile
            >
              <v-icon left>mdi-google-classroom</v-icon>
              Grupos
            </v-btn>

	  		    <v-btn
              color="orange"
              dark
              class="mb-2 mr-2 text-capitalize"
              @click="abrirModalRegistro()"
              small
              tile
            >
              <v-icon left>mdi-cash</v-icon>
              Realizar pago
            </v-btn>

	  		    <v-btn
              color="primary"
              dark
              class="mb-2 text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
	  		  </v-card-title>

	  		  <v-card-text>

	  		  	<!-- Filtros -->
	  		  	<v-row justify="end">
	  		  		<!-- Seleccionar el plantel -->
	  		  		<v-col cols="12" sm="6" md="4" lg="3">
	  		  			<v-autocomplete
	  		  			  label="Plantel"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  single-line
	  		  			  hide-details
	  		  			  v-model="plantel"
	  		  			  :items="planteles"
	  		  			  item-text="plantel"
	  		  			  item-value="id_plantel"
	  		  			  clearable
	  		  			></v-autocomplete>
	  		  		</v-col>
	  		  		
	  		  		<!-- Seleccionar el ciclo -->
	  		  		<v-col cols="12" sm="6" md="4" lg="3">
	  		  			<v-autocomplete
	  		  			  label="Ciclo"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  single-line
	  		  			  hide-details
	  		  			  v-model="ciclo"
	  		  			  :items="ciclos"
	  		  			  item-text="ciclo"
	  		  			  item-value="id_ciclo"
	  		  			  clearable
	  		  			></v-autocomplete>
	  		  		</v-col>
	  		  		<v-spacer></v-spacer>
	  		  		<v-col cols="12" sm="6" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  single-line
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>

	  		  	<v-row>
	  		  		<v-col cols="12" class="pt-0">
		  		  		<v-radio-group
						      v-model="tipoFiltro"
						      row
						      hide-details
						    >
						      <v-radio
						        label="Todos"
						        :value="3"
						      ></v-radio>
						      <v-radio
						        label="Sin aduedo"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="Con aduedo"
						        :value="2"
						      ></v-radio>
						      <v-radio
						        label="RI"
						        :value="4"
						      ></v-radio>

						      <v-radio
						        label="NO RI"
						        :value="5"
						      ></v-radio>

						      <v-radio
						        label="NI"
						        :value="6"
						      ></v-radio>
						    </v-radio-group>
		  		  	</v-col>
				</v-row>

	  		  	<!-- Tabla -->
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterAlumnos"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
			            id="grupos"
			            item-class="clase_row"
							  >
							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    		tile
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="1200px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1"><b>Alumno: {{ editedItem.alumno }} </b></span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_alumno }}</strong>
          </span>
        </v-card-title>
        <v-tabs vertical v-model="tabs">
        	<v-tab class="text-capitalize" v-for="(tab, i) in menu" :key="i">
		        <v-icon left v-text="tab.icono"></v-icon>
		        {{ tab.text }}
					</v-tab>

		      <!-- información del grupo -->
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text class="pt-0">
		          	<InfoAlumno  v-if="tabs == 0 && dialog" :editedItem="editedItem" @click="initialize()"/> 
			        </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <!-- Tutro -->
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <Tutor v-if="tabs == 1 && dialog" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <!-- Grupos -->
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <GruposAlumno  v-if="tabs == 2 && dialog" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

		      <!-- Becas -->
		      <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <BecaAlumno  v-if="tabs == 3 && dialog" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

			 		<!-- Precios -->
			  <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <Precios  v-if="tabs == 4 && dialog" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>

			  <!-- Hermanos -->
			  <v-tab-item>
		        <v-card flat>
		          <v-card-text>
		            <Hermanos  v-if="tabs == 5 && dialog" :editedItem="editedItem"/>
		          </v-card-text>
		        </v-card>
		      </v-tab-item>
		    </v-tabs>

        
        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            @click="close"
            small
          >
          	<v-icon left small>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para ver todos los grupos habiles que hay -->
    <GruposHabiles v-if="dialogGrupos.estatus == true"    :dialogGrupos="dialogGrupos"/>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"          :parametros="parametros"/>
    <carga                v-if="cargar"/>
    <DialogInscripcion    v-if="inscribir.estatus == true"          :inscribir="inscribir"/>


	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta             from '@/components/alertas/Alerta.vue'
  import carga              from '@/components/alertas/carga.vue';
  import InfoAlumno         from '@/components/inscripciones/InfoAlumno.vue';
  import GruposAlumno       from '@/components/inscripciones/GruposAlumno.vue';
  import Tutor              from '@/components/inscripciones/Tutor.vue';
  import BecaAlumno         from '@/components/inscripciones/BecaAlumno.vue';
  import DialogInscripcion  from '@/components/inscripciones/DialogInscripcion.vue';
  import Precios            from '@/components/inscripciones/Precios.vue';
  import Hermanos            from '@/components/inscripciones/Hermanos.vue';

  import GruposHabiles      from '@/components/grupos/GruposHabiles.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
      InfoAlumno,
      GruposAlumno,
      Tutor,
      BecaAlumno,
      DialogInscripcion,
      GruposHabiles,
	  Precios,
	  Hermanos
    },

    data: () => ({
    	tabs: 0, 
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      editedIndex: -1,

      editedItem: {
        id_grupo:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      defaultItem: {
        id_grupo:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      // Filtros: 
      incio:null,
			fin:null,
			plantel: null,
			ciclo: null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      grupos: [],
      alumnos:[],
      headers: [
        { text: 'ID'                      , value: 'id_alumno'            },
		{ text: 'Folio Prospecto'         , value: 'folioProspecto'		  },
        { text: 'Matricula'               , value: 'matricula'            },
        // { text: 'Matricula ERP'           , value: 'matricula_erp'        },
        { text: 'Alumno'                  , value: 'alumno'               },
        { text: 'Celular'                 , value: 'celular'              },
        { text: 'Grupo'                   , value: 'grupo'                },
        { text: 'Precio grupo'            , value: 'precio'               },
        { text: 'Precio con descuento'    , value: 'precio_grupo_con_descuento_x_alumno' },
        { text: 'Monto pagado'            , value: 'pagado'               },
        { text: 'Monto adeudo'            , value: 'adeudo'               },
        { text: 'Diferencia'              , value: 'diferencia'           },
        { text: 'Alta'                    , value: 'fecha_inscripcion'    },
        // { text: 'Activo'                  , value: 'activo_sn'            },
        { text: 'Actions'                 , value: 'actions'              },
      ],

      ciclos:[],
			planteles:[],
			inscribirAlumno: false,
			inscribir: { estatus: false },
			menu:[{text:'Alumno',icono:'mdi-account'},{text:'Tutor',icono:'mdi-account'},{text:'Pagos',icono:'mdi-bitcoin'},{text:'Beca',icono:'mdi-percent-outline'},{text:'Precios',icono:'mdi-currency-usd'}, {text:'Hermanos',icono:'mdi-account-multiple '}],
			tipoFiltro: 3,

			dialogGrupos: {
				estatus: false
			}
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Grupo' : 'Editar Grupo'
      },

      filterAlumnos( ){
      	let alumnos = this.alumnos

      	if( this.tipoFiltro == 1 ){
      		alumnos = alumnos.filter( el => { return el.adeudo <= 0 })
      	}

      	if( this.tipoFiltro == 2 ){
      		alumnos = alumnos.filter( el => { return el.adeudo > 0 })
      	}

      	if( this.tipoFiltro == 4 ){
      		alumnos = alumnos.filter( el => { return el.clase_row == 'alumno_ri' })
      	}

      	if( this.tipoFiltro == 5 ){
      		alumnos = alumnos.filter( el => { return el.clase_row != 'alumno_ri'})
      	}

      	if( this.tipoFiltro == 6 ){
      		alumnos = alumnos.filter( el => { return el.alumno_ni == 1 })
      	}

      	if( this.plantel && !this.ciclo ){
      		return alumnos.filter( el=> { return el.id_plantel == this.plantel })
      	}

      	if( this.plantel && this.ciclo){
      		return alumnos.filter( el=> { return el.id_plantel == this.plantel && el.id_ciclo == this.ciclo })
      	}

      	if( !this.plantel && this.ciclo){
      		return alumnos.filter( el=> { return el.id_ciclo == this.ciclo })
      	}

      	if( !this.plantel && !this.ciclo){
      		return alumnos
      	}
      }
    },

    watch: {
      dialog (val) { val || this.close() },

      tabs( val ) { console.log( val ) },

      ciclo( val ) { if( val ){ this.initialize() } }

    },

    async created () {
      // await this.initialize()
      await this.getCiclos()
      await this.getPlanteles()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.alumnos = []
        
        return this.$http.get('inscripciones.list/' + this.ciclo).then(response=>{
        	this.alumnos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.grupos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

    	abrirModalRegistro ( ) {
    		this.inscribirAlumno   = true
    		this.inscribir.estatus = true
    	},

    	exportarInscripciones( ){

    		if( this.getdatosUsuario.iderp == 32 ){
	    		const alumnos = this.filterAlumnos.map(({ id_alumno, matricula, folioProspecto, fechaProspecto, puesto, alumno, fecha_inscripcion, alumno_ni, id_grupo, grupo, precio, pagado, adeudo, diferencia, precio_con_descuento, plantel, telefono }) => ({ id_alumno, matricula, folioProspecto, fechaProspecto, puesto, alumno, fecha_inscripcion, alumno_ni, id_grupo, grupo, precio, pagado, adeudo, diferencia, precio_con_descuento, plantel, telefono }));

					this.exportExcel( alumnos, 'Inscripciones')

    		}else{
    			const alumnos = this.filterAlumnos.map(({ id_alumno, matricula, folioProspecto, fechaProspecto, puesto, alumno, fecha_inscripcion, alumno_ni, id_grupo, grupo, precio, pagado, adeudo, diferencia, precio_con_descuento, plantel }) => ({ id_alumno, matricula, folioProspecto, fechaProspecto, puesto, alumno, fecha_inscripcion, alumno_ni, id_grupo, grupo, precio, pagado, adeudo, diferencia, precio_con_descuento, plantel }));

					this.exportExcel( alumnos, 'Inscripciones')
    		}


    	}
    },
  }
</script>


<style>
  #grupos td:nth-child(1) {
    background-color: #E8E8E8 !important;
  }

  #grupos td{
    font-size: 12px !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  .adeudo_alumno{
  	background-color: #FF3030 !important;
  	color: white;
  }

  .alumno_ri{
  	background-color: #8CC339 !important;
  	color: white;
  }

</style>