<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">LEADS INBI | {{ leds.length }} | </span>
            <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              tile
              @click="exportExcel(filterLeds , 'LEADS FAST')">
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              @click="dialog = true"
              small
              tile
            >
              <v-icon small left>mdi-plus</v-icon>
              Nuevo LEAD
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="fechaini"
                  hide-details
                  filled
                  dense
                  type="date"
                  label="Fecha inicio"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="fechafin"
                  hide-details
                  filled
                  dense
                  type="date"
                  label="Fecha final"
                  clearable
                ></v-text-field>
              </v-col>

              <v-spacer></v-spacer>

              <v-col cols="12" md="4" lg="4">
                <v-text-field
                  v-model="search"
                  label="Buscar"
                  filled
                  dense
                  append-icon="mdi-magnify"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4" lg="3">
                <v-text-field
                  dense
                  filled
                  append-icon="mdi-magnify"
                  label="Buscar teléfono"
                  v-model.number="searchTelefono"
                  type="number"
                  hint="Enter para buscar"
                  @keyup.enter="buscarTelefono()"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" ms="4" md="3" lg="2" v-for="( contactos, i) in vendedorasDesglose"  :key="i" class="text-center mt-0 pt-0" >
                <v-card class="shadowCard white--text" color="#2EDAD5">
                  <b>{{ contactos.vendedora }}</b><br/>
                  {{ contactos.contactos }}
                </v-card>
              </v-col>
            </v-row>

            <tabla_leads  
              :headers="headers" 
              :items="filterLeds" 
              @actualizar="initialize"
              @pasarAcontacto="preLlenarDatos"
              @editar="editarLead"
              @respondio="checkRespondio"
              :search="search"

            />
          </v-card-text>
        </v-card>
        
      </v-col>
    </v-row>

    <!-- Agregar LEAD -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <nuevo_lead 
        :formTitle="formTitle"
        :editedItem="editedItem"
        :cursos_escuela="cursos_escuela"
        :vendedoras_plantel="vendedoras_plantel"
        :fuentes="fuentes"
        :detallesFilter="detallesFilter"
        :medios_contacto="medios_contacto"
        :campaniasFilter="campaniasFilter"
        :escuela="escuela"
        @save="save"
        @close="close"
        @buscarTelefono="buscarTelefono"
      />
    </v-dialog>

    <!-- Dialog para crear el prospecto :) -->
    <v-dialog
      v-model="dialogNuevoProspecto"
      max-width="500"
    >
      <nuevo_prospecto 
        :formTitle="formTitle"
        :prospecto="prospecto"
        :cursos_escuela="cursos_escuela"
        :vendedoras_plantel="vendedoras_plantel"
        :escuela="escuela"
        @grabarProspecto="grabarProspecto"
        @cerarDialog="cerarDialog"
        @buscarTelefono="buscarTelefono"
      />
    </v-dialog>

    <!-- Editar el prospecto -->
    <v-dialog
      v-model="dialogEditar"
      max-width="500px"
    >
      <editar_lead 
        :formTitle="formTitle"
        :editedItem="editedItem"
        :cursos_escuela="cursos_escuela"
        :vendedoras_plantel="vendedoras_plantel"
        :fuentes="fuentes"
        :detallesFilter="detallesFilter"
        :medios_contacto="medios_contacto"
        :campaniasFilter="campaniasFilter"
        :escuela="escuela"
        @actualizarLead="actualizarLead"
        @cerrarActualizar="cerrarActualizar"
        @buscarTelefono="buscarTelefono"
      />
    </v-dialog>
    
    <!-- validar la escuela -->
    <v-dialog
      v-model="dialogValidarEscuela"
      persistent
      max-width="500"
    >
      <validar_escuela 
        :escuela="escuela" 
        @escuelaValidada="escuelaValidada"
        @cancelar="dialogValidarEscuela = false"
      />
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  import tabla_leads           from '@/components/basicos/tabla_leads.vue';
  import nuevo_lead            from '@/components/leads/nuevo_lead.vue';
  import nuevo_prospecto       from '@/components/leads/nuevo_prospecto.vue';
  import validar_escuela       from '@/components/leads/validar_escuela.vue';
  import editar_lead           from '@/components/leads/editar_lead.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import basicosTablas         from '@/mixins/basicosTablas'
  import mixinLeads            from '@/mixins/mixinLeads'
  import funcionesExcel        from '@/mixins/funcionesExcel'



  import { mapGetters, mapActions } from 'vuex'
  export default {
    components:{
      Alerta,
      carga,
      tabla_leads,
      nuevo_lead,
      nuevo_prospecto,
      validar_escuela,
      editar_lead
    },

    mixins: [ validarErrores, basicosTablas, mixinLeads, funcionesExcel ],

    data: () => ({

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      // Modales
      dialog: false,
      dialogDelete: false,
      dialogNuevoProspecto: false,
      dialogValidarEscuela: false,
      dialogEditar:false,

      // Datos de la tabla
      headers: [
        { text: 'ID'       , align: 'start', sortable: false, value: 'idleds', },
        { text: 'Folio'            , value: 'folio' },
        { text: 'Teléfono'         , value: 'telefono' },
        { text: 'Nombre'           , value: 'nombre_completo' },
        { text: 'Cómo nos conocio' , value: 'medio' },
        { text: 'detalle'          , value: 'campania' },
        { text: 'Medio contacto'   , value: 'fuente' },
        { text: 'Detalle'          , value: 'detalle_fuente' },
        { text: 'Escuela'          , value: 'escuela' },
        { text: 'Foraneo'          , value: 'foraneo' },
        { text: 'Curso'            , value: 'curso' },
        { text: 'Suc. Intéres'     , value: 'sucursal_interes' },
        { text: 'Actions'          , value: 'actions', sortable: false },
      ],
      leds: [],
      search:'',

      // Datos para editar el led 
      editedIndex: -1,
      editedItem: {
        nombre_completo:'',
        idleds:'',
        escuela: 1,
        iddetalle_fuentes:0,
        idfuentes: 0,
        fuente: '',
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal:0,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
        id_sucursal_interes:0,
        curso_interes: 0,
        horario_interes:'',
        respondio: 0,
      },
      defaultItem: {
        nombre_completo:'',
        idfuentes: 0,
        idleds:'',
        escuela: 1,
        iddetalle_fuentes:0,
        fuente: 0,
        estatus: '',
        foraneo: 0,
        idcursos_escuela: 0,
        telefono:'',
        sucursal:0,
        nota_inicial:'El prospecto desea información',
        idmedio_contacto:0,
        idcampanias:0,
        id_sucursal_interes:0,
        curso_interes: 0,
        horario_interes:'',
        respondio: 0,
      },

      // Consultas extras
      detalles:[],
      fuentes:[],

      // Manejo de errores
      dialogSuccess:false,
      overlay: false,
      dialogError:  false,
      error:        '',
      successMensaje:'',
      errorBack:false,
      textoCopiado:'COPIAR',

      escuela: 1,

      // Datos del prospecto
      prospecto: {
        //agrego cada campo a utlizar
        idprospectos: 0,
        nombre_completo: '',
        telefono:'',
        correo: '',
        idetapa:0,
        sucursal_interes:0,
        como_nos_conocio:0,
        ciclo_interes: 0,
        curso_interes:0,
        usuario_asignado:0,
        usuario_creo:0,
        nota_inicial:'',
        folio:'',
        idleds:0,
      },

      sucursales:[],
      sucursal:null,
      vendedoras:[],
      vendedoras_plantel:[],
      vendedora:null,
      campanias:[],
      campania:null,

      fechaini:null,
      fechafin:null,

      cursos_escuela:[],
      medios_contacto:[],

      searchTelefono:'',

      fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechafin:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),


      fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fecha_final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      contactosVendedora: [],
      vendedorasDesglose:[],

    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return this.editedIndex === -1 ? 'Agregar un LEAD' : 'Editar LEAD'
      },

      detallesFilter(){
        return this.detalles.filter(el => { return el.idfuentes == this.editedItem.idfuentes })
      },

      campaniasFilter(){
        return this.campanias.filter(el => { return el.idmedio_contacto == this.editedItem.idmedio_contacto })
      },

      filterLeds(){
        if(this.leds){
          return this.leds.filter((led)=>{
            // Sin fecha, poner todos
            if(!this.fechaini && !this.fechafin){
              return true
            }

            // Solo fecha de inicio seleccionada
            if(this.fechaini && !this.fechafin){
              if( led.fecha == this.fechaini){
                return true
              }
            }

            // Solo fecha de inicio seleccionada
            if(this.fechaini && this.fechafin){
              if(led.fecha >= this.fechaini && led.fecha <= this.fechafin ){
                return true
              }
            }
          })
        }
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    async created () {
      await this.initialize()
      await this.getContactosAsignados()
      await this.getFuntes()
      await this.getDetallesFuente()
      await this.getCampanias()
      await this.getVendedoras()
      await this.getCursosEscuela()
      await this.getComoNosConocio()
    },

    methods: {
      cerarDialog( ){
        this.dialogNuevoProspecto = false
      }
    },
  }
</script>

